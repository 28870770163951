import { ref } from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { axiosInventory } from '@/services/httpService';
import { IWarehouseLocation, IWarehouse } from '@/types/inventory';
import { IPaginatedResponse } from '@/types/api/common';
import EquipmentListData from '@/components/Warehouse/EquipmentList.json';
import { EquipmentListPrepDay } from '@/types/warehouse';
import { EquipmentList } from '@/types/events/equipmentList';
import { formatDate } from '@/localization/i18n';

interface IWarehouseStore extends IWarehouse {
  warehouseLocations: IWarehouseLocation[];
}

interface IListWarehousesResponse extends IPaginatedResponse {
  rows: IWarehouse[];
}

const useWarehouseStore = defineStore('WarehouseStore', () => {
  const defaultWarehouse = ref<IWarehouseStore>();
  const warehousesInAccount = ref<IWarehouse[]>([]);

  const preppingDayTimeSlots = ref<Record<string, EquipmentListPrepDay>>();
  const equipmentListPreppingToday = ref<Record<string, EquipmentListPrepDay>>({});
  const equipmentListPreppingTomorrow = ref<Record<string, EquipmentListPrepDay>>({});
  const equipmentListPreppingTwoDaysFromNow = ref<Record<string, EquipmentListPrepDay>>({});
  // TODO: Remove allEquipmentLists when backend is hooked up
  const allEquipmentLists = ref<EquipmentList[]>([]);

  const getDefaultWarehouse = async () => {
    const { data }: { data: IWarehouse } = await axiosInventory.get('/warehouses/default');
    defaultWarehouse.value = {
      ...data,
      warehouseLocations: []
    };
  };

  // FIXME: Figure out a way to only fetch paginated responses when user scrolls down the list
  const searchWarehouseLocations = async (
    searchText?: string,
    nextPage?: number,
    aggregateWarehouseLocations: IWarehouseLocation[] = [],
    includePartialQueryAsOption = true
  ): Promise<IWarehouseLocation[]> => {
    if (!searchText) {
      return [];
    }

    if (!defaultWarehouse.value) {
      await getDefaultWarehouse();
    }

    if (!defaultWarehouse || !defaultWarehouse.value) {
      throw new Error('Error fetching default warehouse');
    }

    const searchUrl = `/warehouses/${defaultWarehouse.value.id}/warehouse_locations/search${
      nextPage ? `page=${nextPage}` : ''
    }`;

    const { data }: { data: IPaginatedResponse } = await axiosInventory.post(searchUrl, { text: searchText });
    const { rows, currentPage, totalPages } = data;
    const allWarehouseLocations: IWarehouseLocation[] = aggregateWarehouseLocations.concat(rows);

    if (currentPage + 1 === totalPages || totalPages === 0) {
      /*
      include a new warehouse location option if one does not exist, and includePartialQueryAsOption is true
      */
      if (includePartialQueryAsOption && !allWarehouseLocations.find((wl) => wl.warehouseLocationName === searchText)) {
        allWarehouseLocations.push({
          id: 0,
          warehouseLocationName: searchText
        });
      }
      return allWarehouseLocations;
    }

    return searchWarehouseLocations(searchText, currentPage + 1, allWarehouseLocations);
  };

  const listWarehousesInAccount = async (page = 0, size = 100) => {
    if (warehousesInAccount.value.length) {
      return warehousesInAccount.value;
    }

    try {
      const url = `/warehouses?page=${page}size=${size}}`;
      const { data }: { data: IListWarehousesResponse } = await axiosInventory.get(url);
      warehousesInAccount.value = data.rows;
      return warehousesInAccount.value;
    } catch (error) {
      // TODO: tie this into an error toast
      console.error(error);
    }

    return warehousesInAccount.value;
  };
  // TODO: Make this an async req. once api is built / remove if statements that return fake data
  const getEquipmentListTimeSlotsByDay = (requestedDate: Date) => {
    const formattedReqDate = formatDate(requestedDate, 'P');

    let today: string | Date = new Date();
    let tomorrow: string | Date = new Date();
    tomorrow.setDate(today.getDate() + 1);
    let twoDaysFromNow: string | Date = new Date();
    twoDaysFromNow.setDate(today.getDate() + 2);

    today = formatDate(today, 'P');
    tomorrow = formatDate(tomorrow, 'P');
    twoDaysFromNow = formatDate(twoDaysFromNow, 'P');

    //  EquipmentList Data from JSON

    if (formattedReqDate === today) {
      return equipmentListPreppingToday.value;
    }

    if (formattedReqDate === tomorrow) {
      return equipmentListPreppingTomorrow.value;
    }

    if (formattedReqDate === twoDaysFromNow) {
      return equipmentListPreppingTwoDaysFromNow.value;
    }

    return null;
  };

  const assignEquipmentListToTimeSlots = (
    equipmentListByPrepDay: Record<string, EquipmentListPrepDay>,
    equipmentListToBeAssigned: EquipmentListPrepDay
  ) => {
    const finalizeEquipmentListByPrepDay = cloneDeep(equipmentListByPrepDay);
    // use warehouseStore action get EquipmentListBy Date

    const { goingOut, comingIn, subRentals } = equipmentListToBeAssigned;
    // Loop through each goingOut, comingIn & subRentals
    // then add equipmentList to equipmentListByTimeSlot "goingOut, comingIn & subRentals" by the specific time
    // Ex. equipmentList dateLeave = 8:15am it should be assigned to the 8:00 key which is that cell in the table
    if (goingOut.length) {
      // TODO: REMOVE When Removing allEquipmentLists
      allEquipmentLists.value = allEquipmentLists.value.concat(goingOut);

      goingOut.forEach((equipmentList) => {
        const { dateLeave } = equipmentList;
        if (dateLeave) {
          const dateLeaveHour = dateLeave.getHours();
          const dateLeaveMinute = dateLeave.getMinutes();
          const timeSlotKey = new Date(dateLeave);
          //  round down to nearest half hour
          if (dateLeaveMinute < 30) {
            timeSlotKey.setHours(dateLeaveHour, 0, 0);
            const key = formatDate(timeSlotKey, 'p');
            finalizeEquipmentListByPrepDay[key].goingOut = finalizeEquipmentListByPrepDay[key].goingOut.concat([
              equipmentList
            ]);
          } else {
            timeSlotKey.setHours(dateLeaveHour, 30, 0);
            const key = formatDate(timeSlotKey, 'p');
            finalizeEquipmentListByPrepDay[key].goingOut = finalizeEquipmentListByPrepDay[key].goingOut.concat([
              equipmentList
            ]);
          }
        }
      });
    }

    if (comingIn.length) {
      // TODO: REMOVE When Removing allEquipmentLists
      allEquipmentLists.value = allEquipmentLists.value.concat(comingIn);

      comingIn.forEach((equipmentList) => {
        const { dateReturn } = equipmentList;
        if (dateReturn) {
          const dateReturnHour = dateReturn.getHours();
          const dateReturnMinute = dateReturn.getMinutes();
          const timeSlotKey = new Date(dateReturn);
          //  round down to nearest half hour
          if (dateReturnMinute < 30) {
            timeSlotKey.setHours(dateReturnHour, 0, 0);
            const key = formatDate(timeSlotKey, 'p');
            finalizeEquipmentListByPrepDay[key].comingIn = finalizeEquipmentListByPrepDay[key].comingIn.concat([
              equipmentList
            ]);
          } else {
            timeSlotKey.setHours(dateReturnHour, 30, 0);
            const key = formatDate(timeSlotKey, 'p');
            finalizeEquipmentListByPrepDay[key].comingIn = finalizeEquipmentListByPrepDay[key].comingIn.concat([
              equipmentList
            ]);
          }
        }
      });
    }

    if (subRentals.length) {
      // TODO: REMOVE When Removing allEquipmentLists
      allEquipmentLists.value = allEquipmentLists.value.concat(subRentals);
      subRentals.forEach((equipmentList) => {
        const { dateReturn } = equipmentList;
        if (dateReturn) {
          const dateReturnHour = dateReturn.getHours();
          const dateReturnMinute = dateReturn.getMinutes();
          const timeSlotKey = new Date(dateReturn);
          //  round down to nearest half hour
          if (dateReturnMinute < 30) {
            timeSlotKey.setHours(dateReturnHour, 0, 0);
            const key = formatDate(timeSlotKey, 'p');
            finalizeEquipmentListByPrepDay[key].subRentals = finalizeEquipmentListByPrepDay[key].subRentals.concat([
              equipmentList
            ]);
          } else {
            timeSlotKey.setHours(dateReturnHour, 30, 0);
            const key = formatDate(timeSlotKey, 'p');
            finalizeEquipmentListByPrepDay[key].subRentals = finalizeEquipmentListByPrepDay[key].subRentals.concat([
              equipmentList
            ]);
          }
        }
      });
    }

    return finalizeEquipmentListByPrepDay;
  };

  // TODO: REMOVE LoadEquipmentListData when Backend is setup
  const loadEquipmentListsByDays = () => {
    const todayAtEightAM = new Date();
    todayAtEightAM.setHours(8, 0, 0);
    const todayAtOneFifteenPM = new Date();
    todayAtOneFifteenPM.setHours(13, 15, 0);

    const tomorrowAtEightAM = new Date();
    tomorrowAtEightAM.setDate(todayAtEightAM.getDate() + 1);
    tomorrowAtEightAM.setHours(8, 0, 0);
    const tomorrowAtOneFifteenPM = new Date(tomorrowAtEightAM);
    tomorrowAtOneFifteenPM.setHours(13, 15, 0);

    const twoDaysFromNowAtEightAM = new Date();
    twoDaysFromNowAtEightAM.setDate(todayAtEightAM.getDate() + 2);
    twoDaysFromNowAtEightAM.setHours(8, 0, 0);
    const twoDaysFromNowAtOneFifteenPM = new Date(twoDaysFromNowAtEightAM);
    twoDaysFromNowAtOneFifteenPM.setHours(13, 15, 0);

    const today = new EquipmentListPrepDay(cloneDeep(EquipmentListData.today) as any);
    const tomorrow = new EquipmentListPrepDay(cloneDeep(EquipmentListData.tomorrow) as any);
    const twoDaysFromNow = new EquipmentListPrepDay(cloneDeep(EquipmentListData.twoDaysFromNow) as any);

    today.goingOut = today.goingOut.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateLeave = todayAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateLeave = todayAtOneFifteenPM;
      }
      return equipList;
    });

    today.comingIn = today.comingIn.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = todayAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = todayAtOneFifteenPM;
      }
      return equipList;
    });

    today.subRentals = today.subRentals.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = todayAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = todayAtOneFifteenPM;
      }
      return equipList;
    });

    tomorrow.goingOut = tomorrow.goingOut.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateLeave = tomorrowAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateLeave = tomorrowAtOneFifteenPM;
      }
      return equipList;
    });

    tomorrow.comingIn = tomorrow.comingIn.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = tomorrowAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = tomorrowAtOneFifteenPM;
      }
      return equipList;
    });

    tomorrow.subRentals = tomorrow.subRentals.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = tomorrowAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = tomorrowAtOneFifteenPM;
      }
      return equipList;
    });

    twoDaysFromNow.goingOut = twoDaysFromNow.goingOut.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateLeave = twoDaysFromNowAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateLeave = twoDaysFromNowAtOneFifteenPM;
      }
      return equipList;
    });

    twoDaysFromNow.comingIn = twoDaysFromNow.comingIn.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = twoDaysFromNowAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = twoDaysFromNowAtOneFifteenPM;
      }
      return equipList;
    });

    twoDaysFromNow.subRentals = twoDaysFromNow.subRentals.map((equipList, indx) => {
      if (indx === 0) {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = twoDaysFromNowAtEightAM;
      } else {
        // eslint-disable-next-line no-param-reassign
        equipList.dateReturn = twoDaysFromNowAtOneFifteenPM;
      }
      return equipList;
    });
    equipmentListPreppingToday.value = assignEquipmentListToTimeSlots(equipmentListPreppingToday.value, today);
    equipmentListPreppingTomorrow.value = assignEquipmentListToTimeSlots(equipmentListPreppingTomorrow.value, tomorrow);
    equipmentListPreppingTwoDaysFromNow.value = assignEquipmentListToTimeSlots(
      equipmentListPreppingTwoDaysFromNow.value,
      twoDaysFromNow
    );
  };

  // TODO: Make API request to get EquipmentList by UUID
  const getEquipmentListByUuid = (uuid: string) => {
    const matchingEquipmentList = allEquipmentLists.value.find((equipmentList) => equipmentList.uuid === uuid);
    return matchingEquipmentList;
  };

  return {
    searchWarehouseLocations,
    defaultWarehouse,
    warehousesInAccount,
    listWarehousesInAccount,
    getEquipmentListTimeSlotsByDay,
    loadEquipmentListsByDays,
    equipmentListPreppingToday,
    equipmentListPreppingTomorrow,
    equipmentListPreppingTwoDaysFromNow,
    preppingDayTimeSlots,
    getEquipmentListByUuid,
    allEquipmentLists
  };
});

export default useWarehouseStore;

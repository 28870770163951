import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Typography from '@tiptap/extension-typography';
import TextAlign from '@tiptap/extension-text-align';

const extensions = (options?: Partial<StarterKitOptions>) => [
  StarterKit.configure(options),
  Underline,
  Typography,
  TextAlign.configure({
    types: ['heading', 'paragraph']
  })
];

export default extensions;

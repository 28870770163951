// import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { axiosInventory } from '@/services/httpService';
import { IListDepartmentObject, IDescribeDepartmentObject } from '@/types/api/gear/responses/inventory';
import { ICategory } from '@/types/inventory';

const useDepartmentStore = defineStore('DepartmentStore', () => {
  const departments = ref<IListDepartmentObject[]>([]);

  const currentDepartmentCategories = ref<ICategory[]>([]);
  const currentDepartmentId = ref<number>(0); // Id of department that owns currentDepartmentCategories

  const getDepartments = async () => {
    // lazy load
    if (!departments.value.length) {
      const { data }: { data: IListDepartmentObject[] } = await axiosInventory.get('/departments');
      departments.value = data;
    }
    return departments.value || [];
  };

  const getCategoriesForDepartment = async (departmentId: number) => {
    if (!departmentId) {
      return [];
    }
    const { data }: { data: IDescribeDepartmentObject } = await axiosInventory.get(`/departments/${departmentId}`);
    const describeDptResp = data;
    const { categories } = describeDptResp;
    return categories;
  };

  const getDepartmentCategories = async (departmentId: number | undefined) => {
    const deptId = departmentId || currentDepartmentId.value;
    // set currentDepartmentCategories back to [] to trigger new request
    if (deptId > 0 && !currentDepartmentCategories.value.length) {
      const { data }: { data: IDescribeDepartmentObject } = await axiosInventory.get(`/departments/${deptId}`);
      const describeDptResp = data;
      const { categories } = describeDptResp;
      currentDepartmentCategories.value = categories;
      currentDepartmentId.value = deptId;
    }
    return currentDepartmentCategories.value || [];
  };

  const filterDepartmentsBySearch = (searchInput: string) => {
    const text = searchInput.toLocaleLowerCase();
    departments.value = departments.value.filter((department) => {
      const departmentName = department.departmentName.toLocaleLowerCase();
      return departmentName.includes(text);
    });
  };
  return {
    departments,
    currentDepartmentCategories,
    getDepartments,
    getDepartmentCategories,
    getCategoriesForDepartment,
    filterDepartmentsBySearch
  };
});

export default useDepartmentStore;

import { createI18n } from 'vue-i18n-composable';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import type { Locale } from 'date-fns';
import Vue from 'vue';

import enUsMessages from './messages/en-US.json';
import enUsNumberformat from './numberFormats/en-US.json';

export const DEFAULT_LANGUAGE_LOCALE = 'en-US';

export const SUPPORTED_LANGUAGE_CODES = ['en-US'];

export type LocaleSupportedFormatStrings =
  | 'P' // Ex. 11/15/2022
  | 'PP' // Ex. Nov 15, 2022
  | 'PPP' // Ex. November 15th, 2022
  | 'PPPP' // Ex. Tuesday, November 15th, 2022
  | 'Pp' // Ex. 11/15/2022, 12:04 PM
  | 'Ppp' // Ex. 11/15/2022, 12:04:47 PM
  | 'PPp' // Ex. Nov 15, 2022, 12:04 PM
  | 'PPpp' // Ex. Nov 15, 2022, 12:04:47 PM
  | 'PPPp' // Ex. November 15th, 2022 at 12:04 PM
  | 'PPPpp' // Ex. November 15th, 2022 at 12:04:47 PM
  | 'PPPPp' // Ex.  Tuesday, November 15th, 2022 at 12:04 PM
  | 'PPPPpp' // Ex. Tuesday, November 15th, 2022 at 12:04:47 PM
  | 'p' // Ex. 12:04 pm
  | 'pp' // Ex. 12:04:47 pm
  | 'LLLL d' // Ex. January 1
  | 'LLLL d, y' // Ex. January 1, 2023
  | 'iii LLL d' // Ex. Mon Jan 2
  | 'iii LLL d, y' // Ex. Mon Jan 2, 2023
  | 'LL/dd/yy'; // Ex 02/01/23

// FIXME: Figure out how to lazy import other locales and include switching this when translating
const DEFAULT_DATE_LOCALE: Locale = enUS;

export const formatDate = (date: Date, formatStr: LocaleSupportedFormatStrings) => {
  return format(date, formatStr, { locale: DEFAULT_DATE_LOCALE });
};

export const DEFAULT_UNITS_OF_MEASUREMENT =
  navigator.language === 'en-US' || navigator.language === 'en_LR' || navigator.language === 'my_MM'
    ? 'imperial'
    : 'metric';

export function displayCurrency(
  value: number | undefined,
  locale = DEFAULT_LANGUAGE_LOCALE,
  currency = enUsNumberformat.currency.currency
) {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  });
  return formatter.format(value || 0);
}

export function displayPercent(value: number | undefined, locale = DEFAULT_LANGUAGE_LOCALE) {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent'
  });
  return formatter.format(value || 0);
}

// FIXME: hack fix to prevent "Vue is undefined" error introduced with vue 2.7 upgrade
// potentially related to:
// https://github.com/intlify/vue-i18n-composable/issues/21
// https://github.com/intlify/vue-i18n-composable/issues/22
// @ts-expect-error: hack fix to prevent "Vue is undefined" error
window.Vue = Vue;

export const i18n = createI18n({
  locale: DEFAULT_LANGUAGE_LOCALE,
  fallbackLocale: DEFAULT_LANGUAGE_LOCALE,
  messages: {
    [DEFAULT_LANGUAGE_LOCALE]: enUsMessages
  },
  numberFormats: {
    [DEFAULT_LANGUAGE_LOCALE]: enUsNumberformat
  }
});

export async function translate(locale = navigator.language) {
  if (i18n.locale !== locale && SUPPORTED_LANGUAGE_CODES.includes(locale)) {
    console.log(`Switching languge to ${locale}`);
    const messages = await import(`./messages/${locale}.json`);
    const numberFormat = await import(`./numberFormats/${locale}.json`);

    i18n.setLocaleMessage(locale, messages);
    i18n.setNumberFormat(locale, numberFormat);
    i18n.locale = locale;

    const translatedTitle = i18n.t('App.Title');
    document.title = translatedTitle as string;
    return true;
  }

  return false;
}

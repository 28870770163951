var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-sidebar',{attrs:{"lazy":"","right":"","visible":_vm.showNotificationsSidePanel,"sidebar-class":"bg-transparent pr-2","no-header":"","no-footer":""}},[_c('b-card',{staticClass:"d-flex justify-content-center text-center mt-3 rounded-3"},[_c('b-button',{staticClass:"bg-white border-0 shadow-none",attrs:{"id":"hide-notifications","variant":"light"},on:{"click":_vm.hideNotificationsPanel}},[_c('b-icon',{attrs:{"icon":"x"}})],1),_c('b-tooltip',{attrs:{"target":"hide-notifications"}},[_vm._v(_vm._s(_vm.$t('Notifications.HideNotifications')))]),_c('div',{staticClass:"d-flex justify-content-center w-100 ml-3 mb-1"},[_c('h5',{staticClass:"font-weight-bold m-auto"},[_vm._v(_vm._s(_vm.$t('Notifications.Notifications')))]),_c('b-button',{staticClass:"bg-white shadow-none border-0 text-blue",on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('b-icon',{attrs:{"icon":"sliders"}})],1)],1),_c('b-form-input',{staticClass:"rounded-pill",attrs:{"placeholder":_vm.$t('Notifications.SearchNotifications')},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.showFilters)?_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('b-button',{class:{
          'p-0': true,
          'pl-2': true,
          'pr-2': true,
          'border-0': true,
          'shadow-none': true,
          'mr-auto': true,
          'ml-auto': true,
          'filter-active': _vm.filters.status === 'unread',
        },attrs:{"pill":""},on:{"click":function($event){return _vm.setFilter({ status: _vm.filters.status === 'unread' ? undefined : 'unread' })}}},[_vm._v(_vm._s(_vm.$t('Notifications.Filters.Unread')))]),_c('b-button',{class:{
          'p-0': true,
          'pl-2': true,
          'pr-2': true,
          'border-0': true,
          'shadow-none': true,
          'mr-auto': true,
          'ml-auto': true,
          'filter-active': !!_vm.filters.assigned,
        },attrs:{"pill":""},on:{"click":function($event){return _vm.setFilter({ assigned: _vm.filters.assigned ? undefined : true })}}},[_vm._v(_vm._s(_vm.$t('Notifications.Filters.Assigned')))]),_c('b-button',{class:{
          'p-0': true,
          'pl-2': true,
          'pr-2': true,
          'border-0': true,
          'shadow-none': true,
          'mr-auto': true,
          'ml-auto': true,
          'filter-active': !!_vm.filters.watchChanged,
        },attrs:{"pill":""},on:{"click":function($event){return _vm.setFilter({ watchChanged: _vm.filters.watchChanged ? undefined : true })}}},[_vm._v(_vm._s(_vm.$t('Notifications.Filters.WatchedChanged')))])],1):_vm._e()],1),_vm._l((_vm.userNotifications),function(notification){return _c('NotificationCard',{key:notification.uuid,attrs:{"notification":notification},on:{"read":_vm.handleMarkAsRead,"delete":_vm.handleDelete}})}),(_vm.fetchNotificationsInProgress)?_c('div',{staticClass:"d-flex justify-content-center align-items-center w-100 mt-2"},[_c('b-spinner',{staticClass:"dot mr-1",attrs:{"small":"","type":"grow","variant":"secondary"}}),_c('b-spinner',{staticClass:"dot mr-1",attrs:{"small":"","type":"grow","variant":"secondary"}}),_c('b-spinner',{staticClass:"dot",attrs:{"small":"","type":"grow","variant":"secondary"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
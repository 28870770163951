import axios, { AxiosError } from 'axios';
import { ref } from 'vue';

// VUE_APP_AUTH_API_BASE_URL = 'http://api.auth.test.lasso.io/app/v1',

const {
  VUE_APP_INVENTORY_API_BASE_URL = 'http://app.api.gear.local.lasso.io:9999/app/v1',
  VUE_APP_WORKFORCE_API_BASE_URL = 'http://smoketest.test.lasso.io:9996/api/internal/app',
  VUE_APP_AUTH_API_BASE_URL = 'http://api.auth.local.lasso.io:9998/app/v1',
  VUE_APP_LOGIN_URL = 'https://login.test.lasso.io',
  VUE_APP_CHAT_API_BASE_URL = 'http://chat.api.local.lasso.io:9997/app/v1',
  VUE_APP_NOTIFICATIONS_API_BASE_URL = 'http://notifications.api.local.lasso.io:9995/app/v1'
} = process.env;

const { host } = window.location;
const subdomain = ref<string>(host.split('.')[0]);
const updateSubdomain = (url: string) => {
  return url.replace(url.split('//')[1].split('.')[0], subdomain.value);
};

export function redirectToLoginPage() {
  const currentLocation = window.location.href;
  const isLocal = currentLocation.startsWith('http://');
  window.location.href = `${VUE_APP_LOGIN_URL}?redirect_uri=${currentLocation}${isLocal ? '&local' : ''}`;
}

export function redirectToSignoutPage() {
  const currentLocation = window.location.href;
  window.location.href = `${VUE_APP_LOGIN_URL}/signout?redirect_uri=${currentLocation}`;
}

const axiosInventory = axios.create({
  baseURL: VUE_APP_INVENTORY_API_BASE_URL,
  withCredentials: true, // allow server to read cookie
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosWorkforce = axios.create({
  baseURL: VUE_APP_WORKFORCE_API_BASE_URL,
  withCredentials: true, // allow server to read cookie
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosAuth = axios.create({
  baseURL: VUE_APP_AUTH_API_BASE_URL,
  withCredentials: true, // allow server to read cookie
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosChat = axios.create({
  baseURL: VUE_APP_CHAT_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosNotifications = axios.create({
  baseURL: VUE_APP_NOTIFICATIONS_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

/**
 * On 401 responses, redirect to the login page. Use the redirect_uri
 * query parameter to redirect to current URL upon success.
 */
axiosInventory.interceptors.response.use(
  (resp) => resp,
  (err: AxiosError) => {
    if (err.response && err.response.status === 401) {
      return redirectToLoginPage();
    }
    return Promise.reject(err.response);
  }
);

axiosWorkforce.interceptors.response.use(
  (resp) => resp,
  (err: AxiosError) => {
    if (err.response && err.response.status === 401) {
      return redirectToLoginPage();
    }
    return Promise.reject(err.response);
  }
);

axiosAuth.interceptors.response.use(
  (resp) => resp,
  (err: AxiosError) => {
    if (err.response && err.response.status === 401) {
      return redirectToLoginPage();
    }
    return Promise.reject(err.response);
  }
);

axiosChat.interceptors.response.use(
  (resp) => resp,
  (err: AxiosError) => {
    if (err.response && err.response.status === 401) {
      return redirectToLoginPage();
    }
    return Promise.reject(err.response);
  }
);

axiosNotifications.interceptors.response.use(
  (resp) => resp,
  (err: AxiosError) => {
    if (err.response && err.response.status === 401) {
      return redirectToLoginPage();
    }
    return Promise.reject(err.response);
  }
);

// interface HttpRequestParams {
//   method: 'post' | 'put' | 'get' | 'delete';
//   url: string;
//   data?: object;
//   baseURL?: string; // optionally for other API's
// }

// export async function makeHttpRequest(params: HttpRequestParams) {
//   const { data: dataResp } = await axiosClient({
//     ...params
//   });

//   return dataResp;
// }

export { axiosAuth, axiosInventory, axiosWorkforce, axiosChat, axiosNotifications };

import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import {
  ISerializedItem,
  ISerializedItemStatusType,
  SerializedItem,
  ChildSerializedItem,
  SerializedItemRelation
} from '@/types/inventory';
import { axiosInventory } from '@/services/httpService';
// eslint-disable-next-line import/no-cycle
import { useProductStore } from '@/store'; // https://pinia.vuejs.org/introduction.html#comparison-with-vuex-3-x-4-x
import { ITEM_STATUS_TYPE_CODE } from '@/constants';
import { doesProductHaveChildSerializedItems } from '@/validators';

interface IUniqueBarcodeApiResponse {
  barcodeCount: number;
  duplicateCount: number;
  duplicateBarcodes: string[];
}

const useItemsStore = defineStore('ItemsStore', () => {
  // product store
  const productStore = useProductStore();
  const { activeProduct } = storeToRefs(productStore);

  // store's state
  const activeProductSerializedItems = ref<SerializedItem[]>([]);
  const activeItemStatusCounts = ref<{ count: number; id: number; code: string; status: string }[]>([]);
  const itemSearchStr = ref(null);
  const activeProductSerializedItemsCurrentPage = ref<number>(0);
  const activeProductSerializedItemsLastPage = ref<number>(0);
  const itemStatusTypes = ref<ISerializedItemStatusType[]>([]);
  // Used for Virtual & locked & unlocked container product types
  // SerializedItemIds are keys on this obj
  // type : {parentSerializedItemId: ChildSerializedItem[]}
  const childSerializedItemsBySIID = ref<Record<number, ChildSerializedItem[]>>({});
  // type : {productId: SerializedItem[]}
  const serializedItemsByProductId: Record<number, SerializedItem[]> = {};

  // draft state
  const currentDraftId = ref<number>(1);
  const draftIdMap = ref<{ [draftId: number]: number | null }>({});
  const draftPlayback = ref<boolean>(false);

  /**
   * Get possible item status types from API and persist to store
   * @returns item status types
   */
  const getItemStatusTypes = async () => {
    if (itemStatusTypes.value.length === 0) {
      const { data }: { data: ISerializedItemStatusType[] } = await axiosInventory.get(
        '/items/serialized/status_types'
      );
      itemStatusTypes.value = data;
    }
    return itemStatusTypes.value;
  };

  const getProductItemStatusCounts = async () => {
    if (productStore.isNewProduct()) {
      return [];
    }
    const url = `/products/${activeProduct.value.uuid}/items/status_counts`;
    const { data } = await axiosInventory.get(url);

    return data;
  };

  /**
   * Fetch serialized items from API and persist to store
   */
  const getProductItems = async () => {
    // when drafting, store state is local/fetching is disabled
    if (productStore.isNewProduct()) return;

    activeItemStatusCounts.value = await getProductItemStatusCounts();

    if (activeProductSerializedItemsCurrentPage.value <= activeProductSerializedItemsLastPage.value) {
      const url = `/products/search/serialized?page=${activeProductSerializedItemsCurrentPage.value}`;
      const payload: { productId: number; searchStr?: string } = {
        productId: activeProduct.value.id
      };
      if (itemSearchStr.value) {
        payload.searchStr = itemSearchStr.value;
      }

      const { data } = await axiosInventory.post(url, payload);
      const {
        serializedItems: { total, rows, currentPage, totalPages }
      } = data;

      activeProductSerializedItemsCurrentPage.value = currentPage;

      const defaultStatus = itemStatusTypes.value.find((s) => s.code === ITEM_STATUS_TYPE_CODE.IN_SERVICE);
      // Virtual, Locked & Unlocked Containers
      const hasExpandableRow = doesProductHaveChildSerializedItems(activeProduct.value.productType.code);

      const items = rows.map((r: ISerializedItem) => {
        const { itemStatus } = r;
        return new SerializedItem({
          ...r,
          ...(!itemStatus?.id && {
            itemStatus: defaultStatus
          }),
          ...(hasExpandableRow && {
            _showDetails: false
          })
        });
      });

      if (activeProductSerializedItemsCurrentPage.value === 0) {
        activeProductSerializedItems.value = [...items];
        activeProductSerializedItemsLastPage.value = totalPages - 1;
      } else {
        activeProductSerializedItems.value = activeProductSerializedItems.value.concat(items);
      }
    }
  };

  /**
   * Validate that the provided barcodes are not used by other inventory
   * @param barcodes barcodes to validate
   * @returns IUniqueBarcodeApiResponse
   */
  const validateUniqueBarcodes = async (barcodes: string[]) => {
    const { data }: { data: IUniqueBarcodeApiResponse } = await axiosInventory.post(
      '/items/serialized/validate_barcodes',
      { barcodes }
    );
    return data;
  };

  /**
   * Reset serialized items store state
   */
  const resetActiveProductSerializedItems = () => {
    activeProductSerializedItemsCurrentPage.value = 0;
    activeProductSerializedItemsLastPage.value = 0;
    activeProductSerializedItems.value = [];

    draftPlayback.value = false;
    currentDraftId.value = 1;
    draftIdMap.value = {};
  };

  /**
   * Persist serialized items to API and add them to the store state
   * @param items serialized items to add
   * @returns number of items added
   */
  const addSerializedItemsBulk = async (items: Array<SerializedItem>) => {
    // simulate behavior for when product is a draft
    if (productStore.isNewProduct()) {
      // use temporary frontend-ids
      items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.id = currentDraftId.value;
        draftIdMap.value[currentDraftId.value] = null;
        currentDraftId.value += 1;
      });
      // add to serialized items list for display
      activeProductSerializedItems.value.push(...items);
      activeProduct.value.totalBarcodedInventory += items.length;
      return items.length;
    }

    const bulkData = items.map((item) => ({
      ...item,
      product: {
        id: activeProduct.value.id
      }
    }));

    const bulkResp = await axiosInventory.post('/items/serialized/bulk', bulkData);
    let newCount = 0;
    if (bulkResp.data) {
      bulkResp.data.forEach(async (item: Partial<SerializedItem>) => {
        const newItem = new SerializedItem(item);

        if (item.itemStatusId) {
          const itemStatus = itemStatusTypes.value.find((s: ISerializedItemStatusType) => s.id === item.itemStatusId);
          if (itemStatus) {
            newItem.itemStatus = {
              id: itemStatus.id,
              code: itemStatus.code,
              status: itemStatus.status
            };
          }
        }

        if (newItem.product?.id && activeProduct.value.id === newItem.product.id) {
          resetActiveProductSerializedItems();
          await getProductItems();
          // activeProductSerializedItems.value.push(newItem);
          newCount += 1;
        }

        // map draft ids to newly created backend ids
        if (draftPlayback.value && item.id) {
          draftIdMap.value[currentDraftId.value] = item.id;
          currentDraftId.value += 1;
        }
      });
    }
    activeProduct.value.totalBarcodedInventory += newCount;
    return newCount;
  };

  /**
   * Persist updates to a serialized item to the API, then call getProductItems to refresh all items
   * @param item changed item to persist, must have id
   */
  const updateSerializedItem = async (item: Partial<ISerializedItem>): Promise<void> => {
    if (!item.id) {
      throw new Error('must provide item id to update item');
    }

    const storedItemIndex = activeProductSerializedItems.value.findIndex((i) => i.id === item.id);
    const storedItem = activeProductSerializedItems.value[storedItemIndex];
    const itemToUpdate = storedItem ? { ...storedItem, ...item } : item;

    if (productStore.isNewProduct()) {
      activeProductSerializedItems.value[storedItemIndex] = new SerializedItem(itemToUpdate);
      activeProductSerializedItems.value = [...activeProductSerializedItems.value];
      return;
    }
    if (draftPlayback.value) {
      const mappedId = draftIdMap.value[item.id];
      if (!mappedId) throw new Error('persisting draft: error mapping frontend id to backend id');
      // eslint-disable-next-line no-param-reassign
      item.id = mappedId;
    }

    const { disposalDate, disposalPrice, itemCode, purchaseDate, ...props } = new SerializedItem(itemToUpdate);
    const payload = {
      ...props,
      ...(disposalDate && { disposalDate }),
      ...(disposalPrice && { disposalPrice }),
      ...(itemCode && { itemCode }),
      ...(purchaseDate && {
        purchaseDate
      })
    };

    await axiosInventory.put(`/items/serialized/${item.id}`, payload);
    await getProductItemStatusCounts();
    await getProductItems();
  };

  /**
   * Mark serialized item as disposed and persist to backend. Update item in-place in store.
   * @param item item to dispose, must have id
   */
  const deleteSerializedItem = async (item: Partial<ISerializedItem>) => {
    if (!item.id) {
      throw new Error('must provide item id to delete item');
    }

    const storedItemIndex = activeProductSerializedItems.value.findIndex((i) => i.id === item.id);

    if (productStore.isNewProduct()) {
      // mark as disposed
      // eslint-disable-next-line prefer-destructuring
      activeProductSerializedItems.value[storedItemIndex].itemStatus = itemStatusTypes.value.filter(
        (statusType) => statusType.code === 'D'
      )[0];
      activeProduct.value.totalBarcodedInventory -= 1;
      return;
    }
    if (draftPlayback.value) {
      const mappedId = draftIdMap.value[item.id];
      if (!mappedId) throw new Error('persisting draft: error mapping frontend id to backend id');
      // eslint-disable-next-line no-param-reassign
      item.id = mappedId;
    }

    const { data }: { data: ISerializedItem } = await axiosInventory.delete(`/items/serialized/${item.id}`);
    activeProductSerializedItems.value.splice(storedItemIndex, 1, new SerializedItem(data));
    activeProduct.value.totalBarcodedInventory -= 1;
  };

  // Used for options in dropdown for assigning SerializedItemRelations
  const getSerializedItemsByProductId = async (productId: number, barcode?: string) => {
    const url = `/products/search/serialized`;
    const payload: { productId: number; searchStr?: string } = {
      productId,
      ...(barcode && { searchStr: barcode })
    };

    const { data } = await axiosInventory.post(url, payload);

    // Currently not handling pagination because they are used as dropdown options
    const {
      serializedItems: { rows }
    } = data;

    const items: SerializedItem[] = rows.length ? rows.map((r: ISerializedItem) => new SerializedItem(r)) : [];

    return items;
  };

  const createSerializedItemRelation = async (serializedItemRelation: SerializedItemRelation) => {
    const url = 'items/serialized/relation';

    const { data } = await axiosInventory.post(url, serializedItemRelation);

    const newSerializedItemRelation = new SerializedItemRelation(data);

    return newSerializedItemRelation;
  };

  const deleteSerializedItemRelation = async (serializedItemRelation: SerializedItemRelation) => {
    const url = `items/serialized/relation/${serializedItemRelation.id}`;

    const { data } = await axiosInventory.delete(url);

    const deletedSerializedItemRelation = new SerializedItemRelation(data);

    return deletedSerializedItemRelation;
  };

  return {
    addSerializedItemsBulk,
    getProductItemStatusCounts,
    getProductItems,
    activeProductSerializedItems,
    activeProductSerializedItemsCurrentPage,
    activeProductSerializedItemsLastPage,
    activeItemStatusCounts,
    validateUniqueBarcodes,
    resetActiveProductSerializedItems,
    getItemStatusTypes,
    itemStatusTypes,
    updateSerializedItem,
    deleteSerializedItem,
    draftPlayback,
    currentDraftId,
    itemSearchStr,
    getSerializedItemsByProductId,
    childSerializedItemsBySIID,
    createSerializedItemRelation,
    deleteSerializedItemRelation,
    serializedItemsByProductId
  };
});

export default useItemsStore;


import { computed, defineComponent, PropType, ref } from 'vue';
import { formatRelative, compareAsc, subHours, formatDistance } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useRouter } from 'vue-composition-wrapper';
import { storeToRefs } from 'pinia';
import { IUserChangeFeed } from '@/types/notifications';
import NotificationCardChangeDetail from '@/components/Common/Notifications/NotificationCardChangeDetail.vue';
import { useNavigationStore, useNotificationsStore } from '@/store';

type CardType = 'change' | 'assignment' | 'watchChanged';

export default defineComponent({
  name: 'NotificationCard',
  components: {
    NotificationCardChangeDetail
  },
  props: {
    notification: {
      type: Object as PropType<IUserChangeFeed>,
      required: true
    }
  },
  emits: ['read', 'delete'],
  setup(props, { emit }) {
    const showDetails = ref<boolean>(false);
    const notificationsStore = useNotificationsStore();
    const isUnread = ref<boolean>(props.notification.status === 'unread');
    const navigationStore = useNavigationStore();
    const { isNxDomain, nxAppBaseUrl } = storeToRefs(navigationStore);
    const cardType = computed(() => {
      let type: CardType = 'change';

      const assignmentChanged = props.notification.internalChangeEvent.internalChangeEventMetadata.find(
        (meta) => meta.field === 'assignedUsers'
      );
      const watchersChanged = props.notification.internalChangeEvent.internalChangeEventMetadata.find(
        (meta) => meta.field === 'watchingUsers'
      );

      if (!assignmentChanged && watchersChanged) {
        type = 'watchChanged';
      } else if (!watchersChanged && assignmentChanged) {
        type = 'assignment';
      }
      return type;
    });
    const isAssignmentChange = computed(() => cardType.value === 'assignment');
    const isWatchChanged = computed(() => cardType.value === 'watchChanged');
    const isStandardChange = computed(() => cardType.value === 'change');
    const relativeDate = computed(() => {
      const now = new Date();
      const twelveHoursAgo = subHours(now, 12);
      const changeDate = new Date(props.notification.dbDateCreated);
      const moreThanTwelveHoursAgo = compareAsc(twelveHoursAgo, changeDate) === 1;

      const baseFormatOpts = {
        locale: enUS // TODO: use browser locale / user preference
      };
      if (!moreThanTwelveHoursAgo) {
        return formatDistance(changeDate, now, {
          ...baseFormatOpts,
          includeSeconds: false,
          addSuffix: true
        });
      }
      return formatRelative(changeDate, now, baseFormatOpts);
    });

    const router = useRouter();
    const goToResource = (e: Event) => {
      e.preventDefault();
      notificationsStore.setNotificationsPanelVisibility(false);
      if (props.notification.internalChangeEvent.source === 'pm_project_task') {
        const relativePath = `/events/${props.notification.internalChangeEvent.lassoEvent.code}/projects/tasks`;
        if (isNxDomain) {
          // from nx domain
          router.push({
            path: relativePath
          });
          return;
        }
        // from WF or other domain
        window.location.href = `${nxAppBaseUrl}${relativePath}`;
      }
      console.error(`No path configured for source: ${props.notification.internalChangeEvent.source}`);
    };

    // prevent double click if notifications-api is slow for some reason
    const deleteInProgress = ref<boolean>(false);
    const emitMarkAsRead = (e: Event) => {
      e.preventDefault();
      emit('read', props.notification);
      isUnread.value = false;
    };

    const emitDelete = (e: Event) => {
      e.preventDefault();
      deleteInProgress.value = true;
      emit('delete', props.notification);
    };

    return {
      showDetails,
      isUnread,
      cardType,
      isAssignmentChange,
      isWatchChanged,
      isStandardChange,
      relativeDate,
      goToResource,
      emitMarkAsRead,
      emitDelete,
      deleteInProgress
    };
  }
});

export interface IActions {
  get: boolean;
  list: boolean;
  describe: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
}

export default class Actions implements IActions {
  get = false;

  list = false;

  describe = false;

  create = false;

  update = false;

  delete = false;

  constructor(select?: Record<string, boolean>) {
    if (!select) {
      return;
    }

    this.get = select.get ?? this.get;
    this.list = select.list ?? this.list;
    this.describe = select.describe ?? this.describe;
    this.create = select.create ?? this.create;
    this.update = select.update ?? this.update;
    this.delete = select.delete ?? this.delete;
  }
}

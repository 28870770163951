/* eslint-disable max-classes-per-file */
import { IAccount } from '@/types/core/account';

export interface IVenue {
  id: number;

  uuid: string;

  airport: string | null;

  code: string | null;

  companyName: string | null;

  contactName: string | null;

  country: string | null;

  latitude: number | null;

  locality: string | null;

  longitude: number | null;

  name: string;

  postalCode: string | null;

  phone: string | null;

  region: string | null;

  status: string;

  parentVenueId: number | null;
}

export class Venue implements IVenue {
  id: number;

  uuid: string;

  airport: string | null;

  code: string | null;

  companyName: string | null;

  contactName: string | null;

  country: string | null;

  latitude: number | null;

  locality: string | null;

  longitude: number | null;

  name: string;

  postalCode: string | null;

  phone: string | null;

  region: string | null;

  status: string;

  parentVenueId: number | null;

  constructor(options?: Partial<IVenue>) {
    this.id = options?.id ?? 0;
    this.name = options?.name ?? '';
    this.uuid = options?.uuid ?? '';
    this.airport = options?.airport ?? null;
    this.code = options?.code ?? null;
    this.companyName = options?.companyName ?? null;
    this.contactName = options?.contactName ?? null;
    this.country = options?.country ?? null;
    this.latitude = options?.latitude ?? null;
    this.locality = options?.locality ?? null;
    this.longitude = options?.longitude ?? null;
    this.postalCode = options?.postalCode ?? null;
    this.phone = options?.phone ?? null;
    this.region = options?.region ?? null;
    this.status = options?.status ?? 'active';
    this.parentVenueId = options?.parentVenueId ?? null;
  }
}

export interface IVenueContact {
  id: number;

  uuid: string;

  accountId: number;
  account?: Partial<IAccount>;

  venueId: number;
  venue?: Partial<IVenue>;

  companyName?: string;

  email?: string;

  extension?: string;

  fax?: string;

  firstName?: string;

  jobTitle?: string;

  lastName?: string;

  mobile?: string;

  phone?: string;

  status?: string;

  type?: string;

  dbDateCreated?: Date;

  dbDateUpdated?: Date;

  dbDateRemoved?: Date;
}

export class VenueContact implements IVenueContact {
  id: number;

  uuid: string;

  accountId: number;

  account?: Partial<IAccount>;

  venueId: number;

  venue?: Venue;

  companyName: string;

  email?: string;

  extension?: string;

  fax?: string;

  firstName: string;

  jobTitle?: string;

  lastName: string;

  mobile?: string;

  phone?: string;

  status: string;

  type: string;

  dbDateCreated?: Date;

  dbDateUpdated?: Date;

  dbDateRemoved?: Date;

  displayName: string;

  constructor(options?: Partial<IVenueContact>) {
    this.id = options?.id ?? 0;
    this.uuid = options?.uuid ?? '';
    this.accountId = options?.accountId ?? 0;
    this.account = options?.account;
    this.venueId = options?.venueId ?? 0;
    this.venue = new Venue(options?.venue);
    this.companyName = options?.companyName ?? '';
    this.email = options?.email;
    this.extension = options?.extension;
    this.fax = options?.fax;
    this.firstName = options?.firstName ?? '';
    this.lastName = options?.lastName ?? '';
    this.jobTitle = options?.jobTitle;
    this.mobile = options?.mobile;
    this.phone = options?.phone;
    this.status = options?.status ?? '';
    this.type = options?.type ?? '';
    this.dbDateCreated = options?.dbDateCreated;
    this.dbDateUpdated = options?.dbDateUpdated;
    this.dbDateRemoved = options?.dbDateRemoved;
    // TODO: Figure out what the types are
    this.displayName = this.companyName.length ? this.companyName : [this.firstName, this.lastName].join(' ');
  }
}

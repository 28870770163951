
import { defineComponent, provide } from 'vue';
import axios from 'axios'
import { storeToRefs } from 'pinia';
import { LUserPreferencesSidebar, LUserPreferencesDropdownItem }  from '@lassoworkforce/lasso-vue-styles';
import { useNavigationStore } from '@/store';
import { redirectToSignoutPage } from '@/services/httpService';

export default defineComponent({
  name: 'UserNavMenu',
  components: {
    LUserPreferencesSidebar,
    LUserPreferencesDropdownItem
  },
  setup() {
    provide('axios', axios);
    const navigationStore = useNavigationStore()
    const { subdomain } = storeToRefs(navigationStore)
    const signOut = () => {
      redirectToSignoutPage();
    };

    return {
      signOut,
      subdomain
    };
  }
});

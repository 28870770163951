import { ILocation } from '@/types/core/geographical';

export type ContactTypeName = 'client' | 'supplier' | 'partner';

export interface IPhone {
  id: number;
  phone: string;
}

export interface IEmail {
  id: number;
  email: string;
}

export interface IContactType {
  id: number;
  contactTypeName: ContactTypeName;
}

export interface IContact {
  id: number;

  primaryPhone: Partial<IPhone>;

  secondaryPhone: Partial<IPhone>;

  email: Partial<IEmail>;

  mailingAddress: Partial<ILocation>;

  physicalAddress: Partial<ILocation>;

  billingAddress: Partial<ILocation>;

  firstName?: string;

  middleName?: string;

  lastName?: string;

  suffix?: string;

  businessName?: string;

  contactType: IContactType;
}

export class Contact implements Partial<IContact> {
  id: number;

  primaryPhone: Partial<IPhone>;

  secondaryPhone?: Partial<IPhone>;

  email: Partial<IEmail>;

  mailingAddress?: Partial<ILocation>;

  physicalAddress?: Partial<ILocation>;

  billingAddress?: Partial<ILocation>;

  firstName?: string;

  middleName?: string;

  lastName?: string;

  suffix?: string;

  contactType: IContactType;

  businessName?: string;

  get displayName() {
    // FIXME: Localize first name last name order
    return this.businessName?.length ? this.businessName : `${this.firstName} ${this.lastName}`;
  }

  constructor(options: Partial<IContact>) {
    const {
      id = 0,
      primaryPhone = { id: 0, phone: '' },
      secondaryPhone,
      email = { id: 0, email: '' },
      contactType,
      billingAddress,
      mailingAddress,
      physicalAddress,
      businessName = '',
      firstName = '',
      middleName = '',
      lastName = '',
      suffix = ''
    } = options;
    this.id = id;
    this.physicalAddress = physicalAddress;
    this.mailingAddress = mailingAddress;
    this.billingAddress = billingAddress;
    this.primaryPhone = primaryPhone;
    this.secondaryPhone = secondaryPhone;
    this.email = email;
    this.contactType = {
      id: contactType?.id || 0,
      contactTypeName: contactType?.contactTypeName || 'client'
    };
    this.businessName = businessName;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.suffix = suffix;
  }
}

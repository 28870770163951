// why we disable the following lint rule: https://pinia.vuejs.org/introduction.html#comparison-with-vuex-3-x-4-x
/* eslint-disable import/no-cycle */
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import useAccountingStore from './accounting';
import useContactStore from './contacts';
import useCountriesStore from './countries';
import useDepartmentStore from './departments';
import useEquipmentListStore from './equipmentLists';
import useEventStore from './eventStore';
import useJobStore from './job';
import useItemsStore from './items';
import useItemsDraftStore from './itemsDraft';
import useItemTrasactionsStore from './itemTransactions';
import useNavigationStore from './navigation';
import usePermissionsStore from './permissions';
import usePriceStrategyStore from './priceStrategy';
import useProductStore from './products';
import useProjectStore from './project';
import useProjectAttachmentsStore from './projectAttachmentsStore';
import useProposalStore from './proposal';
import useSettingsStore from './settingsStore';
import useTransportStore from './transport';
import useUserConfigStore from './userConfig';
import useWarehouseStore from './warehouse';
import useUserStore from './user';
import useChatStore from '@/store/chatStore';
import useNotificationsStore from '@/store/notifications';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default pinia;

export {
  useAccountingStore,
  useContactStore,
  useCountriesStore,
  useEquipmentListStore,
  useEventStore,
  useDepartmentStore,
  useJobStore,
  useItemsStore,
  useItemsDraftStore,
  useItemTrasactionsStore,
  usePermissionsStore,
  usePriceStrategyStore,
  useProductStore,
  useProjectStore,
  useProjectAttachmentsStore,
  useProposalStore,
  useNavigationStore,
  useSettingsStore,
  useTransportStore,
  useUserConfigStore,
  useWarehouseStore,
  useUserStore,
  useChatStore,
  useNotificationsStore
};

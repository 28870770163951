
import { defineComponent, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '@/store';
import { LIconVenue, LIconMarketplace } from '../Icons/NavIcons';

export default defineComponent({
  name: 'AdminNavMenu',
  components: {
    LIconVenue,
    LIconMarketplace
  },
  props: {
    customFieldsFeature: Boolean,
    marketplaceFinanceViewPermission: Boolean, // TODO: not used?
    documentLibraryViewPermission: Boolean,
    documentMgmtFeature: Boolean,
    dataImportPermission: Boolean,
    dataExportPermission: Boolean,
    accountIsMarketplace: Boolean,
    userIsAdmin: Boolean, // TODO: not used?
    userIsLasso: Boolean,
    marketplacePageAllowed: Boolean
  },
  setup(props) {
    const navigationStore = useNavigationStore();
    const { navSettings } = storeToRefs(navigationStore);

    const clientsLink = navSettings.value.admin.clients;
    const venuesLink = navSettings.value.admin.venues;
    const vendorsLink = navSettings.value.admin.vendors;

    const marketplacePath = computed(() =>
      props.marketplacePageAllowed && props.accountIsMarketplace ? navSettings.value.admin.marketplace : ''
    );
    const marketplaceLink = computed(() =>
      // non-MP needs to load `next_finance.html` to get all needed stripe/plaid keys
      props.marketplacePageAllowed && !marketplacePath.value ? navSettings.value.admin.marketplace : ''
    );
    const documentsLibraryLink = computed(() => {
      if (!props.documentMgmtFeature) {
        return navSettings.value.admin.document_library_help;
      }
      if (props.documentLibraryViewPermission) {
        return navSettings.value.admin.document_library;
      }
      return '';
    });
    const companySettingsLink = navSettings.value.admin.settings;
    const usersLink = navSettings.value.admin.users_directory;

    const importExportLink = computed(() => {
      if (props.dataImportPermission) {
        return navSettings.value.admin.import;
      }
      if (props.dataExportPermission) {
        return navSettings.value.admin.export;
      }
      return '';
    });
    const staffLink = computed(() => (props.userIsLasso ? navSettings.value.admin.staff : ''));

    return {
      clientsLink,
      venuesLink,
      vendorsLink,
      documentsLibraryLink,
      companySettingsLink,
      usersLink,
      importExportLink,
      staffLink,
      marketplacePath,
      marketplaceLink
    };
  }
});

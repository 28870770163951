var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VMultiselect',{ref:"multiselectRef",class:{'border-danger': _vm.required && (!_vm.componentModelObject || !_vm.componentModelObject[_vm.valueProp]), 'normal-font': true },attrs:{"options":_vm.options,"label":_vm.displayProp,"trackBy":_vm.displayProp,"searchable":_vm.searchable,"noOptionsText":_vm.noOptionsText,"noResultsText":_vm.noResultsText,"clearOnSearch":_vm.clearOnSearch,"delay":_vm.asyncMode ? 100 : -1,"placeholder":_vm.placeholder,"valueProp":_vm.valueProp,"minChars":_vm.minChars,"object":true,"disabled":_vm.disabled,"canDeselect":false,"canClear":_vm.canClear,"resolveOnLoad":_vm.resolveOnLoad,"internalSearch":_vm.internalSearch,"showNoResults":_vm.showNoResults,"loading":_vm.loading,"required":_vm.required,"clearOnSelect":_vm.clearOnSelect,"closeOnSelect":_vm.closeOnSelect,"openDirection":_vm.openDirection,"mode":_vm.mode,"id":_vm.id,"createOption":_vm.createOption,"data-test-id":`${_vm.dataTestIdString}-multiselect`,"classes":{
    option: 'normal-font p-2',
    optionSelected: 'bg-primary',
    optionPointed: 'bg-light',
    tag: 'multiselect-tag bg-gray-600',
    optionSelectedPointed: 'bg-primary',
    containerActive: 'focus',
    placeholder: 'multiselect-placeholder font-weight-light',
    singleLabelText: 'p-0 d-block mw-100 text-truncate',
    singleLabel: 'multiselect-single-label',
    optionDisabled: 'is-disabled text-gray-500'
  }},on:{"change":_vm.change,"search-change":_vm.handleSearchChange},scopedSlots:_vm._u([{key:"option",fn:function(obj){return [_c('li',[_c('p',{staticClass:"m-0 p-0"},[_vm._v(" "+_vm._s(obj.option[_vm.displayProp])+" "),(!obj.option[_vm.valueProp] && !!obj.option[_vm.displayProp] && _vm.allowAddnew)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t('CommonButtons.AddNewLowercase'))+")")]):_vm._e()])])]}}]),model:{value:(_vm.componentModelObject),callback:function ($$v) {_vm.componentModelObject=$$v},expression:"componentModelObject"}})
}
var staticRenderFns = []

export { render, staticRenderFns }
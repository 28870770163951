import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { v4 as v4Uuid } from 'uuid';
import { isArray, sortBy } from 'lodash';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { formatDate } from '@/localization/i18n';
// eslint-disable-next-line import/no-cycle
import { useEventStore, useEquipmentListStore } from '@/store/index';
import { EquipmentList, EquipmentListGroup } from '@/types/events/equipmentList';
import { IRouteOption, ITruckPkg, QuoteEstimate } from '@/types/events/transport';

const useTransportStore = defineStore('TransportStore', () => {
  const { t } = useI18n();
  const equipmentListStore = useEquipmentListStore();
  const eventStore = useEventStore();

  const TIME_STRING_DISPLAY_FORMAT = ref<string>('h:mm aaa');
  const TIME_STRING_FORMAT = ref<string>('HH:mm');
  const TIME_INPUT_ALLOWED_FORMATS = ref<string[]>(['haaaaa', 'h aaaaa', 'h:mm aaa', 'HH:mm']);

  const { activeEvent } = storeToRefs(eventStore);
  const equipmentLists = ref<EquipmentList[]>([]);
  const timezone = ref<string>('EST');
  const shippingLists = ref<number[]>([]);
  const returnLists = ref<number[]>([]);
  const truckLists = ref<ITruckPkg[]>([]);
  const routeOptions = ref<IRouteOption[]>([]);
  const gettingMarketplaceQuote = ref<boolean>(false);
  const marketplaceEstimate = ref<QuoteEstimate | null>(null);
  const userAcceptedEstimate = ref<boolean | null>(null);

  const getGearListData = async () => {
    equipmentLists.value = await equipmentListStore.getEquipmentListsForEvent();
  };

  const equipmentShipList = computed(() => {
    return equipmentLists.value.filter((l) => shippingLists.value.includes(l.id));
  });

  const equipmentReturnList = computed(() => {
    return equipmentLists.value.filter((l) => returnLists.value.includes(l.id));
  });

  const minShipDate = computed(() => {
    if (equipmentShipList.value.length > 0) {
      const orderedList = sortBy(equipmentShipList.value, 'dateLeave', 'asc');
      return orderedList[0].dateLeave;
    }
    return null;
  });

  const minShipArrivalDate = computed(() => {
    if (equipmentShipList.value.length > 0) {
      const orderedList = sortBy(equipmentShipList.value, 'dateLoadIn', 'asc');
      return orderedList[0].dateLoadIn;
    }
    return null;
  });

  const maxReturnDate = computed(() => {
    if (equipmentReturnList.value.length > 0) {
      const orderedList = sortBy(equipmentReturnList.value, 'dateStrikeBegins', 'desc');
      return orderedList[0].dateStrikeBegins;
    }
    return '';
  });

  const maxReturnArrivalDate = computed(() => {
    if (equipmentReturnList.value.length > 0) {
      const orderedList = sortBy(equipmentReturnList.value, 'dateReturn', 'desc');
      return orderedList[0].dateReturn;
    }
    return '';
  });

  const getMarketplaceEstimate = (data: QuoteEstimate) => {
    setTimeout(() => {
      marketplaceEstimate.value = data;
      marketplaceEstimate.value.estimationDate = new Date();
    }, 3000);
  };

  const getTimeBaseStr = (val: string) => {
    return val.toLowerCase().substring(0, val.toLowerCase().slice(-1) === 'm' ? val.length - 1 : val.length);
  };

  const getTimeStringFormat = (val: string) => {
    const baseString = getTimeBaseStr(val);
    const found = TIME_INPUT_ALLOWED_FORMATS.value.filter((fmt: string) => {
      try {
        return isValid(parse(baseString, fmt, new Date()));
      } catch {
        return null;
      }
    });

    return isArray(found) && found.length > 0 ? found[0] : null;
  };

  const timeFormatter = (val?: string) => {
    if (!val) {
      return val;
    }

    const currFmt = getTimeStringFormat(val);
    const baseStr = getTimeBaseStr(val);
    return currFmt ? formatDate(parse(baseStr, currFmt, new Date()), 'p') : val;
  };

  const timeFmtToDate = (val: string) => {
    return parse(val, TIME_STRING_FORMAT.value, new Date());
  };

  const getEquipmentListDetails = async (newList: EquipmentList[]) => {
    const newListData: any[] = [];

    await Promise.all(
      newList.map(async (l: EquipmentList) => {
        const {
          total: _total,
          totalPages: _totalPages,
          currentPage: _currentPage,
          rows: listGroups
        } = await equipmentListStore.getEquipmentListGroups(l.id, 0, 100);

        const listDetails = await equipmentListStore.getEquipmentListDetails(l.id);
        return { l, listGroups, listDetails };
      })
    ).then((results) => {
      results.forEach((r) => {
        let listItemCount = 0;
        const groups: EquipmentListGroup[] = [];
        if (r.listGroups && r.listGroups.length) {
          r.listGroups.forEach((group) => {
            groups.push(new EquipmentListGroup({ ...group }));
            listItemCount += group.equipmentListLineItems.length;
          });
        }
        newListData.push({ ...r.l, groups, listItemCount });

        truckLists.value = [
          {
            uuid: v4Uuid(),
            trucks: [
              {
                truckSize: 12,
                truckType: t('Event.Transport.TruckCards.BoxTruck').toString(),
                icon: 'truck',
                plate: 'IVN-345',
                vin: '5678K1234567'
              },
              {
                truckSize: 20,
                truckType: t('Event.Transport.TruckCards.Truck').toString(),
                icon: 'truck',
                plate: 'IVN-754',
                vin: '5678K1234568'
              }
            ]
          },
          {
            uuid: v4Uuid(),
            trucks: [
              {
                truckSize: 50,
                truckType: t('Event.Transport.TruckCards.Truck').toString(),
                icon: 'truck',
                plate: 'IVN-464',
                vin: '5678K1234568'
              }
            ]
          },
          {
            uuid: v4Uuid(),
            trucks: [
              {
                truckSize: 12,
                truckType: t('Event.Transport.TruckCards.BoxTruck').toString(),
                icon: 'truck',
                plate: 'IVN-846',
                vin: '5678K1234569'
              },
              {
                truckSize: 12,
                truckType: t('Event.Transport.TruckCards.Truck').toString(),
                icon: 'truck',
                plate: 'IVN-364',
                vin: '5678K1234570'
              }
            ]
          },
          {
            uuid: v4Uuid(),
            trucks: [
              {
                truckSize: 12,
                truckType: t('Event.Transport.TruckCards.BoxTruck').toString(),
                icon: 'truck',
                plate: 'IVN-908',
                vin: '5678K1234571'
              }
            ]
          }
        ];
        routeOptions.value = [
          {
            time: { hr: 3, min: 58 }, // minutes
            distance: 266, // miles
            arrivalTime: '11:05 am EST'
          },
          {
            time: { hr: 4, min: 23 },
            distance: 274, // miles
            arrivalTime: '11:15 am EST'
          },
          {
            time: { hr: 4, min: 32 },
            distance: 284, // miles
            arrivalTime: '11:35 am EST'
          }
        ];
      });
    });

    return newListData;
  };

  return {
    activeEvent,
    equipmentLists,
    equipmentShipList,
    equipmentReturnList,
    minShipDate,
    minShipArrivalDate,
    maxReturnDate,
    maxReturnArrivalDate,
    timezone,
    truckLists,
    routeOptions,
    shippingLists,
    returnLists,
    getGearListData,
    getEquipmentListDetails,
    gettingMarketplaceQuote,
    marketplaceEstimate,
    getMarketplaceEstimate,
    userAcceptedEstimate,
    getTimeBaseStr,
    getTimeStringFormat,
    timeFmtToDate,
    timeFormatter,
    TIME_INPUT_ALLOWED_FORMATS,
    TIME_STRING_DISPLAY_FORMAT,
    TIME_STRING_FORMAT
  };
});

export default useTransportStore;

/* eslint-disable max-classes-per-file */
import { v4 as v4Uuid } from 'uuid';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICountry } from '@/types/core/geographical';
import { IUser, User } from '@/types/core';
import { EnumProductType } from '@/types/core/product';
import { IContact, Contact } from '../contacts';
import { DEFAULT_UNITS_OF_MEASUREMENT, i18n, displayCurrency } from '@/localization/i18n';
import { IDepreciationMethod, IGeneralLedger } from '@/types/accounting';

export interface ISerializedItemStatusType {
  id: number;
  status: string;
  code: string;
  disabled?: boolean; // property added in on the frontend
}

export interface IProductType {
  id: number;
  productType: typeof EnumProductType[keyof typeof EnumProductType] | '';
  code: string;
}

export class ProductType implements IProductType {
  id: number;

  productType: typeof EnumProductType[keyof typeof EnumProductType] | '';

  code: string;

  constructor(options: IProductType) {
    this.id = options.id;
    this.productType = options.productType;
    this.code = options.code || '';
  }
}

export interface IDepartment {
  id: number;
  departmentName: string;
  uuid: string;
}

export class Department implements IDepartment {
  id: number;

  departmentName: string;

  uuid: string;

  constructor(department: Partial<IDepartment>) {
    this.id = department.id || 0;
    this.departmentName = department.departmentName || '';
    this.uuid = department.uuid || '';
  }
}

interface IDepartmentCreateInput {
  departmentName: string;
}

export class DepartmentCreate implements Partial<IDepartment> {
  departmentName: string;

  constructor(options: IDepartmentCreateInput) {
    this.departmentName = options.departmentName;
  }
}

export interface ICategory {
  id: number;
  categoryName: string;
  description?: string;
  uuid: string;
  department?: IDepartment;
  archived: boolean;
}

export class Category implements Partial<ICategory> {
  id: number;

  categoryName: string;

  description?: string;

  uuid: string;

  department?: IDepartment;

  constructor(options: Partial<ICategory>) {
    this.id = options.id || 0;
    this.categoryName = options.categoryName || '';
    this.description = options.description || '';
    this.uuid = options.uuid || '';
    if (options.department) {
      this.department = new Department(options.department);
    }
  }
}

interface ICategoryCreateInput {
  categoryName: string;
  description?: string;
  uuid: string;
  departmentId: number;
}

export class CategoryCreate implements Partial<ICategory> {
  categoryName: string;

  description?: string;

  uuid: string;

  departmentId: number;

  constructor(options: ICategoryCreateInput) {
    this.categoryName = options.categoryName;
    this.description = options.description;
    this.uuid = options.uuid;
    this.departmentId = options.departmentId;
  }
}

export interface IWarehouse {
  id: number;
  title: string;
}

export class Warehouse implements IWarehouse {
  id: number;

  title: string;

  constructor(options: IWarehouse) {
    this.id = options.id;
    this.title = options.title;
  }
}

export interface IItemStatus {
  id: number;
  status: string;
}

export interface IWarehouseLocation {
  id: number;
  warehouseLocationName: string;
}

export interface IProductPriceStrategy {
  id: number;

  productId: number;

  priceStrategyId: number;
}

type BillingMultiplierPeriodicalUnit = 'hour' | 'day' | 'sale';

export interface IPriceStrategy {
  id: number;

  accountId: number | null;

  billingMultiplier: number;

  billingMultiplierPeriodicalUnit: BillingMultiplierPeriodicalUnit;

  billingMultiplierPeriodLimit: number;

  displayName: string;

  defaultStrategy: boolean;

  productPriceStrategy?: IProductPriceStrategy; // this would only be populated when eager loading products with price strategies
}

export class PriceStrategy implements Partial<IPriceStrategy> {
  id: number;

  accountId?: number | null;

  billingMultiplier: number;

  billingMultiplierPeriodicalUnit: BillingMultiplierPeriodicalUnit;

  billingMultiplierPeriodLimit: number;

  displayName: string;

  defaultStrategy: boolean;

  productPriceStrategy?: IProductPriceStrategy;

  constructor(options: Partial<IPriceStrategy>) {
    this.id = options.id || 0;
    this.accountId = options.accountId;
    this.billingMultiplier = options.billingMultiplier || 1;
    const defaultPeriodicalUnit: BillingMultiplierPeriodicalUnit = 'day';
    this.billingMultiplierPeriodicalUnit = options.billingMultiplierPeriodicalUnit || defaultPeriodicalUnit;
    this.billingMultiplierPeriodLimit = options.billingMultiplierPeriodLimit || 1;
    this.displayName = options.displayName || '';
    if (options.productPriceStrategy) {
      this.productPriceStrategy = options.productPriceStrategy;
    }
    this.defaultStrategy = options.defaultStrategy || false;
  }
}

export interface IUnserializedInput {
  quantity: number;
  minUnitPrice?: number;
  date?: Date;
  poNumber?: string;
  note?: string;
}

export type ProductReminderRoundType = 'Up' | 'Exact';

export type ProductRelationType = 'Bundle' | 'Reminder' | 'Component';

export interface IProductRelationType {
  id: number;

  productRelationType: ProductRelationType;
}

export interface IReminder {
  id: number;

  per: number;

  charge: boolean;

  max: number;

  quantity: number;

  round: ProductReminderRoundType;

  prompt: boolean;

  required: boolean;

  product: {
    id: number;

    productName: string;

    description?: string;
  };
}

export class Reminder implements IReminder {
  id = 0;

  per = 1;

  charge = true;

  max = 0;

  quantity = 1;

  round: ProductReminderRoundType = 'Exact';

  prompt = false;

  required = false;

  product: { id: number; productName: string; description?: string } = {
    id: 0,
    productName: '',
    description: ''
  };

  constructor(select?: Partial<IReminder>) {
    if (!select) {
      return;
    }

    this.id = select.id ?? this.id;
    this.per = select.per ?? this.per;
    this.charge = select.charge ?? this.charge;
    this.max = select.max ?? this.max;
    this.quantity = select.quantity ?? this.quantity;
    this.round = select.round ?? this.round;
    this.prompt = select.prompt ?? this.prompt;
    this.required = select.required ?? this.required;
    this.product = select.product ?? this.product;
  }
}

export interface IComponent {
  id: number;

  product: {
    id: number;

    productName: string;

    description?: string;
  };

  quote: boolean;

  quantity: number;
}

export class Component implements IComponent {
  id = 0;

  quantity = 1;

  quote = false;

  product: { id: number; productName: string; description?: string } = {
    id: 0,
    productName: '',
    description: ''
  };

  constructor(select?: Partial<IComponent>) {
    if (!select) {
      return;
    }

    this.id = select.id ?? this.id;
    this.quote = select.quote ?? this.quote;
    this.quantity = select.quantity ?? this.quantity;
    this.product = select.product ?? this.product;
  }
}

export interface IBundle {
  // TODO: Determine what fields go here
  id: number;

  product: {
    id: number;

    productName: string;

    description?: string;
  };

  quantity: number;
}

export class Bundle implements IBundle {
  id = 0;

  quantity = 1;

  product: { id: number; productName: string; description?: string } = {
    id: 0,
    productName: '',
    description: ''
  };

  constructor(select?: Partial<IComponent>) {
    if (!select) {
      return;
    }

    this.id = select.id ?? this.id;
    this.quantity = select.quantity ?? this.quantity;
    this.product = select.product ?? this.product;
  }
}

export interface IProductImage {
  id: number;

  publicImageUrl: string;

  defaultImage: boolean;
}

export class ProductImage implements IProductImage {
  id = 0;

  publicImageUrl = '';

  defaultImage = false;

  constructor(options: Partial<IProductImage>) {
    if (options.id) this.id = options.id;
    if (options.publicImageUrl) this.publicImageUrl = options.publicImageUrl;
    if (options.defaultImage) this.defaultImage = options.defaultImage;
  }
}

export interface IProductAccountingInfo {
  id: number;
  numberOfDays: number | null;
  revenueCode: IGeneralLedger | null;
  expenseCode: IGeneralLedger | null;
  depreciationType: IDepreciationMethod | null;
}

export class ProductAccountingInfo implements IProductAccountingInfo {
  id: number;

  numberOfDays: number | null;

  revenueCode: IGeneralLedger | null;

  expenseCode: IGeneralLedger | null;

  depreciationType: IDepreciationMethod | null;

  constructor(input: Partial<IProductAccountingInfo>) {
    this.id = input.id || 0;
    this.numberOfDays = input.numberOfDays || null;
    this.revenueCode = input.revenueCode || null;
    this.expenseCode = input.expenseCode || null;
    this.depreciationType = input.depreciationType || null;
  }
}

export interface IProductDocumentType {
  id: number;
  documentTypeName: string;
}

export interface IProductDocument {
  id: number;
  fileName: string;
  originalFileName: string;
  description?: string;
  publicUrl?: string;
  documentTypeId?: number | null;
  accountId?: number | null;
}

export class ProductDocument implements IProductDocument {
  id = 0;

  originalFileName = '';

  fileName = '';

  accountId = 0;

  constructor(options: Partial<IProductDocument>) {
    this.id = options?.id ?? 0;
    this.originalFileName = options?.originalFileName || '';
    this.fileName = options?.fileName || '';
    if (options.accountId) {
      this.accountId = options.accountId;
    }
  }
}

export interface IProductDocumentRendererParams extends ICellRendererParams {
  data: ProductDocument;
  productUuid: string;
}

export interface IProductDocumentDownloadParams extends IProductDocument {
  productUuid: string;
}

export interface IProduct {
  id: number;

  uuid: string;

  accountId: number;

  productName: string;

  productType: IProductType;

  category: Partial<ICategory>;

  description?: string;

  fullText?: string;

  productCode?: string;

  taxable: boolean;

  units: 'imperial' | 'metric';

  height?: number;

  width?: number;

  depth?: number;

  weight?: number;

  rentalRate?: number;

  insuredValue?: number;

  warehouse?: IWarehouse;

  ownerWarehouse?: IWarehouse;

  maxDiscount?: number;

  warehouseLocation?: Partial<IWarehouseLocation>;

  originCountry?: ICountry;

  priceStrategies?: Partial<IPriceStrategy>[];

  supplier?: Partial<IContact>;

  unserializedQuantity: number;

  reminders?: Partial<IReminder>[];

  components?: Partial<IComponent>[];

  productImages?: IProductImage[];

  productDocuments?: IProductDocument[];

  totalBarcodedInventory?: number;

  productAccounting?: IProductAccountingInfo;

  reorderLevel?: number;
}

export class Product implements Partial<IProduct> {
  id: number;

  uuid: string;

  accountId: number;

  productName: string;

  productType: IProductType;

  category: Partial<ICategory>;

  description?: string;

  fullText?: string;

  productCode?: string;

  taxable: boolean;

  units: 'imperial' | 'metric';

  height?: number;

  width?: number;

  depth?: number;

  weight?: number;

  rentalRate?: number;

  insuredValue?: number;

  warehouse?: IWarehouse;

  ownerWarehouse?: IWarehouse;

  maxDiscount: number;

  warehouseLocation?: Partial<IWarehouseLocation>;

  originCountry?: ICountry;

  priceStrategies?: Partial<IPriceStrategy>[];

  supplier?: Contact;

  unserializedQuantity: number;

  components: Component[];

  reminders: Reminder[];

  productImages: ProductImage[];

  productDocuments: ProductDocument[];

  totalBarcodedInventory: number;

  productAccounting?: ProductAccountingInfo;

  reorderLevel?: number;

  get productCategoryName() {
    return this.category.categoryName;
  }

  get dimensions() {
    if (!this.depth && !this.width && !this.height) {
      return '';
    }
    const i18nStr = this.units
      ? `UnitsOfMeasurement.${this.units[0].toUpperCase() + this.units.slice(1)}.DimensionAbbreviation`
      : '';
    const i18nDimensions = i18n.t(i18nStr).toString();
    const height = this.height ? `x ${this.height} ${i18nDimensions} H` : '';
    return `${this.depth} ${i18nDimensions} D x ${this.width} ${i18nDimensions} W ${height}`;
  }

  get weightWithUnits() {
    if (!this.weight) {
      return '';
    }
    const i18nStr = this.units
      ? `UnitsOfMeasurement.${this.units[0].toUpperCase() + this.units.slice(1)}.WeightAbbreviation`
      : '';
    const i18nDimensions = i18n.t(i18nStr).toString();
    return `${this.weight} ${i18nDimensions}`;
  }

  get productTableCell() {
    return {
      id: this.id,
      productName: this.productName
    };
  }

  get capitalUnits() {
    return `${this.units[0].toLocaleUpperCase()}${this.units.substring(1, this.units.length)}`;
  }

  get unitsI18nDimension() {
    return this.units ? `UnitsOfMeasurement.${this.capitalUnits}.DimensionAbbreviation` : '';
  }

  get unitsI18nWeight() {
    return this.units ? `UnitsOfMeasurement.${this.capitalUnits}.WeightAbbreviation` : '';
  }

  get totalInventory() {
    return this.unserializedQuantity + this.totalBarcodedInventory;
  }

  constructor(options: Partial<IProduct>) {
    this.id = options.id || 0;
    this.accountId = options.accountId || 0;
    this.uuid = options.uuid || v4Uuid();
    this.productName = options.productName || '';

    this.productType = new ProductType(
      options.productType || {
        id: 0,
        productType: '',
        code: ''
      }
    );

    this.category = new Category(
      options.category || {
        id: 0,
        categoryName: '',
        department: new Department({})
      }
    );

    this.description = options.description;
    this.fullText = options.fullText;
    this.productCode = options.productCode;
    this.taxable = options.taxable ?? true;
    this.units = options.units ?? DEFAULT_UNITS_OF_MEASUREMENT;
    this.height = options.height;
    this.width = options.width;
    this.depth = options.depth;
    this.weight = options.weight;
    this.rentalRate = options.rentalRate;
    this.insuredValue = options.insuredValue;
    this.maxDiscount = options.maxDiscount || 100;
    this.originCountry = options.originCountry || {
      id: 0,
      alphaCode2: '',
      alphaCode3: '',
      name: '',
      fullName: '',
      numericCode: 0
    };

    this.reminders = options.reminders?.map((r) => new Reminder(r)) || [];

    this.components = options.components?.map((r) => new Component(r)) || [];

    this.priceStrategies = options.priceStrategies || [];

    if (options.warehouse) {
      this.warehouse = new Warehouse(options.warehouse);
    }

    if (options.ownerWarehouse) {
      this.ownerWarehouse = new Warehouse(options.ownerWarehouse);
    }

    this.warehouseLocation = options.warehouseLocation || { id: 0, warehouseLocationName: '' };

    this.supplier = new Contact(
      options.supplier || {
        contactType: {
          id: 0,
          contactTypeName: 'supplier'
        }
      }
    );

    this.unserializedQuantity = options.unserializedQuantity ?? 0;
    this.productImages = options.productImages?.map((i) => new ProductImage(i)) || [];
    this.productDocuments = options.productDocuments?.map((d) => new ProductDocument(d)) || [];
    this.totalBarcodedInventory = options.totalBarcodedInventory || 0;

    if (options.productAccounting) {
      this.productAccounting = new ProductAccountingInfo(options.productAccounting);
    } else {
      this.productAccounting = {
        id: 0,
        numberOfDays: null,
        revenueCode: null,
        expenseCode: null,
        depreciationType: null
      };
    }

    if (options.reorderLevel) {
      this.reorderLevel = options.reorderLevel;
    }
  }

  getDisplayProductType() {
    return this.productType
      ? this.productType.productType.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
      : '';
  }
}

export interface ISerializedItemRelation {
  id: number;
  parentSerializedItemId: number;
  childSerializedItemId: number;
  childSerializedItem: { barcode: string; productId: number };
}

export class SerializedItemRelation implements ISerializedItemRelation {
  id = 0;

  parentSerializedItemId = 0;

  childSerializedItemId = 0;

  childSerializedItem = { barcode: '', productId: 0 };

  constructor(options: ISerializedItemRelation) {
    this.id = options.id ?? this.id;
    this.parentSerializedItemId = options.parentSerializedItemId ?? this.parentSerializedItemId;
    this.childSerializedItemId = options.childSerializedItemId ?? this.childSerializedItemId;
    this.childSerializedItem = options.childSerializedItem ?? this.childSerializedItem;
  }

  get childSerializedItemBarcode() {
    return this.childSerializedItem.barcode;
  }

  get childSerializedItemProductId() {
    return this.childSerializedItem.productId;
  }
}

export interface ISerializedItem {
  id: number;

  itemStatus: Partial<ISerializedItemStatusType>;

  barcode: string;

  itemCode: string;

  warehouseLocation: Partial<IWarehouseLocation>;

  disposalDate: Date | string;

  disposalPrice: number;

  purchaseDate: Date | string;

  purchasePrice: number;

  poNumber: string;

  replacementPrice: number;

  productId: number;

  relations: ISerializedItemRelation[];
}

export class SerializedItem implements Partial<ISerializedItem> {
  id: number;

  itemStatusId: number;

  itemStatus: ISerializedItemStatusType;

  barcode?: string;

  itemCode?: string;

  warehouseLocation?: Partial<IWarehouseLocation>;

  disposalDate?: Date | undefined;

  disposalPrice?: number;

  purchaseDate: Date | undefined;

  purchasePrice?: number;

  poNumber?: string;

  replacementPrice?: number;

  product?: Partial<Product>;

  relations: SerializedItemRelation[];

  constructor(options: Partial<ISerializedItem>) {
    const { id = 0, itemStatus, productId, purchaseDate, disposalDate, relations, ...otherProps } = options;
    this.id = id;
    this.itemStatusId = 0;
    this.itemStatus = {
      id: itemStatus?.id || 0,
      code: itemStatus?.code || '',
      status: itemStatus?.status || ''
    };
    this.purchaseDate = purchaseDate ? new Date(purchaseDate) : undefined;
    this.disposalDate = disposalDate ? new Date(disposalDate) : undefined;
    this.product = {
      id: productId
    };
    this.relations = relations?.length ? relations.map((relation) => new SerializedItemRelation(relation)) : [];
    Object.assign(this, otherProps);
  }
}

// Used in SerializedItemsChildTable.vue
export type ChildSerializedItem = {
  productId: number;
  productName: string;
  serializedItemRelation: SerializedItemRelation | null;
  serializedItemRelationOptions: SerializedItemRelation[];
};

export type ItemTransactionType = 'Add' | 'Dispose';

export interface IItemTransactionLogSerializedItem {
  id: number;

  serializedItem: Partial<ISerializedItem>;
}

export class ItemTransactionLogSerializedItem {
  id: number;

  serializedItem: Partial<ISerializedItem>;

  constructor(options: Partial<IItemTransactionLogSerializedItem>) {
    const { id = 0, serializedItem } = options;

    this.id = id;
    this.serializedItem = {
      id: serializedItem?.id || 0,
      barcode: serializedItem?.barcode || '',
      itemCode: serializedItem?.itemCode || ''
    };
  }
}

export interface ITransactionLog {
  id: number;

  transaction: ItemTransactionType;

  unserializedQuantityChange: number;

  date: string;

  minUnitPrice: number;

  maxUnitPrice: number;

  totalPrice: number;

  formattedUnitPriceRange: string;

  user: Partial<IUser>;

  note: string;

  poNumber: string;

  itemTransactionLogSerializedItems: IItemTransactionLogSerializedItem[];

  _showDetails: boolean;
}

export class TransactionLog implements Partial<ITransactionLog> {
  id: number;

  transaction: ItemTransactionType;

  unserializedQuantityChange: number;

  numberOfItemsInTransaction: number;

  date: string;

  minUnitPrice: number;

  maxUnitPrice: number;

  totalPrice: number;

  formattedUnitPriceRange: string;

  user: User;

  userDisplayName: string;

  note: string;

  itemTransactionLogSerializedItems: ItemTransactionLogSerializedItem[];

  barcodes: string[];

  poNumber: string;

  _showDetails: boolean;

  constructor(options: Partial<ITransactionLog>) {
    const {
      id = 0,
      unserializedQuantityChange = 0,
      transaction = 'Add',
      date = new Date().toISOString(),
      minUnitPrice = 0,
      maxUnitPrice = 0,
      totalPrice = 0,
      user = {},
      note = '',
      itemTransactionLogSerializedItems = [],
      _showDetails = false,
      poNumber = ''
    } = options;

    this.id = id;
    this.transaction = transaction;
    this.unserializedQuantityChange = unserializedQuantityChange;
    this.date = date;
    this.minUnitPrice = minUnitPrice;
    this.maxUnitPrice = maxUnitPrice;
    this.totalPrice = totalPrice;
    this.user = new User(user);
    this.note = note;
    this.itemTransactionLogSerializedItems = itemTransactionLogSerializedItems.map(
      (i) => new ItemTransactionLogSerializedItem(i)
    );
    this._showDetails = _showDetails;
    this.numberOfItemsInTransaction = this.getItemQuantity();
    this.userDisplayName = this.getTransactionUserFullname();
    this.formattedUnitPriceRange = this.getFormattedUnitPriceRange();
    this.barcodes = this.getBarcodes();
    this.poNumber = poNumber;
  }

  getTransactionUserFullname() {
    const firstNameFormatted = this.user.firstName
      ? this.user.firstName[0].toLocaleUpperCase() + this.user.firstName.substring(1, this.user.firstName.length)
      : '';
    const lastNameFormatted = this.user.lastName
      ? this.user.lastName[0].toLocaleUpperCase() + this.user.lastName.substring(1, this.user.lastName.length)
      : '';
    return `${firstNameFormatted} ${lastNameFormatted}`.trim();
  }

  getFormattedUnitPriceRange() {
    return displayCurrency(this.minUnitPrice) + (this.maxUnitPrice ? ` - ${displayCurrency(this.maxUnitPrice)}` : '');
  }

  getItemQuantity() {
    return this.unserializedQuantityChange + this.itemTransactionLogSerializedItems.length;
  }

  getBarcodes() {
    const barcodes: string[] = this.itemTransactionLogSerializedItems.reduce((prev, curr) => {
      const { serializedItem } = curr;
      const { barcode } = serializedItem;
      if (barcode) {
        prev.push(barcode);
      }
      return [...prev];
    }, [] as string[]);

    return barcodes;
  }
}

/* eslint-disable max-classes-per-file */
import { ICellRendererParams, CellClickedEvent, ValueGetterParams } from '@ag-grid-community/core';
import { IProduct, IProductRelationType, IWarehouse, Warehouse } from '@/types/inventory';
import { IEvent, Event } from '@/types/events';
import { IUser } from '@/types/core';
import { IPaginatedResponse } from '@/types/api/common';
import { displayCurrency, i18n } from '@/localization/i18n';
import { IAccount } from '@/types/core/account';

export interface IAccountDeliveryMethod {
  id: number;
  name: string;
  accountId: number;
}

export interface IAccountPickupMethod {
  id: number;
  name: string;
  accountId: number;
}

export interface IAccountEventStaus {
  id: number;

  uuid: string;

  accountId: number;

  account?: IAccount;

  name?: string;

  dbDateCreated?: Date;

  dbDateUpdated?: Date;

  dbDateRemoved?: Date;
}

export interface IEquipmentList {
  id: number;
  uuid: string; // TODO: Add to EquipmentLsit Table on backend
  code: string;
  title: string;
  type: 'Rental' | 'SubRental' | 'Transfer/Loan' | string; // 'Rental' | 'SubRental' | 'Transfer/Loan'
  notesPublic: string;
  notesInternal: string;
  dateLeave?: Date | null;
  dateLoadIn?: Date | null;
  dateShowReady?: Date | null;
  dateShowEnds?: Date | null;
  dateStrikeBegins?: Date | null;
  dateReturn?: Date | null;
  discountPercent: number;
  discountPrice: number;
  chargeServiceTotal?: number;
  chargeDamageWaiver?: number;
  chargeInsurance?: number;
  taxAmount?: number;
  sortingOrder: number;
  dateCreated: Date | null;
  chargeTotalLocked: boolean;

  /* relations */

  accountId: number;
  eventId: number;
  event?: IEvent | Event;

  equipmentListStatusId: number;
  equipmentListStatus?: IAccountEventStaus;

  warehouseId?: number;
  warehouse?: IWarehouse;

  deliveryMethodId?: number;
  deliveryMethod?: IAccountDeliveryMethod;

  pickupMethodId?: number;
  pickupMethod?: IAccountPickupMethod;

  createdByUserId: number;
  createdByUser?: IUser;

  /* calculated */

  chargeDays?: number;
  preDiscountPrice?: number;
  discountedPrice?: number;
  preTaxSubtotal?: number;
  equipmentListTotal?: number;
  totalListValue?: number;
  totalListWeight?: number;
}

export class EquipmentList {
  id: number;

  uuid: string;

  code: string;

  title: string;

  type: 'Rental' | 'SubRental' | 'Transfer/Loan' | string;

  notesPublic: string;

  notesInternal: string;

  dateLeave?: Date | null;

  dateLoadIn?: Date | null;

  dateShowReady?: Date | null;

  dateShowEnds?: Date | null;

  dateStrikeBegins?: Date | null;

  dateReturn?: Date | null;

  // ISO 4217
  discountPercent: number;

  discountPrice: number;

  chargeServiceTotal?: number;

  chargeDamageWaiver?: number;

  chargeInsurance?: number;

  taxAmount?: number;

  sortingOrder: number;

  dateCreated: Date | null;

  chargeTotalLocked: boolean;

  /* relations */

  accountId: number;

  eventId: number;

  event?: Event;

  equipmentListStatusId: number;

  equipmentListStatus: IAccountEventStaus;

  warehouseId?: number;

  warehouse?: IWarehouse;

  deliveryMethodId?: number;

  deliveryMethod?: IAccountDeliveryMethod;

  pickupMethodId?: number;

  pickupMethod?: IAccountPickupMethod;

  createdByUserId: number;

  createdByUser?: IUser;

  /* calculated */

  chargeDays: number;

  preDiscountPrice: number;

  discountedPrice: number;

  preTaxSubtotal: number;

  equipmentListTotal: number;

  totalListValue: number;

  totalListWeight: number;

  constructor(options?: Partial<IEquipmentList>) {
    this.id = options?.id ?? 0;
    this.uuid = options?.uuid ?? '';
    this.code = options?.code ?? '';
    this.title = options?.title ?? '';
    this.type = options?.type ?? 'Rental';
    this.notesPublic = options?.notesPublic ?? '';
    this.notesInternal = options?.notesInternal ?? '';
    this.discountPercent = options?.discountPercent ?? 0;
    this.discountPrice = options?.discountPrice ?? 0;
    this.sortingOrder = options?.sortingOrder ?? 0;
    this.dateCreated =
      typeof options?.dateCreated === 'string' ? new Date(options.dateCreated) : options?.dateCreated ?? new Date();
    this.accountId = options?.accountId ?? 0;
    this.eventId = options?.eventId ?? 0;
    // eslint-disable-next-line no-nested-ternary
    this.event = options?.event
      ? options.event instanceof Event
        ? options.event
        : new Event(options.event)
      : new Event();
    this.equipmentListStatusId = options?.equipmentListStatusId ?? 0;
    this.equipmentListStatus = options?.equipmentListStatus ?? { id: 0, name: '', uuid: '', accountId: this.accountId };
    this.createdByUserId = options?.createdByUserId ?? 0;
    this.chargeDays = options?.chargeDays ?? 0;
    this.preDiscountPrice = options?.preDiscountPrice ?? 0;
    this.discountedPrice = options?.discountedPrice ?? 0;
    this.preTaxSubtotal = options?.preTaxSubtotal ?? 0;
    this.equipmentListTotal = options?.equipmentListTotal ?? 0;
    this.totalListValue = options?.totalListValue ?? 0;
    this.totalListWeight = options?.totalListWeight ?? 0;
    this.dateLeave = options?.dateLeave;
    this.dateLoadIn = options?.dateLoadIn;
    this.dateShowReady = options?.dateShowReady;
    this.dateShowEnds = options?.dateShowEnds;
    this.dateStrikeBegins = options?.dateStrikeBegins;
    this.dateReturn = options?.dateReturn;
    this.chargeTotalLocked = options?.chargeTotalLocked ?? false;
    this.warehouse = options?.warehouse;
  }
}

export interface IEquipmentListGroup {
  id: number;
  name: string;
  notesPublic: string;
  notesInternal: string;
  sortingOrder: number;

  /* relations */

  equipmentListId: number;
  equipmentList?: IEquipmentList;

  // eslint-disable-next-line no-use-before-define
  equipmentListLineItems?: Partial<IEquipmentListLineItem>[];

  /* calculated */

  groupValue?: number;
  groupWeight?: number;
  groupTotal?: number;
}

export class EquipmentListGroup implements IEquipmentListGroup {
  id: number;

  name: string;

  notesPublic: string;

  notesInternal: string;

  sortingOrder: number;

  /* relations */

  equipmentListId: number;

  equipmentList?: IEquipmentList;

  // eslint-disable-next-line no-use-before-define
  equipmentListLineItems: Partial<IEquipmentListLineItem>[];

  /* calculated */

  groupValue?: number;

  groupWeight?: number;

  groupTotal?: number;

  constructor(options?: Partial<IEquipmentListGroup>) {
    this.id = options?.id ?? 0;
    this.name = options?.name ?? i18n.t('Event.Equipment.Table.DefaultGroup').toString();
    this.notesInternal = options?.notesInternal ?? '';
    this.notesPublic = options?.notesPublic ?? '';
    this.sortingOrder = options?.sortingOrder ?? 0;
    this.equipmentListId = options?.equipmentListId ?? 0;
    this.equipmentList = options?.equipmentList;
    this.groupValue = options?.groupValue;
    this.groupWeight = options?.groupWeight;
    this.groupTotal = options?.groupTotal;
    this.equipmentListLineItems = options?.equipmentListLineItems ?? [];
  }
}

export interface IEquipmentListLineItemRelation {
  id: number;
  productRelationTypeId: number;
  productRelationType?: IProductRelationType;
  parentLineItemId: number;
  // eslint-disable-next-line no-use-before-define
  parentLineItem?: IEquipmentListLineItem;
  childLineItemId: number;
}

export interface IEquipmentListLineItem {
  id: number;
  quantity: number;
  dailyRate: number;
  multiplier: number;
  chargeQuantity: number;
  discountPercent: number;
  notesPublic?: string;
  notesInternal?: string;
  itemsOut: number;
  itemsBack: number;
  itemsOnQuote: number;

  /* relations */

  equipmentListGroupId: number | null;
  equipmentListGroup?: IEquipmentListGroup;

  equipmentListId: number;
  equipmentList?: IEquipmentList;

  productId: number;
  product?: IProduct;

  // relations that include the parent id of this line item
  parentLineItems?: IEquipmentListLineItemRelation[];
  // relations that include child line item ids of any other line items assigned to this one
  childLineItems?: IEquipmentListLineItemRelation[];
  parentLineItemId?: number;

  /* calculated */

  preDiscountSubtotal?: number;
  postDiscountTotal?: number;
}

interface IEquipmentListLineItemOptions extends Partial<IEquipmentListLineItem> {
  $isRowGroup?: boolean;
}

export class EquipmentListLineItem implements IEquipmentListLineItem {
  id: number;

  quantity: number;

  dailyRate: number;

  multiplier: number;

  chargeQuantity: number;

  discountPercent: number;

  notesPublic: string;

  notesInternal: string;

  itemsOut: number;

  itemsBack: number;

  itemsOnQuote: number;

  /* relations */

  equipmentListGroupId: number | null;

  equipmentListGroup?: IEquipmentListGroup;

  equipmentListId: number;

  equipmentList?: IEquipmentList;

  productId: number;

  product?: IProduct;

  // relations that include the parent id of this line item
  parentLineItems?: IEquipmentListLineItemRelation[];

  // relations that include child line item ids of any other line items assigned to this one
  childLineItems?: IEquipmentListLineItemRelation[];

  parentLineItemId: number;

  get parentLineItemProductName() {
    const parentLineItem = this.parentLineItems?.length ? this.parentLineItems[0] : null;
    const name = parentLineItem?.parentLineItem?.product?.productName;
    return name || this.product?.productName || '';
  }

  /* calculated */
  get preDiscountSubtotal() {
    return this.chargeQuantity * this.multiplier * this.dailyRate;
  }

  postDiscountTotal?: number;

  /* Lasso Grid Option */
  $isRowGroup: boolean;

  // from equipmentListGroup.name, but set at the top level for ag grid
  groupName: string;

  constructor(options?: IEquipmentListLineItemOptions) {
    this.id = options?.id ?? 0;
    this.quantity = options?.quantity ?? 1;
    this.dailyRate = options?.dailyRate ?? 0;
    this.multiplier = options?.multiplier ?? 1;
    this.chargeQuantity = options?.chargeQuantity ?? this.quantity;
    this.discountPercent = options?.discountPercent ?? 0;
    this.itemsOut = 0;
    this.itemsBack = 0;
    this.itemsOnQuote = 0;
    this.equipmentListId = options?.equipmentListId ?? options?.equipmentList?.id ?? 0;
    this.equipmentList = options?.equipmentList;
    this.equipmentListGroup = options?.equipmentListGroup;
    this.equipmentListGroupId = options?.equipmentListGroupId ?? options?.equipmentListGroup?.id ?? null;
    this.productId = options?.productId ?? options?.product?.id ?? 0;
    this.product = options?.product;
    this.postDiscountTotal = options?.postDiscountTotal;

    this.parentLineItems = options?.parentLineItems ?? [];
    this.childLineItems = options?.childLineItems ?? [];

    const parentLineItem = this.parentLineItems?.length ? this.parentLineItems[0] : null;
    this.parentLineItemId = options?.parentLineItemId ?? parentLineItem?.id ?? this.id;

    this.notesInternal = options?.notesInternal ?? '';
    this.notesPublic = options?.notesPublic ?? '';
    this.$isRowGroup = options?.$isRowGroup ?? false;
    this.groupName = options?.equipmentListGroup?.name ?? '';
  }
}

export interface IListEquipmentListsResponse extends IPaginatedResponse {
  rows: IEquipmentList[];
}

export interface IListEquipmentListGroupsResponse extends IPaginatedResponse {
  rows: EquipmentListGroup[];
}

export interface IListEquipmentListLineItemsResponse extends IPaginatedResponse {
  rows: EquipmentListLineItem[];
}

export interface ILineItemProductCellRendererParams extends ICellRendererParams {
  data: EquipmentListLineItem;
}

export interface IEquipmentListLineItemCellClickEvent extends CellClickedEvent {
  data: EquipmentListLineItem;
}

export interface IEquipmentListValueGetterParams extends ValueGetterParams {
  data: EquipmentListLineItem;
}

export interface IUpdateEquipmentListLineItemResponse {
  lineItemsUpdated: IEquipmentListLineItem[];
  lineItemsAdded: IEquipmentListLineItem[];
}

export interface IEquipmentListAggregateData {
  id: number;
  eventId: number;
  event?: IEvent;
  chargeDelivery: number;
  chargeLabor: number;
  chargeService: number;
  chargeTax: number;
  chargeDamageWaiver: number;
  aggregateProductInsurance: number;
  aggregateEquipmentListTotal: number;
}

export class EquipmentListAggregateData {
  id: number;

  eventId: number;

  event?: IEvent;

  chargeTax: number;

  get displayChargeTax() {
    return displayCurrency(this.chargeTax, undefined, this.event?.currency);
  }

  chargeDelivery: number;

  get displayChargeDelivery() {
    return displayCurrency(this.chargeDelivery, undefined, this.event?.currency);
  }

  chargeLabor: number;

  get displayChargeLabor() {
    return displayCurrency(this.chargeLabor, undefined, this.event?.currency);
  }

  chargeService: number;

  get displayChargeService() {
    return displayCurrency(this.chargeLabor, undefined, this.event?.currency);
  }

  chargeDamageWaiver: number;

  get displayChargeDamageWaiver() {
    return displayCurrency(this.chargeDamageWaiver, undefined, this.event?.currency);
  }

  aggregateEquipmentListTotal: number;

  get displayAggregateEquipmentListTotal() {
    return displayCurrency(this.aggregateEquipmentListTotal, undefined, this.event?.currency);
  }

  aggregateProductInsurance: number;

  get displayAggregateProductInsurance() {
    return displayCurrency(this.aggregateProductInsurance, undefined, this.event?.currency);
  }

  constructor(options?: Partial<IEquipmentListAggregateData>) {
    this.id = options?.id ?? 0;
    this.eventId = options?.eventId ?? 0;
    this.event = options?.event;
    this.chargeDelivery = options?.chargeDelivery ?? 0;
    this.chargeTax = options?.chargeTax ?? 0;

    this.chargeLabor = options?.chargeLabor ?? 0;
    this.chargeService = options?.chargeService ?? 0;
    this.chargeDamageWaiver = options?.chargeDamageWaiver ?? 0;
    this.aggregateEquipmentListTotal = options?.aggregateEquipmentListTotal ?? 0;
    this.aggregateProductInsurance = options?.aggregateProductInsurance ?? 0;
  }
}

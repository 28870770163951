import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import type { ColDef } from '@ag-grid-community/core';
import { v4 as v4Uuid } from 'uuid';
import { axiosInventory } from '@/services/httpService';
import {
  Product,
  IProduct,
  IProductType,
  ProductType,
  IUnserializedInput,
  Reminder,
  Component,
  Department,
  IProductDocument,
  IProductDocumentType,
  PriceStrategy
} from '@/types/inventory';
import { IApiSuccess, IPaginatedProductsResponse } from '@/types/api/inventory/responses';
import {
  ICreatePublicUploadUrlResponse,
  ISearchInventoryPayload,
  ICreateDocumentUploadUrlResponse
} from '@/types/api/gear/responses/inventory';
import { i18n, displayCurrency } from '@/localization/i18n';
import { IDepreciationMethod } from '@/types/accounting';
import { IFilterRequest, FilterSelectedIds } from '@/types/componentInput';
// eslint-disable-next-line import/no-cycle
import { usePriceStrategyStore } from '@/store';

const useProductStore = defineStore('ProductStore', () => {
  // nested store
  const priceStrategyStore = usePriceStrategyStore();

  const { priceStrategies: priceStrategyData } = storeToRefs(priceStrategyStore);

  // state
  const products = ref<Product[]>([]);
  const productTypes = ref<ProductType[]>([]);

  const activeProduct = ref<Product>(new Product({})); // used for edit or detailed view
  const activeRelationsTab = ref<number>(0);
  const productRelationsDeleteQueue = ref<number[]>([]);
  const totalProductCount = ref<number>(0);
  const documentTypes = ref<IProductDocumentType[]>([]);

  // paging....
  const allProductsCurrentPage = ref<number>(0);
  const allProductsLastPage = ref<number>(0);
  const productTableSearchInput = ref<string>('');
  const searchInventoryCurrentPage = ref<number>(0);
  const searchInventoryNumPages = ref<number>(0);
  const productSearchCurrentPage = ref<number>(0);
  const productSearchLastPage = ref<number>(0);

  // Get whether the active product has ever been persisted to the API
  const isNewProduct = () => {
    return activeProduct.value.id === 0;
  };

  const getDefaultStrategy = (product: Product) => {
    if (product) {
      if (product.productType.code === 'C') {
        return priceStrategyData.value.find((p: PriceStrategy) => {
          return p.accountId === null && p.billingMultiplierPeriodicalUnit === 'sale';
        });
      }

      const accountDefaultStrategy = priceStrategyData.value.find((p: PriceStrategy) => {
        return p.accountId === product.accountId && p.defaultStrategy === true;
      });
      const globalDefaultStrategy = priceStrategyData.value.find((p: PriceStrategy) => {
        return p.accountId === null && p.defaultStrategy === true;
      });
      return accountDefaultStrategy || globalDefaultStrategy;
    }
    return null;
  };

  const getRelevantProductFields = (product: Product) => {
    const { supplier, warehouseLocation, priceStrategies, ...otherProps } = product;

    const relevantFields = {
      ...otherProps,
      ...(supplier?.id && { supplier }),
      ...((warehouseLocation?.id || warehouseLocation?.warehouseLocationName) && { warehouseLocation }),
      priceStrategies: [...new Set(priceStrategies)]
    };
    return relevantFields;
  };

  const mapIds = (inputIds: number[] | number) => {
    if (Array.isArray(inputIds)) {
      return inputIds.map((id: number) => ({ id }));
    }
    return { id: inputIds };
  };

  const filterProducts = async (selectedFilters: FilterSelectedIds) => {
    if (selectedFilters && Object.keys(selectedFilters).length) {
      const filters: IFilterRequest = {};

      Object.keys(selectedFilters).forEach((key) => {
        switch (key) {
          case 'productType':
            filters.productTypes = mapIds(selectedFilters[key]);
            break;
          case 'department':
            filters.department = mapIds(selectedFilters[key]);
            break;
          case 'category':
            filters.categoryIds = mapIds(selectedFilters[key]);
            break;
          case 'expense':
            filters.expenseIds = mapIds(selectedFilters[key]);
            break;
          case 'revenue':
            filters.revenueIds = mapIds(selectedFilters[key]);
            break;
          case 'country':
            filters.originCountryIds = mapIds(selectedFilters[key]);
            break;
          case 'priceStrategy':
            filters.priceStrategyIds = mapIds(selectedFilters[key]);
            break;
          default:
            break;
        }
      });
      const resp = await axiosInventory.post('/products/search', {
        filter: filters
      });
      const { data } = resp;
      const { rows, totalPages: totalPageResp, total } = data;

      products.value = rows?.map((product: IProduct) => new Product(product));
    }
  };

  // actions
  const createProduct = async (product: Product, setActive?: boolean): Promise<IApiSuccess> => {
    const payload = getRelevantProductFields(product);
    const { data, status, statusText }: { data: IProduct; status: number; statusText?: string } =
      await axiosInventory.post('/products', payload);
    const savedProduct = new Product(data);
    products.value.push(savedProduct);
    if (setActive) activeProduct.value = savedProduct;
    return {
      data,
      status,
      statusText
    };
  };

  const updateProduct = async (product: Product) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { unserializedQuantity, ...payload } = getRelevantProductFields(product);
    const { data, status, statusText }: { data: IProduct; status: number; statusText: string } =
      await axiosInventory.put(`/products/${product.id}`, payload);
    // update the store with the updated product
    products.value = products.value.map((p) => (p.id === product.id ? new Product(data) : p));
    return {
      status,
      statusText
    };
  };

  const searchInventory = async (
    searchInput?: string,
    filters?: FilterSelectedIds,
    page?: number,
    lastPage?: number
  ) => {
    const payload: ISearchInventoryPayload = {};
    if (searchInput) {
      payload.searchStr = searchInput;
    }
    if (filters && Object.keys(filters).length) {
      const filterPayload: FilterSelectedIds = {};
      Object.keys(filters).forEach((key) => {
        switch (key) {
          case 'productType':
            filterPayload.productTypes = filters[key];
            break;
          case 'department':
            filterPayload.department = filters[key];
            break;
          case 'category':
            filterPayload.categoryIds = filters[key];
            break;
          case 'expense':
            filterPayload.expenseIds = filters[key];
            break;
          case 'revenue':
            filterPayload.revenueIds = filters[key];
            break;
          case 'country':
            filterPayload.originCountryIds = filters[key];
            break;
          case 'priceStrategy':
            filterPayload.priceStrategyIds = filters[key];
            break;
          default:
            break;
        }
      });
      payload.filter = filterPayload;
    }
    const { data }: { data: IPaginatedProductsResponse } = await axiosInventory.post(
      `/products/search_inventory?page=${page}`,
      payload
    );

    const { total, rows, totalPages, currentPage } = data;

    // console.log({ page, total, rows, totalPages, currentPage });
    const productsReturned = rows.map((r) => new Product(r));
    if (page === 0) {
      products.value = productsReturned;
    } else {
      products.value = products.value.concat(productsReturned);
    }
    searchInventoryNumPages.value = totalPages;
    searchInventoryCurrentPage.value = currentPage;
  };

  const resetInventorySearchPageCount = () => {
    searchInventoryCurrentPage.value = 0;
    searchInventoryNumPages.value = 0;
  };

  // product relations search
  const searchProducts = async (
    searchInput: string,
    department?: Department,
    types: ProductType[] = productTypes.value
  ) => {
    let productsBySearch: Product[] = [];

    if (productSearchCurrentPage.value <= productSearchLastPage.value) {
      const { data }: { data: IPaginatedProductsResponse } = await axiosInventory.post('/products/search', {
        text: searchInput,
        includeFields: ['productName', 'description'],
        filter: {
          ...(types.length && { productTypes: types }),
          ...(department?.id && { department })
        },
        page: productSearchCurrentPage.value
      });

      const { rows, totalPages: totalPageResp } = data;

      productSearchLastPage.value = totalPageResp - 1;

      productsBySearch = rows?.map((product: IProduct) => new Product(product));
    }
    return productsBySearch;
  };

  const resetProductSearchPageCount = () => {
    productSearchCurrentPage.value = 0;
    productSearchLastPage.value = 0;
  };

  const addReminderToActiveProduct = async (product: Partial<IProduct>) => {
    if (!product.id) {
      console.error('No product dd provided');
      return null;
    }

    const newReminder = new Reminder({
      product: { id: product.id, productName: product.productName || '', description: product.description || '' }
    });

    const foundIndex = activeProduct.value.reminders.findIndex((r) => r.product.id === product.id);

    if (foundIndex < 0) {
      activeProduct.value.reminders.push(newReminder);
    } else {
      activeProduct.value.reminders[foundIndex].quantity += 1;
    }
    return null;
  };

  const addComponentToActiveProduct = async (product: Partial<IProduct>) => {
    if (!product.id) {
      console.error('No product dd provided');
      return null;
    }

    const newComponent = new Component({
      product: { id: product.id, productName: product.productName || '', description: product.description || '' }
    });

    const foundIndex = activeProduct.value.components.findIndex((r) => r.product.id === product.id);

    if (foundIndex < 0) {
      activeProduct.value.components.push(newComponent);
    } else {
      activeProduct.value.components[foundIndex].quantity += 1;
    }
    return null;
  };

  const resetProductRelationsDeleteQueue = () => {
    productRelationsDeleteQueue.value = [];
  };

  const deleteProductRelationsDeleteQueue = async () => {
    const statuses: { data: any; status: number; statusText: string }[] = [];
    productRelationsDeleteQueue.value.forEach(async (relationId) => {
      const { data, status, statusText } = await axiosInventory.delete(`products/relations/${relationId}`);
      statuses.push({ data, status, statusText });
    });

    return statuses;
  };

  const addProductRelationToDeleteQueue = (id: number) => {
    productRelationsDeleteQueue.value.push(id);
  };

  /**
   * getProducts() will incrementally add products to the store each invocation
   */
  const getProducts = async (page?: number): Promise<null> => {
    if (allProductsCurrentPage.value <= allProductsLastPage.value) {
      const url = `/products${allProductsCurrentPage.value ? `?page=${allProductsCurrentPage.value}` : ''}`;
      try {
        const { data }: { data: IPaginatedProductsResponse } = await axiosInventory.get(url);
        const { rows, totalPages, total, currentPage } = data;
        totalProductCount.value = total;

        const rawProductRows: IProduct[] = rows;
        const productsReturned = rawProductRows.map((r) => new Product(r));
        // console.log('ALL PRODUCTS ....', { rows, totalPages, total, currentPage });

        if (allProductsCurrentPage.value === 0) {
          products.value = productsReturned;
        } else {
          products.value = products.value.concat(productsReturned);
        }
        allProductsCurrentPage.value = currentPage;
        allProductsLastPage.value = totalPages;
      } catch (e) {
        console.log(`ERROR: ${JSON.stringify(e)}`);
      }
    }
    return null;
  };

  const resetAllProducts = async () => {
    products.value = [];

    allProductsCurrentPage.value = 0;
    allProductsLastPage.value = 0;

    await getProducts();
  };

  const getProduct = async (productUuid: string) => {
    const { data }: { data: IProduct } = await axiosInventory.get(`/products/${productUuid}`);
    const product = new Product(data);
    return product;
  };

  const getProductTypes = async () => {
    const { data }: { data: IProductType[] } = await axiosInventory.get('/product_types');
    productTypes.value = data.map((pt) => new ProductType(pt));
  };

  const setNewActiveProduct = async (productType: IProductType) => {
    const product = new Product({
      productName: '',
      productType,
      uuid: v4Uuid()
    });
    if (product.priceStrategies?.length === 0) {
      const defaultStrategy = getDefaultStrategy(product);
      if (defaultStrategy) {
        product.priceStrategies = [defaultStrategy];
      }
    }
    const { data }: { data: IDepreciationMethod[] } = await axiosInventory.get('/accounting/depreciation_types');
    // default depreciation to straight line in the new Product
    const straightline = data.find((d: IDepreciationMethod) => d.name.toLowerCase() === 'straight line');
    if (product.productAccounting) {
      Object.assign(product.productAccounting, {
        depreciationType: straightline
      });
    }

    activeProduct.value = product;
  };

  const setActiveProduct = async (productUuid: string): Promise<null> => {
    const product = await getProduct(productUuid);
    if (product.priceStrategies?.length === 0) {
      const defaultStrategy = getDefaultStrategy(product);
      if (defaultStrategy) {
        product.priceStrategies = [defaultStrategy];
      }
    }
    if (product.productAccounting?.depreciationType === null) {
      const { data }: { data: IDepreciationMethod[] } = await axiosInventory.get('/accounting/depreciation_types');
      // default depreciation to straight line in the new Product
      const straightline = data.find((d: IDepreciationMethod) => d.name.toLowerCase() === 'straight line');
      Object.assign(product.productAccounting, {
        depreciationType: straightline
      });
    }
    const { data } = await axiosInventory.get(`/products/${productUuid}/documents`);
    product.productDocuments = data.rows;

    activeProduct.value = product;

    const idx = products.value.findIndex((p) => p.id === product.id.valueOf());
    if (idx >= 0) {
      products.value.splice(idx, 1, product);
    } else {
      products.value.push(product);
    }
    return null;
  };

  /**
   * updateUnserializedQuantity will Add Or Delete Inventory
   * for Add pass positive integer & for Delete pass negative integer
   */
  const updateUnserializedQuantity = async (inputData: IUnserializedInput) => {
    if (isNewProduct()) {
      activeProduct.value.unserializedQuantity += inputData.quantity;

      return {
        status: 200,
        statusText: inputData.quantity
      };
    }

    const payload = {
      quantity: inputData.quantity,
      date: inputData.date,
      minUnitPrice: inputData.minUnitPrice,
      poNumber: inputData.poNumber,
      note: inputData.note
    };
    const { data, status, statusText }: { data: IProduct; status: number; statusText?: string } =
      await axiosInventory.put(`/products/${activeProduct.value.uuid}/unserialized`, payload);
    // if call is successful update activeProduct.value.unserializedQuantity
    if (status === 200) {
      activeProduct.value.unserializedQuantity = data.unserializedQuantity;
    }

    return {
      status,
      statusText
    };
  };

  // product images
  const getUploadUrlForActiveProduct = async (fileName: string, defaultImage?: boolean) => {
    if (!activeProduct.value) {
      throw new Error('No product is selected');
    }
    const { uuid, id } = activeProduct.value;
    const url = `/products/${id === 0 ? 'new' : uuid}/images/create_upload_url`;
    const { data }: { data: ICreatePublicUploadUrlResponse } = await axiosInventory.post(url, {
      fileName,
      ...(defaultImage !== undefined && { defaultImage })
    });
    return data;
  };

  // product documents
  const getDocumentUploadUrlForActiveProduct = async (fileName: string, title?: string) => {
    if (!activeProduct.value) {
      throw new Error('No product is selected');
    }
    const { uuid, id } = activeProduct.value;
    const url = `/products/${id === 0 ? 'new' : uuid}/documents/create_upload_url`;
    const { data }: { data: ICreateDocumentUploadUrlResponse } = await axiosInventory.post(url, {
      fileName,
      description: title || ''
    });
    return data;
  };

  const getDocumentTypes = async () => {
    if (documentTypes.value.length === 0) {
      const { data }: { data: IProductDocumentType[] } = await axiosInventory.get('/document_types');
      documentTypes.value = data;
    }
  };

  const updateProductDocument = async (productUuid: string, document: Partial<IProductDocument>) => {
    await axiosInventory.put(`/products/${productUuid}/document/${document.id}`, document);
    await setActiveProduct(productUuid);
  };

  // product table
  const handleCellChange = async (event: { data: IProduct }) => {
    const { data } = event;
    const productRaw: IProduct = data;
    const product = new Product(productRaw);
    updateProduct(product);
  };

  const formatCurrency = (field: { value: null | string | number }) => {
    const num = typeof field.value === 'string' ? parseInt(field.value, 10) : field.value;
    return displayCurrency(num || 0);
  };

  const productTableHeaderClass = ['d-flex', 'border-left-0', 'border-right-0', 'border-bottom-0', 'text-justify'];
  const productTableGridOptions = ref<ColDef[]>([
    {
      field: 'productType.code',
      headerName: i18n.t('Inventory.ProductTable.Type').toString(),
      cellRenderer: 'ProductTableProductTypeCell',
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 0,
      cellClass: 'p-0',
      lockVisible: true,
      lockPosition: 'left',
      minWidth: 40,
      width: 60
    },
    {
      field: 'productName',
      headerName: i18n.t('Inventory.Product.Product').toString(),
      sortable: true,
      resizable: true,
      headerClass: ['border-right-0', 'border-bottom-0'],
      cellRenderer: 'ProductTableProductNameCell',
      cellClass: 'p-0',
      sortIndex: 1,
      lockVisible: true,
      lockPosition: 'left',
      minWidth: 200
    },
    {
      field: 'category.department.departmentName',
      headerName: i18n.t('Inventory.ProductTable.Department').toString(),
      sortable: true,
      lockVisible: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 2
    },
    {
      field: 'category.categoryName',
      headerName: i18n.t('Inventory.Category').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 3
    },
    {
      field: 'rentalRate',
      headerName: i18n.t('Financials.RentalRate').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      cellClass: 'text-right',
      valueFormatter: formatCurrency,
      sortIndex: 4
    },
    {
      field: 'priceStrategies',
      headerName: i18n.t('Inventory.ProductTable.PriceStrategy').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 5,
      valueFormatter: (field: { value: { id: number; displayName: string }[] }) => {
        return field.value?.length ? field.value[0].displayName : '';
      }
    },
    {
      field: 'maxDiscount',
      headerName: i18n.t('Inventory.ProductTable.MaxDiscount').toString(),
      sortable: true,
      resizable: true,
      sortIndex: 6,
      valueFormatter: (field: { value: null | string | number }) => {
        const num = typeof field.value === 'string' ? parseInt(field.value, 10) : field.value;
        return `${num}%`;
      },
      headerClass: productTableHeaderClass,
      cellClass: 'text-right'
    },
    {
      field: 'insuredValue',
      headerName: i18n.t('Inventory.ProductTable.InsuredValue').toString(),
      sortable: true,
      resizable: true,
      valueFormatter: formatCurrency,
      sortIndex: 7,
      headerClass: productTableHeaderClass,
      cellClass: 'text-right'
    },
    {
      field: 'taxable',
      headerName: i18n.t('Inventory.ProductTable.Taxed').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 8,
      cellClass: 'd-flex justify-content-center',
      cellRenderer: 'ProductTableTaxableCell' // imported in ProductTable.vue
    },
    {
      field: 'warehouseLocation.warehouseLocationName',
      headerName: i18n.t('Inventory.ProductTable.WarehouseLocation').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 9
    },
    {
      field: 'dimensions',
      headerName: i18n.t('Inventory.ProductTable.Dimensions').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 10
    },
    {
      field: 'originCountry.name',
      headerName: i18n.t('Inventory.ProductTable.CountryOfOrigin').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 11
    },
    {
      field: 'weight',
      headerName: i18n.t('UnitsOfMeasurement.Weight').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 12
    },
    {
      field: 'supplier.displayName',
      headerName: i18n.t('Inventory.Product.Supplier').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 13
    },
    {
      field: 'productCode',
      headerName: i18n.t('Inventory.Product.UPCCode').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 14,
      editable: true,
      onCellValueChanged: handleCellChange
    },
    {
      field: 'totalInventory',
      headerName: i18n.t('Inventory.TotalInventory').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 15
    },
    {
      field: 'totalBarcodedInventory',
      headerName: i18n.t('Inventory.BarcodedInventory').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 16
    },
    {
      field: 'productAccounting.expenseCode.code',
      headerName: i18n.t('Financials.Accounting.ExpenseCode').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 17
    },
    {
      field: 'productAccounting.revenueCode.code',
      headerName: i18n.t('Financials.Accounting.RevenueCode').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 18
    },
    {
      field: 'productAccounting.numberOfDays',
      headerName: i18n.t('Financials.Accounting.DepreciationDays').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 19
    },
    {
      field: 'productAccounting.depreciationType.name',
      headerName: i18n.t('Financials.Accounting.DepreciationMethod').toString(),
      sortable: true,
      resizable: true,
      headerClass: productTableHeaderClass,
      sortIndex: 20
    }
  ]);

  return {
    activeProduct,
    activeRelationsTab,
    addComponentToActiveProduct,
    addProductRelationToDeleteQueue,
    addReminderToActiveProduct,
    allProductsCurrentPage,
    allProductsLastPage,
    createProduct,
    deleteProductRelationsDeleteQueue,
    filterProducts,
    getProductTypes,
    getProducts,
    getUploadUrlForActiveProduct,
    getDocumentUploadUrlForActiveProduct,
    isNewProduct,
    productRelationsDeleteQueue,
    productSearchCurrentPage,
    productSearchLastPage,
    productTableGridOptions,
    productTableSearchInput,
    productTypes,
    products,
    resetAllProducts,
    resetInventorySearchPageCount,
    resetProductRelationsDeleteQueue,
    resetProductSearchPageCount,
    searchInventory,
    searchProducts,
    setActiveProduct,
    setNewActiveProduct,
    totalProductCount,
    updateProduct,
    updateUnserializedQuantity,
    searchInventoryCurrentPage,
    searchInventoryNumPages,
    getDocumentTypes,
    documentTypes,
    updateProductDocument
  };
});

export default useProductStore;

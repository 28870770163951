
import { defineComponent, computed } from 'vue';
import { useEventStore } from '@/store';
import { formatDate } from '@/localization/i18n';

export default defineComponent({
  name: 'EventHeader',
  setup() {
    const eventStore = useEventStore();
    const { activeEvent } = eventStore;

    const beginDate = computed(() => {
      return activeEvent?.dateBegin ? formatDate(activeEvent.dateBegin, 'iii LLL d') : '';
    });

    const loadInDate = computed(() => {
      return activeEvent?.dateLoadIn ? formatDate(activeEvent.dateLoadIn, 'LLLL d') : '';
    });

    const loadOutDate = computed(() => {
      return activeEvent?.dateLoadOut ? formatDate(activeEvent.dateLoadOut, 'LLLL d') : '';
    });

    const endDate = computed(() => {
      return activeEvent?.dateEnd ? formatDate(activeEvent.dateEnd, 'iii LLL d, y') : '';
    });

    return {
      activeEvent,
      beginDate,
      endDate,
      loadInDate,
      loadOutDate
    };
  }
});

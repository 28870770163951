import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ICountry } from '@/types/core/geographical';
import { axiosInventory } from '@/services/httpService';

const useCountriesStore = defineStore('CountriesStore', () => {
  const countries = ref<ICountry[]>([]);

  const getCountries = async () => {
    if (!countries.value || !countries.value.length) {
      const { data }: { data: ICountry[] } = await axiosInventory.get('/countries');
      countries.value = data;
    }
    return countries.value;
  };

  const searchCountries = async (countriesFilter?: string) => {
    const { data }: { data: ICountry[] } = await axiosInventory.post('/countries', {
      ...(countriesFilter && countriesFilter.length && { query: countriesFilter })
    });
    return data;
  };

  return {
    countries,
    getCountries,
    searchCountries
  };
});

export default useCountriesStore;

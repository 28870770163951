
import VMultiselect from '@vueform/multiselect/dist/multiselect.vue2';

// import type MultiselectType from '@vueform/multiselect/src/Multiselect';
import { defineComponent, PropType, ref } from 'vue';
import { IAnySimpleObject } from '@/types/misc/unknowns';

export default defineComponent({
  name: 'SearchSelect',
  components: {
    VMultiselect
  },
  emits: ['update:modelValue', 'update:searchValue'],
  props: {
    modelValue: {
      type: [Object, Array] as PropType<Record<any, IAnySimpleObject> | Array<any>>
    },
    options: {
      type: [Array, Function] as PropType<
        IAnySimpleObject[] | Promise<IAnySimpleObject[]> | ((query: string) => Promise<IAnySimpleObject[]>)
      >,
      required: true
    },
    displayProp: {
      type: String,
      required: true
    },
    valueProp: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: () => true
    },
    searchable: {
      type: Boolean,
      default: () => true
    },
    minChars: {
      type: Number,
      default: () => 1
    },
    noOptionsText: {
      type: String,
      default: () => 'No matching results'
    },
    noResultsText: {
      type: String,
      default: () => 'No matching results'
    },
    clearOnSearch: {
      type: Boolean,
      default: () => false
    },
    clearOnSelect: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    canClear: {
      type: Boolean,
      default: () => true
    },
    resolveOnLoad: {
      type: Boolean,
      default: () => false
    },
    allowAddnew: {
      type: Boolean,
      default: () => true
    },
    openDirection: {
      type: String as PropType<'top' | 'bottom'>,
      default: () => 'bottom' // or 'top'
    },
    closeOnSelect: {
      type: Boolean,
      default: () => true
    },
    internalSearch: {
      type: Boolean,
      default: () => true
    },
    showNoResults: {
      type: Boolean,
      default: () => true
    },
    hideSelected: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    mode: {
      type: String,
      default: () => 'single'
    },
    id: {
      type: String,
      default: () => 'multiselect'
    },
    createOption: {
      type: Boolean,
      default: () => false
    },
    dataTestIdString: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const multiselectRef = ref<InstanceType<typeof VMultiselect>>();
    const asyncMode = !!props.options && !Array.isArray(props.options);

    const change = (newSelectionValue: object) => {
      emit('update:modelValue', newSelectionValue);
    };

    const handleSearchChange = (query: string) => {
      emit('update:searchValue', query);
    };

    const componentModelObject = ref<Record<any, IAnySimpleObject> | undefined | Array<any>>(props.modelValue);

    const clear = () => {
      if (componentModelObject.value) {
        multiselectRef.value.deselect(componentModelObject.value);
      }
    };

    const refreshOptions = async () => multiselectRef.value.refreshOptions();
    const open = () => multiselectRef.value.open();
    const close = () => multiselectRef.value.close();
    const select = (obj: IAnySimpleObject) => multiselectRef.value.select(obj);
    const focus = () => multiselectRef.value.focus();

    return {
      asyncMode,
      change,
      clear,
      open,
      close,
      select,
      focus,
      componentModelObject,
      multiselectRef,
      handleSearchChange,
      refreshOptions
    };
  },
  watch: {
    modelValue(newModelValue) {
      this.componentModelObject = newModelValue;
    }
  }
});


import { defineComponent, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '@/store';
import { LIconMarketplace, LIconAirplane } from '../Icons/NavIcons';

export default defineComponent({
  name: 'TasksNavMenu',
  components: {
    LIconMarketplace,
    LIconAirplane
  },
  props: {
    travelFeature: Boolean,
    accountHasOnboardingAccount: Boolean
  },
  setup(props) {
    const navigationStore = useNavigationStore();
    const { navSettings } = storeToRefs(navigationStore);

    const applicationsLink = navSettings.value.tasks.applications; // '/applications/';
    const travelSummaryLink = computed(() => (props.travelFeature ? navSettings.value.tasks.travel_summary : ''));
    const openPositionsLink = navSettings.value.tasks.open_positions; // `/open_positions/${cookieToParams('LASSO_param_filter_open_positions')}`;
    const signatureLink = computed(() =>
      props.accountHasOnboardingAccount
        ? navSettings.value.tasks.lasso_signature // `/signature/company/agreements/primary/${cookieToParams('LASSO_param_filter_agreements_list')}`
        : ''
    );

    return {
      applicationsLink,
      travelSummaryLink,
      openPositionsLink,
      signatureLink
    };
  }
});

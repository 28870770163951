import { computed, Ref, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n-composable';
import { addHours, endOfDay } from 'date-fns';
// eslint-disable-next-line import/no-cycle
import { useEventStore } from '@/store';
import {
  IProjectTask,
  IProjectTaskComment,
  IProjectTaskList,
  IProjectTaskStatus,
  IProjectTaskTag,
  IProjectTime,
  ProjectTask,
  ProjectTaskComment,
  ProjectTaskList,
  ProjectTaskStatus,
  ProjectTime,
  IProjectTaskHistoryResponse,
  IProjectTaskHistoryDisplayEntry,
  ITaskChange,
  IProjectTaskHistoryDisplayChange,
  NormalizedDiffTaskKeys,
  NormalizedDiffTask,
  IProjectTaskHistoryDisplayUpdate,
  ITaskCreated,
  ITaskPaginatedCache
} from '@/types/events/project';
import { axiosInventory } from '../services/httpService';
import { PaginatedResponse } from '@/types/api/common';
import { IUser, User } from '@/types/core';
import { displayCurrency, formatDate } from '@/localization/i18n';
import { proseMirrorToHTML } from '@/components/Common/LassoTiptapEditor';

const useProjectStore = defineStore('ProjectStore', () => {
  const { t } = useI18n();

  // nested store
  const eventStore = useEventStore();
  const { activeEvent } = storeToRefs(eventStore);

  const usersInProject = ref<User[]>([]);
  const selectedUserIdsForProjectTasks = ref<number[]>([]);

  const taskStatusTypes = ref<ProjectTaskStatus[]>([]);
  const taskListsInEvent = ref<ProjectTaskList[]>([]);
  const allTasksInEvent = ref<ProjectTask[]>([]);
  const allTaskListsActive = computed(() => taskListsInEvent.value.every((tl) => tl.active));
  const activeTaskLists = computed(() => taskListsInEvent.value.filter((tl) => tl.active));
  const activeTask = ref<ProjectTask | null>(null);
  const activeTaskComments = ref<ProjectTaskComment[]>([]);
  const activeTaskHistory = ref<IProjectTaskHistoryDisplayEntry[]>([]);

  // task cache
  const topLevelTasks = ref<{ [taskListId: number]: ITaskPaginatedCache }>({});
  const taskChildren = ref<{ [parentTaskId: number]: ITaskPaginatedCache }>({});

  const gridInlineTitleAddValue = ref('');
  const triggerScheduleRefresh = ref(false);

  const taskPageSize = ref(100);

  const getProjectUsers = async () => {
    const { data }: { data: IUser[] } = await axiosInventory.get(`/events/${activeEvent.value?.code}/project/users`);

    usersInProject.value = data.map((user) => new User(user));
  };

  const getTaskLists = async (setAllActive = false) => {
    const { data }: { data: IProjectTaskList[] } = await axiosInventory.get(
      `/events/${activeEvent.value?.code}/project/task_lists`
    );

    const newTaskLists = data.map((tl) => new ProjectTaskList(tl));

    if (setAllActive) {
      newTaskLists.forEach((tl) => {
        tl.active = true;
      });
    } else {
      // preserve which lists are active
      taskListsInEvent.value.forEach((tl) => {
        const newTaskList = newTaskLists.find((ntl) => ntl.id === tl.id);
        if (newTaskList) newTaskList.active = tl.active;
      });
    }

    taskListsInEvent.value = newTaskLists;
  };

  const createTaskList = async (taskList: ProjectTaskList, setActive = false) => {
    const { data }: { data: IProjectTaskList } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists`,
      taskList
    );

    await getTaskLists();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (setActive) taskListsInEvent.value.find((tl) => tl.id === data.id)!.active = true;
  };

  const deleteTaskList = async (taskListId: number) => {
    await axiosInventory.delete(`/events/${activeEvent.value?.code}/project/task_lists/${taskListId}`);

    await getTaskLists();
  };

  const toggleShowAllLists = (value: boolean) => {
    taskListsInEvent.value.forEach((tl) => {
      tl.active = value;
    });
  };

  const updateLastSaved = (taskListId: number) => {
    const taskList = taskListsInEvent.value.find((tl) => tl.id === taskListId);
    if (taskList) taskList.lastSaved = new Date();
  };

  const updateTaskList = async (taskList: ProjectTaskList) => {
    await axiosInventory.put(`/events/${activeEvent.value?.code}/project/task_lists/${taskList.id}`, taskList);
    updateLastSaved(taskList.id);

    await getTaskLists();
  };

  const saveProjectTime = async (data: Partial<IProjectTime>) => {
    await axiosInventory.post(`/events/${activeEvent.value?.code}/project/time`, {
      timeSpentInSeconds: data.timeSpentInSeconds,
      taskId: data.taskId || null
    });
  };

  const getProjectTimeHistory = async (): Promise<PaginatedResponse<ProjectTime>> => {
    const pathParts = window.location.pathname.split('/');
    const eventCode = pathParts[2];

    const resp = await axiosInventory.get(`/events/${eventCode}/project/time_history`);
    const { data, status }: { data: PaginatedResponse<IProjectTime>; status: number } = resp;

    const retData = {
      ...data,
      rows: data.rows.map((h: IProjectTime) => new ProjectTime(h)),
      status
    };
    return retData;
  };

  const getTaskStatusTypes = async () => {
    const { data }: { data: IProjectTaskStatus[] } = await axiosInventory.get('/events/task_status_types');

    taskStatusTypes.value = data.map((statusType) => new ProjectTaskStatus(statusType));
  };

  const fetchTasksInTaskList = async (
    listId: number,
    page = 0,
    size = taskPageSize.value
  ): Promise<PaginatedResponse<ProjectTask>> => {
    const { data }: { data: PaginatedResponse<IProjectTask> } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists/${listId}/tasks/filter?page=${page}&size=${size}`,
      {
        ...(selectedUserIdsForProjectTasks.value.length && { assignedUserIds: selectedUserIdsForProjectTasks.value })
      }
    );

    return {
      ...data,
      rows: data.rows.map((task) => new ProjectTask(task))
    };
  };

  const fetchTaskChildren = async (
    listId: number,
    parentTaskId: number,
    page = 0,
    size = taskPageSize.value
  ): Promise<PaginatedResponse<ProjectTask>> => {
    const { data }: { data: PaginatedResponse<IProjectTask> } = await axiosInventory.get(
      `/events/${activeEvent.value?.code}/project/task_lists/${listId}/tasks/${parentTaskId}/children?page=${page}&size=${size}`
    );

    return {
      ...data,
      rows: data.rows.map((task) => new ProjectTask(task))
    };
  };

  /** internal helper function */
  const getTasksWithCache = async (
    cache: Ref<{ [id: number]: ITaskPaginatedCache }>,
    cacheId: number,
    page: number,
    fetch: () => Promise<PaginatedResponse<ProjectTask>>
  ): Promise<PaginatedResponse<ProjectTask>> => {
    if (!cache.value[cacheId]) cache.value[cacheId] = { total: 0, totalPages: 0, pages: [] };

    if (!cache.value[cacheId].pages[page]) {
      const response = await fetch();

      cache.value[cacheId].total = response.total;
      cache.value[cacheId].totalPages = response.totalPages;
      cache.value[cacheId].pages[page] = response.rows;
    }

    return {
      total: cache.value[cacheId].total ?? 0,
      totalPages: cache.value[cacheId].totalPages ?? 0,
      currentPage: page,
      rows: cache.value[cacheId].pages[page]
    };
  };

  /** get top level tasks with pagination via cache. meant for use with LassoGrid */
  const getTopLevelTasksWithCache = async (listId: number, page = 0): Promise<PaginatedResponse<ProjectTask>> => {
    return getTasksWithCache(topLevelTasks, listId, page, () => fetchTasksInTaskList(listId, page));
  };

  /** get children tasks with pagination via cache. meant for use with LassoGrid */
  const getTaskChildrenWithCache = async (
    listId: number,
    parentTaskId: number,
    page = 0
  ): Promise<PaginatedResponse<ProjectTask>> => {
    return getTasksWithCache(taskChildren, parentTaskId, page, () => fetchTaskChildren(listId, parentTaskId, page));
  };

  const clearTaskCache = () => {
    topLevelTasks.value = {};
    taskChildren.value = {};
  };

  // mapped values for syncfusion components
  const ganttMappedTasks = ref<ProjectTask[]>([]);
  const ganttMappedUsers = ref<(User | { id: number; fullName: string | undefined })[]>([]);
  const calendarMappedTasks = ref<ProjectTask[]>([]);

  const setGanttMappedTasks = () => {
    const calcDependency = (task: ProjectTask) => {
      // translate our dependency language to syncfusions
      return task.dependencies
        .filter((dt) => dt.type === 'blocked-by' && dt.task)
        .map((dt) => `${dt.taskId}FS`)
        .join();
    };

    const mapTasksRescursive = (task: ProjectTask) => {
      // recurse when there are children
      const children: ProjectTask[] = taskChildren.value[task.id]
        ? taskChildren.value[task.id].pages.flat().map((c) => mapTasksRescursive(c))
        : [];
      const dependency = calcDependency(task);
      task.assignedUsers.forEach((assignedUser) => {
        // add unique users for the resource collection required by the gantt chart
        // apply the users' full name to the object, because SF strips the fullName getter
        if (ganttMappedUsers.value.findIndex((u) => u.id === assignedUser.id) === -1) {
          ganttMappedUsers.value.push({ ...assignedUser, fullName: assignedUser.fullName });
        }
      });
      task.children = children;
      task.dependency = dependency;
      task.dateStart = task.dateStart && task.isMilestone ? addHours(task.dateStart, 12) : task.dateStart;
      return task;
    };

    ganttMappedTasks.value = Object.entries(topLevelTasks.value)
      .map((entry) => entry[1].pages.flat())
      .flat()
      .map(mapTasksRescursive);
  };

  const setScheduleMappedTasks = () => {
    const processTasksRescursive = (projectTasks: ProjectTask[]) => {
      let allTasks: ProjectTask[] = [];
      projectTasks.forEach((task) => {
        if (!task.dateEnd && !task.dateStart) {
          // if there are no dates set on the task, do not include it in the calendar
        } else {
          // if only one date is set, use it as both start and end
          if (!task.dateEnd) task.dateEnd = task.dateStart;
          if (!task.dateStart) task.dateStart = task.dateEnd;

          // adjust end date to be end of day.
          task.dateEnd = task.dateEnd ? endOfDay(task.dateEnd) : task.dateEnd;
          allTasks = allTasks.concat([task]);
        }
      });
      return allTasks;
    };

    const allTasks = [
      ...Object.entries(topLevelTasks.value)
        .map((entry) => entry[1].pages.flat())
        .flat(),
      ...Object.entries(taskChildren.value)
        .map((entry) => entry[1].pages.flat())
        .flat()
    ];

    calendarMappedTasks.value = processTasksRescursive(allTasks);
  };

  /** recursively fetch all active-task-list tasks and children into cache. for use with gantt and scheduler. */
  const fetchAllTaskListTasks = async (purge = false) => {
    if (purge) clearTaskCache();

    // get all top level changes
    await Promise.all(
      activeTaskLists.value.reduce((promiseArray, taskList) => {
        // skip those that have been loaded
        if (topLevelTasks.value[taskList.id] !== undefined) return promiseArray;

        const promise = async () => {
          // get first page to get page count
          await getTopLevelTasksWithCache(taskList.id, 0);

          // get remaining pages
          if (topLevelTasks.value[taskList.id].totalPages !== 1)
            await Promise.all(
              Array.from(Array(topLevelTasks.value[taskList.id].totalPages).keys()).map((pageNum) =>
                getTopLevelTasksWithCache(taskList.id, pageNum)
              )
            );
        };

        return [...promiseArray, promise()];
      }, [] as Promise<void>[])
    );

    const getNeededChildren = async () => {
      const allTasksWithChildren = [
        ...Object.entries(topLevelTasks.value)
          .map((entry) => entry[1].pages.flat())
          .flat(),
        ...Object.entries(taskChildren.value)
          .map((entry) => entry[1].pages.flat())
          .flat()
      ].filter((task) => task.hasChildren);

      const promises = allTasksWithChildren.reduce((promiseArray, task) => {
        // skip those that have been loaded
        if (taskChildren.value[task.id] !== undefined) return promiseArray;

        const promise = async () => {
          // get first page to get page count
          await getTaskChildrenWithCache(task.taskListId, task.id, 0);

          // get remaining pages
          if (taskChildren.value[task.id].totalPages !== 1)
            await Promise.all(
              Array.from(Array(taskChildren.value[task.id].totalPages).keys()).map((pageNum) =>
                getTaskChildrenWithCache(task.taskListId, task.id, pageNum)
              )
            );
        };

        return [...promiseArray, promise()];
      }, [] as Promise<void>[]);

      if (promises.length > 0) {
        await Promise.all(promises);

        // one of the fetched children can now have children to fetch, get those too
        await getNeededChildren();
      }
    };

    await getNeededChildren();

    setGanttMappedTasks();
    setScheduleMappedTasks();
  };

  const createNewTags = async (task: ProjectTask) => {
    const tagsToCreate = task.tags.filter((tag) => typeof tag.id !== 'number');

    await Promise.all(
      tagsToCreate.map(async (tag) => {
        const { data }: { data: IProjectTaskTag } = await axiosInventory.post('/projects/tags', { name: tag.name });
        // eslint-disable-next-line no-param-reassign
        tag.id = data.id;
      })
    );
  };

  const createTask = async (task: ProjectTask) => {
    await createNewTags(task);

    const { data }: { data: IProjectTask } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists/${task.taskListId}/tasks`,
      task
    );

    updateLastSaved(task.taskListId);
    return new ProjectTask(data);
  };

  const updateTask = async (task: ProjectTask) => {
    await createNewTags(task);

    const { data }: { data: IProjectTask } = await axiosInventory.put(
      `/events/${activeEvent.value?.code}/project/task_lists/${task.taskListId}/tasks/${task.id}`,
      task
    );

    updateLastSaved(task.taskListId);
    return new ProjectTask(data);
  };

  const deleteTask = async (task: ProjectTask, listId: number) => {
    await axiosInventory.delete(`/events/${activeEvent.value?.code}/project/task_lists/${listId}/tasks/${task.id}`);
    updateLastSaved(task.taskListId);
  };

  const searchProjectTasks = async (searchStr: string, taskListId: number | null) => {
    const { data }: { data: PaginatedResponse<IProjectTask> } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/search_tasks`,
      { searchStr, taskListId }
    );

    return {
      ...data,
      rows: data.rows.map((task) => new ProjectTask(task))
    };
  };

  const searchTags = async (searchStr: string) => {
    const { data }: { data: PaginatedResponse<IProjectTaskTag> } = await axiosInventory.post(`/projects/tags/search`, {
      searchStr
    });

    return data;
  };

  const indentTask = async (taskListId: number, taskId: number) => {
    await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists/${taskListId}/tasks/${taskId}/indent`
    );

    updateLastSaved(taskListId);
  };

  const reorderTask = async (
    taskListId: number,
    taskId: number,
    newParentTaskId: number | null,
    taskIdToPlaceUnder: number | null,
    taskIdToPlaceAbove?: number | null
  ) => {
    await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists/${taskListId}/tasks/${taskId}/reorder`,
      {
        parentTaskId: newParentTaskId,
        taskIdToPlaceUnder,
        taskIdToPlaceAbove
      }
    );

    updateLastSaved(taskListId);
  };

  const getTemplates = async () => {
    const { data }: { data: IProjectTaskList[] } = await axiosInventory.get('/projects/templates');

    return data.map((template) => new ProjectTaskList(template));
  };

  const createTemplate = async (
    sourceTaskListId: number,
    options: { action: 'new'; templateTitle: string } | { action: 'overwrite'; templateIdToOverwrite: number }
  ) => {
    await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists/${sourceTaskListId}/create_template`,
      options
    );
  };

  const loadTemplate = async (templateId: number, mapDates: boolean, assignUsers: boolean) => {
    const { data }: { data: { newListId: number } } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/load_template`,
      {
        templateId,
        mapDates,
        assignUsers
      }
    );
    return data.newListId;
  };

  const getTaskComments = async () => {
    if (activeTask.value?.id === 0) return;

    activeTaskComments.value = [];

    const { data }: { data: IProjectTaskComment[] } = await axiosInventory.get(
      `/events/${activeEvent.value?.code}/project/task_lists/${activeTask.value?.taskListId}/tasks/${activeTask.value?.id}/comments`
    );

    activeTaskComments.value = data.map((comment) => new ProjectTaskComment(comment));
  };

  const createTaskComment = async (comment: ProjectTaskComment) => {
    activeTaskComments.value = [comment, ...activeTaskComments.value];

    const { data }: { data: IProjectTaskComment } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/project/task_lists/${activeTask.value?.taskListId}/tasks/${activeTask.value?.id}/comments`,
      comment
    );

    activeTaskComments.value = [new ProjectTaskComment(data), ...activeTaskComments.value.slice(1)];
  };

  const updateTaskComment = async (comment: ProjectTaskComment) => {
    const { data }: { data: IProjectTaskComment } = await axiosInventory.put(
      `/events/${activeEvent.value?.code}/project/task_lists/${activeTask.value?.taskListId}/tasks/${activeTask.value?.id}/comments/${comment.id}`,
      comment
    );

    activeTaskComments.value[activeTaskComments.value.indexOf(comment)] = new ProjectTaskComment(data);
  };

  const deleteTaskComment = async (comment: ProjectTaskComment) => {
    activeTaskComments.value = activeTaskComments.value.filter((activeComment) => activeComment.id !== comment.id);

    await axiosInventory.delete(
      `/events/${activeEvent.value?.code}/project/task_lists/${activeTask.value?.taskListId}/tasks/${activeTask.value?.id}/comments/${comment.id}`
    );
  };

  const getTaskHistory = async () => {
    if (activeTask.value?.id === 0) return;

    const { data }: { data: IProjectTaskHistoryResponse } = await axiosInventory.get(
      `/events/${activeEvent.value?.code}/project/task_lists/${activeTask.value?.taskListId}/tasks/${activeTask.value?.id}/history`
    );

    const getValueDisplay = <T extends NormalizedDiffTaskKeys>(key: T, value: NormalizedDiffTask[T]): string => {
      const none = t('Event.Project.Tasks.History.None');
      const dateFormatter = (v: string | null) => (v ? formatDate(new Date(v), 'P') : none);
      const userNames = (v: { id: number }[]) =>
        v.map((userId) => new User(data.related.users.find((user) => user.id === userId.id)).fullName).join(', ');

      return (
        (
          {
            title: (v) => v,
            dateStart: dateFormatter,
            dateEnd: dateFormatter,
            duration: (v) => v ?? none,
            isMilestone: (v) => (v ? t('Event.Project.Tasks.History.True') : t('Event.Project.Tasks.History.False')),
            // v-html needs to be used in the rendering element
            description: (v) => (v ? proseMirrorToHTML(v) : none),
            priority: (v) => t(`Event.Project.Tasks.Priority.${v}`),
            timeEstimate: (v) => v ?? none,
            billableHours: (v) => v ?? none,
            billRate: (v) => (v ? displayCurrency(v, undefined, eventStore.activeEvent?.currency) : none),
            parentTaskId: (v) => data.related.tasks.find((task) => task.id === v)?.title ?? '',
            taskStatus: (v) =>
              t(
                `Event.Project.Tasks.Statuses.${data.related.statuses.find((status) => status.id === v.id)?.code ?? ''}`
              ),
            dependencies: (v) =>
              v
                .map(
                  (dependency) =>
                    `${t(`Event.Project.Tasks.DependencyTypes.${dependency.type}`)} ${
                      data.related.tasks.find((task) => task.id === dependency.taskId)?.title
                    }`
                )
                .join(', '),
            assignedUsers: userNames,
            watchingUsers: userNames,
            tags: (v) => v.map((tagId) => data.related.tags.find((tag) => tag.id === tagId.id)?.name).join(', ')
          } as { [Key in NormalizedDiffTaskKeys]: (value: NormalizedDiffTask[Key]) => string }
        )
          // @ts-expect-error: this causes an error on typescript 4.5.5, but works on 4.9.5 -- remove after update!
          [key](value)
      );
    };

    activeTaskHistory.value = [
      ...(data.history
        .filter((history): history is ITaskChange => history.type === 'change')
        .map((history) => ({
          type: 'change',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          user: new User(data.related.users.find((user) => user.id === history.userId)!),
          date: new Date(history.date),
          changes: [
            ...(Object.entries(history.inserted).map(([key, insert]) => ({
              type: 'array-change',
              fieldName: t(`Event.Project.Tasks.Fields.${key}`),
              display: t('Event.Project.Tasks.History.ArrayInsert', {
                msg: getValueDisplay(key as NormalizedDiffTaskKeys, insert)
              })
            })) as IProjectTaskHistoryDisplayChange[]),
            ...(Object.entries(history.removed).map(([key, removal]) => ({
              type: 'array-change',
              fieldName: t(`Event.Project.Tasks.Fields.${key}`),
              display: t('Event.Project.Tasks.History.ArrayRemoved', {
                msg: getValueDisplay(key as NormalizedDiffTaskKeys, removal)
              })
            })) as IProjectTaskHistoryDisplayChange[]),
            ...(Object.entries(history.updated).map(([key, update]) => ({
              type: 'update',
              key,
              fieldName: t(`Event.Project.Tasks.Fields.${key}`),
              before: getValueDisplay(key as NormalizedDiffTaskKeys, update[0]),
              after: getValueDisplay(key as NormalizedDiffTaskKeys, update[1])
            })) as IProjectTaskHistoryDisplayUpdate[])
          ]
        })) as Extract<IProjectTaskHistoryDisplayEntry, { type: 'change' }>[]),
      ...(data.history
        .filter((history): history is ITaskCreated => history.type === 'created')
        .map((history) => ({
          type: 'created',
          user: activeTask.value?.createdByUser,
          date: new Date(history.date)
        })) as Extract<IProjectTaskHistoryDisplayEntry, { type: 'created' }>[])
    ];
  };

  return {
    usersInProject,
    getProjectUsers,
    getTaskLists,
    taskListsInEvent,
    createTaskList,
    updateTaskList,
    taskStatusTypes,
    getTaskStatusTypes,
    activeTask,
    createTask,
    updateTask,
    deleteTask,
    searchProjectTasks,
    searchTags,
    allTasksInEvent,
    allTaskListsActive,
    toggleShowAllLists,
    activeTaskLists,
    deleteTaskList,
    indentTask,
    reorderTask,
    getTemplates,
    createTemplate,
    loadTemplate,
    gridInlineTitleAddValue,
    saveProjectTime,
    getProjectTimeHistory,
    activeTaskComments,
    getTaskComments,
    createTaskComment,
    updateTaskComment,
    deleteTaskComment,
    selectedUserIdsForProjectTasks,
    triggerScheduleRefresh,
    getTaskHistory,
    activeTaskHistory,
    taskPageSize,
    getTopLevelTasksWithCache,
    getTaskChildrenWithCache,
    clearTaskCache,
    fetchAllTaskListTasks,
    ganttMappedTasks,
    ganttMappedUsers,
    calendarMappedTasks
  };
});

export default useProjectStore;

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { axiosInventory } from '../services/httpService';
import { PaginatedResponse } from '@/types/api/common';
import { IUser, User } from '@/types/core';

const useUserStore = defineStore('UserStore', () => {
  const self = ref(new User());

  const searchUsers = async (searchStr: string): Promise<PaginatedResponse<User>> => {
    const { data }: { data: PaginatedResponse<IUser> } = await axiosInventory.post(`/users/search`, { searchStr });

    return {
      ...data,
      rows: data.rows.map((t) => new User(t))
    };
  };

  const loadSelf = async () => {
    const { data }: { data: IUser } = await axiosInventory.get('/users/self');

    self.value = new User(data);
  };

  return {
    searchUsers,
    self,
    loadSelf
  };
});

export default useUserStore;

import { Editor } from '@tiptap/core';
import extensions from '@/components/Common/LassoTiptapEditor/extensions';

export default function proseMirrorToHTML(jsonString: string) {
  const editor = new Editor({
    editable: false,
    extensions: extensions(),
    content: JSON.parse(jsonString)
  });

  const html = editor.getHTML();
  editor.destroy();

  return html;
}


import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-composition-wrapper';
import { storeToRefs } from 'pinia';
import { useEventStore, usePermissionsStore, useNavigationStore } from '@/store';
import SpokeNavItem from '@/components/Nav/SpokeNavItem.vue';
import {
  EVENT_SPOKE_COMMUNICATE,
  EVENT_SPOKE_CREWING,
  EVENT_SPOKE_LOGISTICS,
  EVENT_SPOKE_PROPOSAL,
  EVENT_SPOKE_RATES,
  EVENT_SPOKE_ROSTER,
  EVENT_SPOKE_SCHEDULE,
  EVENT_SPOKE_SUMMARY,
  EVENT_SPOKE_TRAVEL,
  EVENT_SPOKE_INFO,
  EVENT_SPOKE_PROJECTS,
  EVENT_SPOKE_GEAR
} from '@/constants';

export default defineComponent({
  name: 'SpokeNav',
  components: {
    SpokeNavItem
  },
  setup() {
    const route = useRoute();
    const eventStore = useEventStore();
    const navigationStore = useNavigationStore();
    const permissionsStore = usePermissionsStore();
    const labelIsOpen = ref<boolean>(false);
    const spokeNavIsOpen = ref<boolean>(false);

    const { workforceServer } = storeToRefs(navigationStore);
    const { activeEvent } = storeToRefs(eventStore);
    const { features } = storeToRefs(permissionsStore);
    const { financeSummaryAllowed } = permissionsStore;
    const eventCode = activeEvent.value?.code;

    // once spoke links are implemented, that data could be fed into here
    const items: Array<{
      name: string;
      iconName: string;
      titleKey: string;
      dataTestId: string;
      pathName: string | null;
      disabled: boolean;
    }> = [
      {
        name: EVENT_SPOKE_INFO,
        iconName: 'b-icon-speedometer2',
        titleKey: 'EventInfo.SpokeNavTitle',
        dataTestId: 'event-spokes-dashboard',
        pathName: `/workforce/events/${eventCode}`,
        disabled: false
      },
      {
        name: EVENT_SPOKE_PROJECTS,
        iconName: 'LIconProjectManagement',
        titleKey: 'Nav.Project',
        dataTestId: 'event-spokes-projects',
        pathName: `/events/${eventCode}/projects/tasks`,
        disabled: !features.value.projectManagementFeature
      },
      {
        name: EVENT_SPOKE_GEAR,
        iconName: 'LIconGearList',
        titleKey: 'Nav.Gear',
        dataTestId: 'event-spokes-gear',
        pathName: `/events/${eventCode}/gear`,
        disabled: !features.value.inventoryFeature
      },
      {
        name: EVENT_SPOKE_LOGISTICS,
        iconName: 'LIconTransport',
        titleKey: 'Transport.Title',
        dataTestId: 'event-spokes-logistics',
        pathName: `/events/${eventCode}/transport`,
        disabled: !features.value.logisticsFeature
      },
      {
        name: EVENT_SPOKE_SCHEDULE,
        iconName: 'b-icon-calendar-date',
        titleKey: 'Nav.Schedule',
        dataTestId: 'event-spokes-schedule',
        pathName: `/workforce/events/${eventCode}/schedule/${
          features.value.eventsModifyPermission || features.value.eventsScheduleModifyPermission ? 'edit/' : ''
        }`,
        disabled: false
      },
      {
        name: EVENT_SPOKE_RATES,
        iconName: 'b-icon-cash-coin',
        titleKey: 'Nav.RatesAndBilling',
        dataTestId: 'event-spokes-rates-and-billing',
        pathName: `/workforce/events/${eventCode}/rates_and_billing`,
        disabled: !(
          features.value.eventsModifyPermission &&
          (features.value.crewRatesViewPermission || financeSummaryAllowed)
        ) as boolean
      },
      {
        name: EVENT_SPOKE_CREWING,
        iconName: 'b-icon-person-plus-fill',
        titleKey: 'Crewing.Title',
        dataTestId: 'event-spokes-crewing',
        pathName: `/workforce/next/events/${eventCode}/crewing/`,
        disabled: false
      },
      {
        name: EVENT_SPOKE_TRAVEL,
        iconName: 'LIconAirplane',
        titleKey: 'Travel.Title',
        dataTestId: 'event-spokes-travel',
        pathName: `/workforce/travel/events/${eventCode}/itinerary/`,
        disabled: !features.value.travelFeature
      },
      {
        name: EVENT_SPOKE_PROPOSAL,
        iconName: 'LIconProposals',
        titleKey: 'Proposal.Title',
        dataTestId: 'event-spokes-proposal',
        pathName: `/events/${eventCode}/proposal`,
        disabled: !features.value.proposalsFeature
      },
      {
        name: EVENT_SPOKE_ROSTER,
        iconName: 'b-icon-person-lines-fill',
        titleKey: 'Roster.Title',
        dataTestId: 'event-spokes-roster',
        pathName: `/workforce/events/${eventCode}/crew/`,
        disabled: false
      },
      {
        name: EVENT_SPOKE_COMMUNICATE,
        iconName: 'LIconCommunicationsSpoke',
        titleKey: 'Communicate.Title',
        dataTestId: 'event-spokes-communicate',
        pathName: `/workforce/events/${eventCode}/communications/`,
        disabled: false
      },
      // hiding this for now....
      // {
      //   name: '',
      //   iconName: 'b-icon-cash-coin',
      //   titleKey: 'PurchaseOrders.Title',
      //   dataTestId: 'event-spokes-purchase-orders',
      //   pathName: `/events/${eventCode}/purchase-orders`,
      //   disabled: false
      // },
      {
        name: EVENT_SPOKE_SUMMARY,
        iconName: 'b-icon-file-earmark-bar-graph-fill',
        titleKey: 'Summary.Title',
        dataTestId: 'event-spokes-summary',
        pathName: `/workforce/events/${eventCode}/summary`,
        disabled: !financeSummaryAllowed
      }
    ];
    const renderedItems = computed(() => {
      const newItems: Array<{
        name: string;
        iconName: string;
        titleKey: string;
        dataTestId: string;
        active: boolean;
        pathName: string;
      }> = [];

      items.forEach((item) => {
        if (!item.disabled && item.pathName) {
          // break item pathName down:  https://server.io/events/:code/<spoke>/<spoke-child>
          const pathParts = item.pathName ? item.pathName.split('/') : [];
          const checkpath = `/${pathParts.slice(1, 4).join('/')}/*`;
          newItems.push({
            name: item.name,
            iconName: item.iconName,
            titleKey: item.titleKey,
            dataTestId: item.dataTestId,
            pathName: item.pathName,
            // check if route path contains `/events/<code>/<spoke>/*`
            active: new RegExp(checkpath).test(route.value.path)
          });
        }
      });

      return newItems;
    });

    const toggleSpokes = () => {
      labelIsOpen.value = !labelIsOpen.value;
    };
    const toggleMobileSpokes = () => {
      spokeNavIsOpen.value = !spokeNavIsOpen.value;
    };

    return {
      labelIsOpen,
      spokeNavIsOpen,
      toggleSpokes,
      toggleMobileSpokes,
      renderedItems,
      workforceServer
    };
  }
});


import { defineComponent, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '@/store';
import { LIconSupervisors } from '../Icons/NavIcons';

export default defineComponent({
  name: 'CrewNavMenu',
  components: {
    LIconSupervisors
  },
  props: {
    crewApprovePermission: Boolean,
    commsEmailFeature: Boolean
  },
  setup(props) {
    const navigationStore = useNavigationStore();
    const { navSettings } = storeToRefs(navigationStore);

    const crewListLink = navSettings.value.crew.all_crew;
    const crewPendingLink = computed(() => (props.crewApprovePermission ? navSettings.value.crew.pending : ''));
    const crewSupervisorsLink = navSettings.value.crew.supervisors;
    const crewCommunicationsLink = computed(() =>
      props.commsEmailFeature ? navSettings.value.crew.messages : navSettings.value.crew.messages_help
    );

    return {
      crewListLink,
      crewPendingLink,
      crewSupervisorsLink,
      crewCommunicationsLink
    };
  }
});


import { defineComponent, computed, toRefs, ref } from 'vue';
import type { PropType } from 'vue';
import GearIconSizes from '@/types/gearIcon';

export default defineComponent({
  name: 'ProjectManagement',
  props: {
    name: {
      type: String,
      required: false,
      default: () => 'ProjectManagement'
    },
    size: {
      type: String as PropType<keyof typeof GearIconSizes>,
      required: false,
      default: (): keyof typeof GearIconSizes => 'md'
    },
    color: {
      type: String,
      required: false,
      default: () => 'gray-300'
    },
    hoverColor: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  setup(props) {
    // props
    const { size, color, hoverColor } = toRefs(props);

    // template ref
    const svg = ref<InstanceType<typeof SVGSVGElement> | null>(null);

    // computed
    const width = computed(() => {
      return GearIconSizes[size.value];
    });
    const height = computed(() => {
      return GearIconSizes[size.value];
    });
    const fillColor = computed(() => {
      return `var(--${color.value})`;
    });
    const iconHoverColor = computed(() => {
      return `var(--${hoverColor.value})`;
    });

    // functions
    const onMouseOver = () => {
      if (hoverColor?.value) {
        (svg.value as SVGSVGElement).style.fill = iconHoverColor.value;
      }
    };
    const onMouseOut = () => {
      if (hoverColor?.value) {
        (svg.value as SVGSVGElement).style.fill = fillColor.value;
      }
    };

    return {
      svg,
      width,
      height,
      fillColor,
      onMouseOver,
      onMouseOut
    };
  }
});

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Contact, ContactTypeName, IContact, IContactType } from '@/types/contacts';
import { axiosInventory } from '@/services/httpService';
import { IPaginatedResponse } from '@/types/api/common';

interface ISearchContactsResponse extends IPaginatedResponse {
  rows: IContact[];
}

interface IContactTypeMap {
  [contactTypeName: string]: number;
}
const useContactStore = defineStore('ContactStore', () => {
  const searchContactsResults = ref<Contact[]>([]);
  const searchContactQuery = ref<string>('');
  const searchContactNextPage = ref<number>(0);

  const contactTypes = ref<IContactTypeMap>({});

  const loadContactTypes = async () => {
    const { data }: { data: IContactType[] } = await axiosInventory.get('/contacts/types');
    contactTypes.value = data.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.contactTypeName]: curr.id
      };
    }, {} as IContactTypeMap);
  };

  const searchContacts = async (text: string, contactType: ContactTypeName) => {
    if (!contactTypes.value || !Object.keys(contactTypes.value).length) {
      await loadContactTypes();
    }

    if (searchContactQuery.value !== text) {
      searchContactQuery.value = text;
      searchContactNextPage.value = 0;
      searchContactsResults.value = [];
    }

    const url = `/contacts/search${searchContactNextPage.value ? `?page=${searchContactNextPage.value}` : ''}`;
    const { data }: { data: ISearchContactsResponse } = await axiosInventory.post(url, {
      text,
      additionalFilters: {
        contactTypeId: contactTypes.value[contactType]
      }
    });
    const contactsFound: Contact[] = [...new Set(data.rows.map((c) => new Contact(c)))];

    searchContactsResults.value = searchContactsResults.value.concat(contactsFound);
    if (data.currentPage + 1 < data.totalPages && data.totalPages !== 1) {
      searchContactNextPage.value = data.currentPage + 1;
    }

    return searchContactsResults.value;
  };

  const searchSuppliers = async (text?: string) => {
    if (text) {
      const contacts = await searchContacts(text, 'supplier');
      return contacts;
    }
    return [];
  };

  return {
    searchContactsResults,
    searchSuppliers
  };
});

export default useContactStore;

export const DEBOUNCE_MS = 300;
export const PRODUCT_TYPE_CODE = {
  NORMAL: 'N',
  VIRTUAL: 'V',
  CONSUMABLE: 'C',
  BUNDLE: 'B',
  PRICED_BUNDLE: 'PB',
  FREE_PACK_CONTAINER: 'FPC',
  LOCKED_CONTAINER: 'LC',
  UNLOCKED_CONTAINER: 'ULC'
};
export const ITEM_STATUS_TYPE_CODE = {
  IN_SERVICE: 'IS',
  IN_REPAIR: 'IR',
  RETIRED: 'R',
  DISPOSED: 'D'
};

// FIXME: pull into shared package with backend/frontend
// currently copy/pasted into constants file on API side
export const ALLOWED_PRODUCT_RELATIONS: {
  [key in typeof PRODUCT_TYPE_CODE[keyof typeof PRODUCT_TYPE_CODE]]: {
    reminders: readonly string[];
    components: readonly string[];
  };
} = {
  [PRODUCT_TYPE_CODE.NORMAL]: {
    reminders: [
      PRODUCT_TYPE_CODE.NORMAL,
      PRODUCT_TYPE_CODE.VIRTUAL,
      PRODUCT_TYPE_CODE.CONSUMABLE,
      PRODUCT_TYPE_CODE.BUNDLE,
      PRODUCT_TYPE_CODE.PRICED_BUNDLE,
      PRODUCT_TYPE_CODE.FREE_PACK_CONTAINER,
      PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
      PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
    ],
    components: []
  },
  [PRODUCT_TYPE_CODE.CONSUMABLE]: {
    reminders: [
      PRODUCT_TYPE_CODE.NORMAL,
      PRODUCT_TYPE_CODE.VIRTUAL,
      PRODUCT_TYPE_CODE.CONSUMABLE,
      PRODUCT_TYPE_CODE.BUNDLE,
      PRODUCT_TYPE_CODE.PRICED_BUNDLE,
      PRODUCT_TYPE_CODE.FREE_PACK_CONTAINER,
      PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
      PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
    ],
    components: []
  },
  [PRODUCT_TYPE_CODE.VIRTUAL]: {
    reminders: [
      PRODUCT_TYPE_CODE.NORMAL,
      PRODUCT_TYPE_CODE.VIRTUAL,
      PRODUCT_TYPE_CODE.CONSUMABLE,
      PRODUCT_TYPE_CODE.BUNDLE,
      PRODUCT_TYPE_CODE.PRICED_BUNDLE,
      PRODUCT_TYPE_CODE.FREE_PACK_CONTAINER,
      PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
      PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
    ],
    components: [PRODUCT_TYPE_CODE.NORMAL]
  },
  [PRODUCT_TYPE_CODE.BUNDLE]: {
    reminders: [],
    components: [
      PRODUCT_TYPE_CODE.NORMAL,
      PRODUCT_TYPE_CODE.VIRTUAL,
      PRODUCT_TYPE_CODE.CONSUMABLE,
      PRODUCT_TYPE_CODE.FREE_PACK_CONTAINER,
      PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
      PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
    ]
  },
  [PRODUCT_TYPE_CODE.PRICED_BUNDLE]: {
    reminders: [],
    components: [
      PRODUCT_TYPE_CODE.NORMAL,
      PRODUCT_TYPE_CODE.VIRTUAL,
      PRODUCT_TYPE_CODE.CONSUMABLE,
      PRODUCT_TYPE_CODE.FREE_PACK_CONTAINER,
      PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
      PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
    ]
  },
  [PRODUCT_TYPE_CODE.FREE_PACK_CONTAINER]: {
    reminders: [PRODUCT_TYPE_CODE.NORMAL, PRODUCT_TYPE_CODE.VIRTUAL, PRODUCT_TYPE_CODE.CONSUMABLE],
    components: []
  },
  [PRODUCT_TYPE_CODE.LOCKED_CONTAINER]: {
    reminders: [PRODUCT_TYPE_CODE.NORMAL, PRODUCT_TYPE_CODE.VIRTUAL, PRODUCT_TYPE_CODE.CONSUMABLE],
    components: [PRODUCT_TYPE_CODE.NORMAL, PRODUCT_TYPE_CODE.VIRTUAL, PRODUCT_TYPE_CODE.CONSUMABLE]
  },
  [PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER]: {
    reminders: [PRODUCT_TYPE_CODE.NORMAL, PRODUCT_TYPE_CODE.VIRTUAL, PRODUCT_TYPE_CODE.CONSUMABLE],
    components: [PRODUCT_TYPE_CODE.NORMAL, PRODUCT_TYPE_CODE.VIRTUAL, PRODUCT_TYPE_CODE.CONSUMABLE]
  }
} as const;

export const EVENT_LOGO_SOURCE_CUSTOM = 'custom';
export const EVENT_LOGO_SOURCE_ACCOUNT = 'account';
export const EVENT_LOGO_SOURCE_CLIENT = 'client';

export const EVENT_SPOKE_INFO = 'info';
export const EVENT_SPOKE_SCHEDULE = 'schedule';
export const EVENT_SPOKE_PROJECTS = 'projects';
export const EVENT_SPOKE_GEAR = 'gear';
export const EVENT_SPOKE_LOGISTICS = 'logistics';
export const EVENT_SPOKE_RATES = 'rates_and_billing';
export const EVENT_SPOKE_CREWING = 'crewing';
export const EVENT_SPOKE_PROPOSAL = 'proposal';
export const EVENT_SPOKE_ROSTER = 'roster';
export const EVENT_SPOKE_COMMUNICATE = 'communicate';
export const EVENT_SPOKE_TRAVEL = 'travel';
export const EVENT_SPOKE_SUMMARY = 'summary';

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
export const EVENT_LIST_ARCHIVED = 'archived';
export const EVENT_STATUS_UNPUBLISHED = 'unpublished';
export const EVENT_STATUS_PUBLISHED = 'published';
export const EVENT_STATUS_COMPLETED = 'completed';
export const EVENT_STATUS_CANCELED = 'canceled';
export const EVENT_STATUS_TEST = 'test_event';
export const EVENT_STATUS_CHOICES = {
  [EVENT_STATUS_UNPUBLISHED]: toTitleCase(EVENT_STATUS_UNPUBLISHED),
  [EVENT_STATUS_PUBLISHED]: toTitleCase(EVENT_STATUS_PUBLISHED),
  [EVENT_STATUS_COMPLETED]: toTitleCase(EVENT_STATUS_COMPLETED),
  [EVENT_STATUS_CANCELED]: toTitleCase(EVENT_STATUS_CANCELED),
  [EVENT_STATUS_TEST]: 'Test Event'
};

export const ROUTE_NAME_EVENTS_LIST = 'EventsList';

import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import NavConfig from '@/shared/navigation-settings.json';

type Environment = 'dev' | 'test' | 'sandbox' | 'prod-dr' | 'prod';

const {
  VUE_APP_ENVIRONMENT = 'dev',
  VUE_APP_WORKFORCE_SERVER = 'https://smoketest.test.lasso.io',
  VUE_APP_NX_SUBDOMAIN = 'nx',
  VUE_APP_NX_APP_BASE_URL = `https://test.${VUE_APP_NX_SUBDOMAIN}.lasso.io`
} = process.env;

export const ENV_NAME: Environment = VUE_APP_ENVIRONMENT as Environment;

const useNavigationStore = defineStore('NavigtationStore', () => {
  // state
  const navigationInProgress = ref<boolean>(false);
  const workforceServer = ref<string>(VUE_APP_WORKFORCE_SERVER);
  const { host } = window.location;
  const subdomain = ref<string>(host.split('.')[0]);
  const navConfig: { [key: string]: any } = NavConfig;
  const navSettings = ref<{ [key: string]: any }>(navConfig);
  const NX_DOMAIN = process.env.VUE_APP_NX_SUBDOMAIN ?? 'nx';
  const isNxDomain = computed(() => window.location.origin.includes(`.${NX_DOMAIN}.`));

  // actions
  const setNavigationInProgress = (inProgress: boolean) => {
    navigationInProgress.value = inProgress;
  };

  const updateSubdomain = (url: string) => {
    return url.replace(url.split('//')[1].split('.')[0], subdomain.value);
  };

  const getSubdomainUrl = (expression: string) => {
    const templateMatcher = /\${\s?([^{}\s]*)\s?}/g;
    return expression.replace(templateMatcher, (substring, literal) => {
      if (literal === 'subdomain') return subdomain.value;
      return substring;
    });
  };

  return {
    navigationInProgress,
    setNavigationInProgress,
    getSubdomainUrl,
    updateSubdomain,
    workforceServer,
    navSettings,
    subdomain,
    isNxDomain,
    nxAppBaseUrl: VUE_APP_NX_APP_BASE_URL
  };
});

export default useNavigationStore;

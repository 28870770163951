import { ref } from 'vue';
import { defineStore } from 'pinia';
import { axiosInventory } from '../services/httpService';
import { Event, IEvent } from '@/types/events';

const useEventStore = defineStore('EventStore', () => {
  const activeEvent = ref<Event | null>(null);
  const navSearchTerm = ref<string | null>(null);

  const setActiveEvent = async (code?: string) => {
    if (!code) {
      activeEvent.value = null;
    }
    try {
      const { data }: { data: IEvent | null } = await axiosInventory.get(`/events/${code}`);
      if (!data) {
        return;
      }
      activeEvent.value = new Event(data);
    } catch (error: any) {
      console.error(error);
    }
  };
  return {
    activeEvent,
    setActiveEvent,
    navSearchTerm
  };
});

export default useEventStore;

import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { orderBy } from 'lodash';
import { v4 as v4Uuid } from 'uuid';
import { axiosInventory, axiosWorkforce } from '@/services/httpService';
import { EquipmentList, EquipmentListGroup } from '@/types/events/equipmentList';
import {
  IGearQuoteGroup,
  ILaborEventPosition,
  ILaborQuoteGroup,
  GearDetailType,
  GearOrganizationType,
  LaborDetailType,
  LaborOrganizationType,
  IDbProposalType,
  IGearItemsType,
  ILaborItemsType
} from '@/types/events/proposal';

// eslint-disable-next-line import/no-cycle
import { useEquipmentListStore } from '@/store/index';

const useProposalStore = defineStore('ProposalStore', () => {
  const equipmentListStore = useEquipmentListStore();

  const enabledQuoteItems = ref<string[]>([]);
  const quoteItemOrder = ref<any[]>([]);
  const componentList = ref<any[]>([]);

  // Proposal data selections:
  // gear
  const gearGroupOptions = ref<IGearQuoteGroup[]>([]);
  const selectedGearGroups = ref<IGearQuoteGroup[]>([]);
  const gearOrganization = ref<GearOrganizationType>(GearOrganizationType.ListGroups);
  const gearDetail = ref<GearDetailType>(GearDetailType.LineItems);
  const gearShowPublicNotes = ref<boolean>(true);

  // labor
  const selectedLaborGroups = ref<ILaborQuoteGroup[]>([]);
  const eventPositionData = ref<ILaborEventPosition[]>([]);
  const laborOrganization = ref<LaborOrganizationType>(LaborOrganizationType.LaborGroups);
  const laborDetail = ref<LaborDetailType>(LaborDetailType.LineItems);

  // transport
  const selectedTransportGroups = ref<any[]>([]);

  // let apiData;
  const getEventLaborData = async (eventCode: string | undefined, page = 0, size = 100): Promise<any> => {
    // try {
    //   // await axiosWorkforce('/initialize_vue/', { method: 'GET', withCredentials: true });
    //   // const { data }: { data: any } = await axiosWorkforce.get(
    //   // await axiosWorkforce
    //   //   .get(`/events/TEST-E116500/groups/`)
    //   //   .then((response) => {
    //   //     console.log(JSON.stringify(response.data));
    //   //   })
    //   //   .catch((error) => {
    //   //     console.error(error);
    //   //   });
    //   //
    //   // console.log({ data, eventCode });
    // } catch (e) {
    //   console.error(e);
    // }

    if (!eventCode) {
      return [];
    }

    // NOTE: RAW TEST DATA UNTIL I CAN QUERY ENDPOINT
    const rawData = {
      event_positions: [
        {
          id: 409576,
          position_name: 'Audio (A1)',
          quantity: 1,
          billed_rate: '100.00',
          billed_rate_type: 'hourly',
          group: {
            id: 171878,
            name: 'Account Group 1'
          },
          days: {
            '2022-12-06': {
              hours_regular: '8',
              hours_overtime: '4',
              hours_doubletime: '6',
              hours_total: '18',
              cost_regular: '800.00',
              cost_overtime: '600.000',
              cost_doubletime: '1200.000',
              cost_total: '2600.000'
            }
          },
          hours_regular: '8',
          hours_overtime: '4',
          hours_doubletime: '6',
          hours_total: '18',
          cost_regular: '800.00',
          cost_overtime: '600.000',
          cost_doubletime: '1200.000',
          cost_total: '2600.000'
        },
        {
          id: 409579,
          position_name: 'Audio (A2)',
          quantity: 1,
          billed_rate: '200.00',
          billed_rate_type: 'hourly',
          group: {
            id: 171878,
            name: 'Account Group 1'
          },
          days: {
            '2022-12-06': {
              hours_regular: '1',
              hours_overtime: '0',
              hours_doubletime: '0',
              hours_total: '1',
              cost_regular: '200.00',
              cost_overtime: '0.000',
              cost_doubletime: '0.000',
              cost_total: '200.000'
            },
            '2022-12-07': {
              hours_regular: '1',
              hours_overtime: '0',
              hours_doubletime: '0',
              hours_total: '1',
              cost_regular: '200.00',
              cost_overtime: '0.000',
              cost_doubletime: '0.000',
              cost_total: '200.000'
            }
          },
          hours_regular: '2',
          hours_overtime: '0',
          hours_doubletime: '0',
          hours_total: '2',
          cost_regular: '400.00',
          cost_overtime: '0.000',
          cost_doubletime: '0.000',
          cost_total: '400.000'
        },
        {
          id: 409581,
          position_name: 'Video (V1)',
          quantity: 1,
          billed_rate: '29.99',
          billed_rate_type: 'hourly',
          group: {
            id: 171878,
            name: 'Account Group 1'
          },
          days: {
            '2022-12-06': {
              hours_regular: '4',
              hours_overtime: '0',
              hours_doubletime: '0',
              hours_total: '4',
              cost_regular: '119.96',
              cost_overtime: '0.000',
              cost_doubletime: '0.000',
              cost_total: '119.960'
            },
            '2022-12-07': {
              hours_regular: '1',
              hours_overtime: '0',
              hours_doubletime: '0',
              hours_total: '1',
              cost_regular: '29.99',
              cost_overtime: '0.000',
              cost_doubletime: '0.000',
              cost_total: '29.990'
            }
          },
          hours_regular: '5',
          hours_overtime: '0',
          hours_doubletime: '0',
          hours_total: '5',
          cost_regular: '149.95',
          cost_overtime: '0.000',
          cost_doubletime: '0.000',
          cost_total: '149.950'
        }
      ]
    };
    eventPositionData.value = rawData.event_positions.map((p: any) => p as ILaborEventPosition);

    return eventPositionData.value;
  };

  const getEquipmentListDetails = async (newList: EquipmentList[]) => {
    const newListData: any[] = [];

    await Promise.all(
      newList.map(async (l: EquipmentList) => {
        const groups: EquipmentListGroup[] = [];
        const {
          total: _total,
          totalPages: _totalPages,
          currentPage: _currentPage,
          rows: listGroups
        } = await equipmentListStore.getEquipmentListGroups(l.id, 0, 100);

        const listDetails = await equipmentListStore.getEquipmentListDetails(l.id);
        return { l, listGroups, listDetails };
      })
    ).then((results) => {
      results.forEach((r) => {
        let listItemCount = 0;
        const groups: EquipmentListGroup[] = [];
        if (r.listGroups && r.listGroups.length) {
          r.listGroups.forEach((group) => {
            groups.push(new EquipmentListGroup({ ...group }));
            listItemCount += group.equipmentListLineItems.length;
          });
        }
        newListData.push({ ...r.l, groups, listItemCount });
      });
    });

    return newListData;
  };

  // Get Info for selected equipment lists
  const getSelectedEquipmentListInfo = async (newList: IGearQuoteGroup[]) => {
    selectedGearGroups.value = newList;
  };

  const selectedLaborData = computed(() => {
    if (selectedLaborGroups.value.length > 0) {
      return eventPositionData.value.filter((r: ILaborEventPosition) =>
        selectedLaborGroups.value.map((grp: ILaborQuoteGroup) => grp.id).includes(r.group.id)
      );
    }
    return [];
  });

  const getLaborByGroup = (laborList: ILaborEventPosition[]) => {
    const groups: { [key: string]: ILaborEventPosition[] } = {};
    laborList.forEach((r: ILaborEventPosition) => {
      groups[r.group.name] = (groups[r.group.name] || []).concat(r);
    });
    return groups;
  };

  const getLaborByDay = (laborList: ILaborEventPosition[]) => {
    const days: { [key: string]: ILaborEventPosition[] } = {};
    laborList.forEach((r) => {
      Object.keys(r.days).forEach((dateStr: any) => {
        days[dateStr] = (days[dateStr] || []).concat({
          ...r,
          ...r.days[dateStr]
        });
      });
    });
    return days;
  };

  const getLaborByPosition = (laborList: ILaborEventPosition[]) => {
    const positions: { [key: string]: ILaborEventPosition[] } = {};
    laborList.forEach((r) => {
      positions[r.position_name] = (positions[r.position_name] || []).concat(r);
    });
    return positions;
  };

  const selectedLaborByGroups = computed(() => {
    return getLaborByGroup(selectedLaborData.value);
  });

  const selectedLaborByDays = computed(() => {
    return getLaborByDay(selectedLaborData.value);
  });

  const selectedLaborByPosition = computed(() => {
    return getLaborByPosition(selectedLaborData.value);
  });

  const laborGroupCount = computed(() => {
    return Object.keys(getLaborByGroup(eventPositionData.value)).length;
  });

  const laborDaysCount = computed(() => {
    return Object.keys(getLaborByDay(eventPositionData.value)).length;
  });

  const laborPositionCount = computed(() => {
    return Object.keys(getLaborByPosition(eventPositionData.value)).length;
  });

  const laborEventPositionCount = computed(() => {
    return eventPositionData.value.map((p) => p.id).length;
  });

  // hydrate proposal from DB
  const loadProposal = async (uuid: string) => {
    // POSSIBLE DB structure to hydrate into UI
    const rawData: IDbProposalType = {
      uuid: '1e0b65d4-d1af-499d-b9e1-36b0bb0e33e9',
      eventCode: 'SDK-34333039090004',
      quoteItems: [
        {
          key: 'labor',
          groupIds: [171878],
          detail: 'labor-totals-only',
          organization: 'labor-groups',
          order: 0
        },
        {
          key: 'gear',
          groupIds: [1, 2, 3],
          detail: 'department-summery',
          organization: 'departments',
          showPublicNotes: false,
          order: 1
        }
      ]
    };

    quoteItemOrder.value = orderBy(
      rawData.quoteItems.reduce((acc: any[], e) => {
        return acc.concat({
          key: e.key,
          order: e.order
        });
      }, []),
      'order'
    );
    const gear = rawData.quoteItems.find((i) => i.key === 'gear') as IGearItemsType;
    const labor = rawData.quoteItems.find((i) => i.key === 'labor') as ILaborItemsType;

    // load proposal setting into store state....
    enabledQuoteItems.value = rawData.quoteItems.map((i) => i.key);
    laborOrganization.value = labor.organization as LaborOrganizationType;
    laborDetail.value = labor.detail as LaborDetailType;
    gearOrganization.value = gear.organization as GearOrganizationType;
    gearDetail.value = gear.detail as GearDetailType;
    gearShowPublicNotes.value = gear.showPublicNotes ?? true;
    selectedGearGroups.value = gearGroupOptions.value.filter((g) => gear.groupIds.includes(g.id));
    const uniqueIds = new Set();
    selectedLaborGroups.value = eventPositionData.value
      .filter((element) => {
        const isDuplicate = uniqueIds.has(element.group.id);
        uniqueIds.add(element.group.id);
        return !isDuplicate;
      })
      .map((e) => e.group);
  };

  // persist the current quote item configuration to json
  const saveActiveProposal = async (eventCode: string | null) => {
    if (eventCode) {
      const jsonExport: IDbProposalType = {
        uuid: v4Uuid(),
        eventCode,
        quoteItems: []
      };

      const quoteItems = [...enabledQuoteItems.value];
      quoteItems.forEach((item) => {
        if (item === 'gear') {
          jsonExport.quoteItems.push({
            key: 'gear',
            groupIds: selectedGearGroups.value.map((g) => g.id),
            detail: gearDetail.value,
            organization: gearOrganization.value,
            showPublicNotes: gearShowPublicNotes.value,
            order: componentList.value.findIndex((e) => e.key === 'gear')
          });
        }

        if (item === 'labor') {
          jsonExport.quoteItems.push({
            key: 'labor',
            groupIds: selectedLaborGroups.value.map((g) => g.id),
            detail: laborDetail.value,
            organization: laborOrganization.value,
            order: componentList.value.findIndex((e) => e.key === 'labor')
          });
        }
      });

      const resp = await axiosInventory.post(`/events/${eventCode}/proposal/quote_config`, {
        uuid: jsonExport.uuid,
        jsonConfig: JSON.stringify(jsonExport),
        createdByUserId: 0,
        updatedByUserId: 0
      });
    }
  };
  // const getProposalHistory = () => {} <-- TODO: existing proposal history

  return {
    componentList,
    loadProposal,
    saveActiveProposal,
    quoteItemOrder,
    enabledQuoteItems,
    gearGroupOptions,
    selectedGearGroups,
    gearOrganization,
    gearDetail,
    gearShowPublicNotes,
    getSelectedEquipmentListInfo,
    getEquipmentListDetails,
    selectedLaborGroups,
    eventPositionData,
    laborOrganization,
    laborDetail,
    getEventLaborData,
    selectedLaborData,
    selectedLaborByGroups,
    laborGroupCount,
    laborDaysCount,
    laborPositionCount,
    laborEventPositionCount,
    selectedLaborByDays,
    selectedLaborByPosition,
    selectedTransportGroups
  };
});

export default useProposalStore;

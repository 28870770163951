/* eslint-disable import/no-cycle */
import { ref, computed } from 'vue';
import type { Ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type { ColDef } from '@ag-grid-community/core';
import { i18n } from '@/localization/i18n';
import { TableColumnHeader } from '@/types/componentTable';
import { PRODUCT_TYPE_CODE } from '@/constants';
import {
  useProductStore,
  useDepartmentStore,
  useCountriesStore,
  useAccountingStore,
  usePriceStrategyStore
} from '@/store';
import { IFilter, FilterOption, FilterSelectedIdMap, FilterSelectedId } from '@/types/componentInput';

const useUserConfigStore = defineStore(
  'UserConfigStore',
  () => {
    // nested store
    const productStore = useProductStore();
    const departmentStore = useDepartmentStore();
    const countriesStore = useCountriesStore();
    const accountingStore = useAccountingStore();
    const priceStrategyStore = usePriceStrategyStore();

    const { productTableGridOptions, productTypes, activeProduct } = storeToRefs(productStore);
    const { departments } = storeToRefs(departmentStore);
    const { countries } = storeToRefs(countriesStore);
    const { generalLedgerCodes } = storeToRefs(accountingStore);
    const { priceStrategies } = storeToRefs(priceStrategyStore);

    // state
    const productTableGridCols = ref<ColDef[]>([]);
    const inventorySerializedItemColumnOptions: Ref<TableColumnHeader[]> = ref([
      {
        key: 'barcode',
        position: 0,
        label: 'Inventory.SerializedItem.Barcode',
        sortable: true,
        thClass: ['border-right-0'],
        tdClass: ['p-0', 'border-right-0', 'align-middle']
      },
      {
        key: 'itemCode',
        position: 2,
        label: 'Inventory.SerializedItem.ItemCode',
        sortable: true,
        thClass: ['border-left-0', 'border-right-0'],
        tdClass: ['p-0', 'align-middle']
      },
      {
        key: 'purchaseDate',
        position: 3,
        label: 'Inventory.SerializedItem.Purchased',
        sortable: true,
        thClass: ['border-left-0', 'border-right-0'],
        tdClass: ['p-0', 'align-middle']
      },
      {
        key: 'purchasePrice',
        position: 4,
        label: 'Inventory.SerializedItem.PurchasePrice',
        sortable: true,
        thClass: ['border-left-0', 'border-right-0'],
        tdClass: ['p-0', 'align-middle']
      },
      {
        key: 'itemStatus',
        position: 5,
        label: 'Inventory.SerializedItem.Status',
        sortable: true,
        thClass: ['border-left-0', 'border-right-0'],
        tdClass: ['p-0', 'align-middle']
      },
      {
        key: 'id',
        position: 6,
        label: 'Inventory.SerializedItem.Actions',
        sortable: false,
        thClass: ['border-left-0', 'text-center'],
        tdClass: ['p-0', 'align-middle']
      }
    ]);
    const inventorySerializedItemColumns: Ref<TableColumnHeader[]> = ref([]);
    const productTableFilters: Ref<IFilter[]> = ref([
      {
        id: 'productType',
        component: 'MultiFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.ProductType').toString() }).toString(),
          selected: [] as number[],
          options: [] as FilterOption[],
          placeholder: i18n
            .t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.ProductType').toString() })
            .toString()
        }
      },
      {
        id: 'department',
        component: 'SingleFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.Department').toString() }).toString(),
          selected: null as number | null,
          options: [] as FilterOption[],
          placeholder: i18n
            .t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.Department').toString() })
            .toString()
        }
      },
      {
        id: 'category',
        component: 'MultiFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.Category').toString() }).toString(),
          selected: [] as number[],
          options: [] as FilterOption[],
          placeholder: i18n.t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.Category').toString() }).toString()
        }
      },
      {
        id: 'country',
        component: 'MultiFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.Country').toString() }).toString(),
          selected: [] as number[],
          options: [] as FilterOption[],
          placeholder: i18n.t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.Country').toString() }).toString()
        }
      },
      {
        id: 'expense',
        component: 'MultiFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.Expense').toString() }).toString(),
          selected: [] as number[],
          options: [] as FilterOption[],
          placeholder: i18n.t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.Expense').toString() }).toString()
        }
      },
      {
        id: 'revenue',
        component: 'MultiFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.Revenue').toString() }).toString(),
          selected: [] as number[],
          options: [] as FilterOption[],
          placeholder: i18n.t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.Revenue').toString() }).toString()
        }
      },
      {
        id: 'priceStrategy',
        component: 'MultiFilterSelect',
        props: {
          label: i18n.t('DropdownLabels.FilterLabel', { msg: i18n.t('Filters.PriceStrategy').toString() }).toString(),
          selected: [] as number[],
          options: [] as FilterOption[],
          placeholder: i18n
            .t('DropdownLabels.DefaultSelect', { msg: i18n.t('Filters.PriceStrategy').toString() })
            .toString()
        }
      }
    ]);

    // getters
    const selectedProductFilters = computed(() => {
      const selected: FilterSelectedIdMap | FilterSelectedId = {};
      productTableFilters.value.forEach((f: IFilter) => {
        if (Array.isArray(f.props.selected)) {
          if (f.props.selected.length) {
            selected[f.id] = f.props.selected;
          }
        } else if (f.props.selected !== null) {
          selected[f.id] = f.props.selected;
        }
      });
      return selected;
    });

    const getOptionText = computed(() => (filterKey: string, optionId: number) => {
      const filterOptions = productTableFilters.value.find((f: IFilter) => f.id === filterKey);
      const selectedOption = filterOptions
        ? filterOptions.props.options.find((o: FilterOption) => o.value === optionId)
        : null;
      return selectedOption ? selectedOption.text : filterKey;
    });

    // actions
    const initInventoryProductTableColumns = () => {
      const nonDefaultColumnKeys: string[] = [
        'weight',
        'supplier.displayName',
        'productCode',
        'totalBarcodedInventory',
        'productAccounting.expenseCode.code',
        'productAccounting.revenueCode.code',
        'productAccounting.numberOfDays',
        'productAccounting.depreciationType.name'
      ];

      if (!productTableGridCols.value.length) {
        productTableGridCols.value = [
          ...productTableGridOptions.value.filter((h) => {
            return !nonDefaultColumnKeys.includes(`${h.field}`);
          })
        ];
      } else {
        productTableGridCols.value = [
          ...productTableGridCols.value.filter(
            (col) => productTableGridOptions.value.findIndex((c) => c.field === col.field) !== -1
          )
        ];
      }

      productTableGridCols.value = productTableGridCols.value.map((col) => {
        const ogColOpts = productTableGridOptions.value.find((c) => c.field === col.field);
        /* Pinia won't cache function values, so we need to fetch things like valueformatter from options */
        return {
          ...col,
          ...ogColOpts
        };
      });
    };

    const toggleSITableExpandableRow = () => {
      const expandableRow = {
        key: 'show_details',
        position: 1,
        label: '',
        sortable: false,
        thClass: ['border-left-0', 'border-right-0'],
        tdClass: ['p-0', 'border-left-0', 'border-right-0', 'align-middle']
      };

      const expandableRowProductTypes = [
        PRODUCT_TYPE_CODE.VIRTUAL,
        PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
        PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
      ];

      const hasExpandableRowFunctionality = expandableRowProductTypes.includes(activeProduct.value.productType.code);
      const optionExistIndex = inventorySerializedItemColumns.value.findIndex(
        (option) => option.key === 'show_details'
      );

      if (hasExpandableRowFunctionality && optionExistIndex < 0) {
        inventorySerializedItemColumns.value = inventorySerializedItemColumns.value.concat([expandableRow]);
      } else if (!hasExpandableRowFunctionality && optionExistIndex >= 0) {
        inventorySerializedItemColumns.value.splice(optionExistIndex, 1);
      }
    };

    const initInventorySerializedItemColumns = () => {
      if (!inventorySerializedItemColumns.value.length) {
        inventorySerializedItemColumns.value = [...inventorySerializedItemColumnOptions.value];
      }
    };

    const clearAllProductTableFilters = () => {
      productTableFilters.value.forEach((f: IFilter, idx: number) => {
        if (f.id === 'category') {
          productTableFilters.value[idx].props.options = [];
        }
        productTableFilters.value[idx].props.selected = Array.isArray(f.props.selected) ? [] : null;
      });
    };

    const clearProductTableFilter = (filterKey: string, id: number) => {
      if (filterKey === 'department') {
        const categoryIdx = productTableFilters.value.findIndex((f: IFilter) => f.id === 'category');
        productTableFilters.value[categoryIdx].props.options = [];
        productTableFilters.value[categoryIdx].props.selected = [];
      }

      const idx = productTableFilters.value.findIndex((f: IFilter) => f.id === filterKey);
      if (idx !== -1) {
        const currSelected = productTableFilters.value[idx].props.selected;
        if (Array.isArray(currSelected)) {
          productTableFilters.value[idx].props.selected = currSelected.filter((o) => o !== id);
        } else {
          productTableFilters.value[idx].props.selected = null;
        }
      }
    };

    const handleFilterSelection = async (key: string, selected: number | null | number[]) => {
      if (key === 'department') {
        const categoryIdx = productTableFilters.value.findIndex((f: IFilter) => f.id === 'category');
        if (selected === null) {
          productTableFilters.value[categoryIdx].props.options = [];
          productTableFilters.value[categoryIdx].props.selected = [];
        } else {
          const departmentCategories = await departmentStore.getCategoriesForDepartment(selected as number);
          const categoryOptions: FilterOption[] = departmentCategories
            ? departmentCategories.map((o) => {
                return {
                  text: o.categoryName,
                  value: o.id
                };
              })
            : [];
          productTableFilters.value[categoryIdx].props.options = categoryOptions;
        }
      }

      const idx = productTableFilters.value.findIndex((f: IFilter) => f.id === key);
      if (Array.isArray(selected)) {
        productTableFilters.value[idx].props.selected = selected;
      } else {
        productTableFilters.value[idx].props.selected = selected || null;
      }
    };

    const loadFilterOptionData = async () => {
      // Product Types
      await productStore.getProductTypes();
      const productTypeOptions: FilterOption[] = productTypes.value.map((o) => {
        return {
          text: o.productType,
          value: o.id
        };
      });

      let objIndex = productTableFilters.value.findIndex((f: IFilter) => f.id === 'productType');
      if (objIndex !== -1) {
        productTableFilters.value[objIndex].props.options = productTypeOptions;
      }

      // Departments
      await departmentStore.getDepartments();
      const departmentOptions: FilterOption[] = departments.value.map((o) => {
        return {
          text: o.departmentName,
          value: o.id
        };
      });
      const deptObjIndex = productTableFilters.value.findIndex((f: IFilter) => f.id === 'department');
      if (deptObjIndex !== -1) {
        productTableFilters.value[deptObjIndex].props.options = departmentOptions;
      }

      // Categories
      // skipping since dependant on department....

      // Countries
      await countriesStore.getCountries();
      const countryOptions: FilterOption[] = countries.value.map((o) => {
        return {
          text: o.name,
          value: o.id
        };
      });
      objIndex = productTableFilters.value.findIndex((f: IFilter) => f.id === 'country');
      if (objIndex !== -1) {
        productTableFilters.value[objIndex].props.options = countryOptions;
      }

      // Expense/Revenue Codes
      await accountingStore.getGeneralLedgerCodes();
      const expenseCodeOptions: FilterOption[] = generalLedgerCodes.value
        .filter((c) => c.generalLedgerType === 'Expense')
        .map((o) => {
          return {
            text: o.code,
            value: o.id
          };
        });
      objIndex = productTableFilters.value.findIndex((f: IFilter) => f.id === 'expense');
      if (objIndex !== -1) {
        productTableFilters.value[objIndex].props.options = expenseCodeOptions;
      }

      const revenueCodeOptions: FilterOption[] = generalLedgerCodes.value
        .filter((c) => c.generalLedgerType === 'Revenue')
        .map((c) => {
          return {
            text: c.code,
            value: c.id
          };
        });
      objIndex = productTableFilters.value.findIndex((f: IFilter) => f.id === 'revenue');
      if (objIndex !== -1) {
        productTableFilters.value[objIndex].props.options = revenueCodeOptions;
      }

      // Price Strategy
      await priceStrategyStore.getAvailablePriceStrategies();
      const priceStrategyOptions: FilterOption[] = priceStrategies.value.map((o) => {
        return {
          text: o.displayName,
          value: o.id
        };
      });
      objIndex = productTableFilters.value.findIndex((f: IFilter) => f.id === 'priceStrategy');
      if (objIndex !== -1) {
        productTableFilters.value[objIndex].props.options = priceStrategyOptions;
      }
    };

    return {
      productTableGridOptions,
      productTableGridCols,
      initInventoryProductTableColumns,
      inventorySerializedItemColumnOptions,
      inventorySerializedItemColumns,
      initInventorySerializedItemColumns,
      productTableFilters,
      clearAllProductTableFilters,
      clearProductTableFilter,
      selectedProductFilters,
      handleFilterSelection,
      getOptionText,
      loadFilterOptionData,
      toggleSITableExpandableRow
    };
  },
  {
    persist: {
      paths: ['productTableGridCols', 'inventorySerializedItemColumns'],
      key: 'v2_userConfig' // iterate version for breaking changes
    }
  }
);

export default useUserConfigStore;

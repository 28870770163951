
import { defineComponent, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n-composable';
import ChatInbox from '@/components/Common/ChatInbox.vue';
import { useChatStore, usePermissionsStore } from '@/store';

export default defineComponent({
  name: 'Inbox',
  components: {
    ChatInbox
  },
  setup(props, { emit }) {
    const chatStore = useChatStore();
    const permissionsStore = usePermissionsStore();
    const chatInboxRef = ref<InstanceType<typeof ChatInbox>>();

    const { loggedInUserChatUserId, loggedInChatUserSignature } = storeToRefs(chatStore);
    const { domain: accountSlug } = storeToRefs(permissionsStore);

    const { t } = useI18n();

    const errorMessage = ref<string>();

    const initChat = async () => {
      try {
        errorMessage.value = undefined;
        await chatStore.getSignature();
      } catch (error: any) {
        console.error(error);
        errorMessage.value = t('CommonResponses.UnknownIssue').toString();
      }
    };

    watch(
      () => accountSlug.value,
      (newSlug) => {
        if (newSlug !== undefined) initChat();
      }
    );

    const handleUnreadCountChange = (unreadMessageCount: number) => {
      emit('unread-count-change', unreadMessageCount);
    };

    return {
      loggedInUserChatUserId,
      loggedInChatUserSignature,
      errorMessage,
      accountSlug,
      chatInboxRef,
      handleUnreadCountChange
    };
  }
});

/* eslint-disable import/no-cycle */
import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { axiosInventory } from '@/services/httpService';
import { useProductStore } from '@/store';
import { ITransactionLog, TransactionLog } from '@/types/inventory';
import { IPaginatedResponse } from '@/types/api/common';

interface IGetTransactionHistoryResponse extends IPaginatedResponse {
  rows: ITransactionLog[];
}

const useItemTrasactionsStore = defineStore('ItemTransactions', () => {
  const productStore = useProductStore();
  const { activeProduct } = storeToRefs(productStore);

  const activeProductItemTransactions = ref<TransactionLog[]>([]);
  const activeProductItemTransactionsNextPage = ref<number>(0);

  /**
   * Fetch product transaction history from API and persist to store.
   * @param reset boolean value indicating whether to reset pagination
   */
  const getProductItemTransactions = async (reset = false) => {
    // don't attempt API fetches when product is a draft
    if (productStore.isNewProduct()) {
      return;
    }

    if (reset) {
      activeProductItemTransactionsNextPage.value = 0;
    }

    const { data }: { data: IGetTransactionHistoryResponse } = await axiosInventory.get(
      `/products/${activeProduct.value.id}/items/transaction_history${
        activeProductItemTransactionsNextPage.value ? `?page=${activeProductItemTransactionsNextPage.value}` : ''
      }`
    );

    const { rows, totalPages, currentPage } = data;
    const fetchedTransactions = rows.map((log) => new TransactionLog(log));

    activeProductItemTransactionsNextPage.value = currentPage + 1 < totalPages ? currentPage + 1 : 0;

    if (reset) {
      activeProductItemTransactions.value = fetchedTransactions;
    } else {
      activeProductItemTransactions.value = activeProductItemTransactions.value.concat(fetchedTransactions);
    }
  };

  /**
   * Clear store transaction history state.
   */
  const resetItemTransactionList = () => {
    activeProductItemTransactions.value = [];
  };

  return {
    activeProductItemTransactions,
    activeProductItemTransactionsNextPage,
    resetItemTransactionList,
    getProductItemTransactions
  };
});

export default useItemTrasactionsStore;

import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import micromatch from 'micromatch';
import { axiosAuth, axiosInventory } from '@/services/httpService';
import Permission from '@/types/api/auth/permissions';
import { AuthPermissionResponse } from '@/types/api/auth/responses';
import { IActions } from '@/types/api/auth/actions';

type ActionKeys = keyof IActions;

const usePermissionsStore = defineStore('PermissionsStore', () => {
  // state
  const domain = ref<string>();

  const roles = ref<string[]>([]);
  const permissions = ref<Permission[]>([]);

  const allowedRoutes = ref<string[]>(['EventForm']);

  const allActions: ActionKeys[] = ['get', 'list', 'describe', 'create', 'update', 'delete'];
  const viewActions: ActionKeys[] = ['get', 'list', 'describe'];

  // actions
  /**
   * Load Current user permissions
   */
  const loadPermissions = async () => {
    try {
      const { data }: { data: AuthPermissionResponse } = await axiosAuth.get('/permissions/self');

      domain.value = data.domain;
      roles.value = [...data.roles];
      permissions.value = data.permissions.map((permission) => new Permission(permission));
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
  };

  /**
   * Faked FLAGR for now
   * @param flag
   */
  const isFeatureOn = async (flag: string): Promise<boolean> => {
    try {
      const resp = await axiosInventory.post('/features/feature_flag', { flagKey: flag });
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  const hasPermissionActions = (targetedPermissions: Permission[], targetedActions: ActionKeys[]) => {
    let isAuthed = false;

    targetedActions.forEach((action) => {
      const hasAction = targetedPermissions.find((permission) => permission.actions[action]);

      if (hasAction) {
        isAuthed = true;
      } else {
        isAuthed = false;
      }
    });

    return isAuthed;
  };

  const hasPermissionObject = (pattern: string | readonly string[]) => {
    // filter user permissions on given pattern(s)
    return permissions.value.filter((permission) => {
      return permission.object === '*' || micromatch.isMatch(permission.object, pattern);
    });
  };

  const hasRole = (pattern: string) => {
    return roles.value.includes(pattern);
  };

  // computed
  const gearPerms = computed(() => hasPermissionObject('gear/*'));
  const warehouseInventoryPerms = computed(() => hasPermissionObject('gear/warehouse/*'));

  const accountImportPerms = computed(() => hasPermissionObject('lasso/account/import'));
  const accountExportPerms = computed(() => hasPermissionObject('lasso/account/export'));
  const accountDocumentPerms = computed(() => hasPermissionObject('lasso/account/documents'));
  const chatPerms = computed(() => hasPermissionObject('lasso/chat/*'));
  const eventsPerms = computed(() => hasPermissionObject('lasso/event'));
  const lassoProjectPerms = computed(() =>
    hasPermissionObject(['lasso/event', 'lasso/event/projects', 'lasso/event/projects/*'])
  );
  const eventSchedulePerms = computed(() => hasPermissionObject('lasso/event/schedules'));
  const clientPerms = computed(() =>
    hasPermissionObject([
      'lasso/event/client',
      'lasso/event/client/contacts',
      'lasso/event/client/documents',
      'lasso/event/client/notes'
    ])
  );
  const eventFinancePerms = computed(() => hasPermissionObject('lasso/event/finance'));
  const venuePerms = computed(() => hasPermissionObject('lasso/venue'));

  const accountSettingsPerms = computed(() => hasPermissionObject('workforce/account/settings'));
  const crewPerms = computed(() => hasPermissionObject('workforce/crew'));
  const eventsCrewRosterPerms = computed(() => hasPermissionObject('workforce/crew/roster'));
  const crewSchedulePerms = computed(() => hasPermissionObject('workforce/crew/schedules'));
  const crewMessagesPerms = computed(() => hasPermissionObject('workforce/crew/messages'));
  const crewRatesPerms = computed(() => hasPermissionObject('workforce/crew/pay_rates'));
  const crewRatingPerms = computed(() => hasPermissionObject('workforce/crew/performance_ratings'));
  const crewPositionPerms = computed(() => hasPermissionObject('workforce/crew/positions'));
  const workforceCrewSchedulePerms = computed(() => hasPermissionObject('workforce/crew/schedules'));
  const programInvitePerms = computed(() => hasPermissionObject('workforce/crew/roster/invitations'));
  const travelPerms = computed(() => hasPermissionObject(['workforce/travel', 'workforce/travel/*']));
  // const travelDataPerms = computed(() => hasPermission('workforce/travel/traveling_data'));
  const workforceOnboardPerms = computed(() => hasPermissionObject('workforce/onboarding'));
  const workforceOnboardInfoPerms = computed(() => hasPermissionObject('workforce/onboarding/info'));
  const workforcePositionsPerms = computed(() => hasPermissionObject('workforce/positions'));
  const billingRatesPerms = computed(() => hasPermissionObject('workforce/positions/billable_rates'));

  const workforceFinancePerms = computed(() => hasPermissionObject('workforce/finance'));
  const marketplaceFinancePerms = computed(() => hasPermissionObject('marketplace/finance'));

  // =============================
  const canEditInventoryItems = computed(() => {
    return hasPermissionActions(warehouseInventoryPerms.value, ['create', 'update']);
  });

  const canReadWriteChat = computed(() => {
    return hasPermissionActions(chatPerms.value, ['create', 'update', 'list']);
  });

  const canReadWriteGear = computed(() => {
    return hasPermissionActions(gearPerms.value, ['create', 'update', 'list']);
  });

  const features = computed(() => {
    return {
      // Account Features
      // analyticsFeature: false,
      // calendarFeedsFeature: false,
      commsEmailFeature: hasPermissionActions(crewMessagesPerms.value, allActions),
      // commsSmsFeature: false,
      // communicationsFeature: false,
      crewCriteriaFeature: false,
      // crewRatingsFeature: false,
      customFieldsFeature: false,
      // demoTravelFeature: false,
      documentMgmtFeature: false,
      // eventCrewingMessageSmsFeature: false,
      // eventRecapFeature: false,
      financeSummaryFeature: hasPermissionActions(workforceFinancePerms.value, viewActions),
      // laborQuoteFeature: false,
      // mobileFeature: false,
      // onboardingFeature: false,
      programsFeature: false,
      projectManagementFeature: hasPermissionActions(lassoProjectPerms.value, allActions),
      // publiclyVisiblePositionsFeature: false,
      // registrationFeature: false,
      // schedulingFeature: false,
      // smsCrewingFeature: false,
      // stakeholderExportsFeature: false,
      // swipeclockTimeFeature: false,
      // tagsFeature: false,
      travelBookFeature: hasPermissionActions(travelPerms.value, allActions),
      travelFeature: hasPermissionActions(travelPerms.value, viewActions),
      // travelQuotesFeature: isUserAuthed(travelDataPerms.value, viewActions),
      vendorsFeature: false,
      proposalsFeature: false,
      inventoryFeature: false,
      logisticsFeature: false,

      // User Permissions
      accountConfigurePermission: hasPermissionActions(accountSettingsPerms.value, ['create', 'update']),
      billableRatesViewPermission: hasPermissionActions(billingRatesPerms.value, viewActions),
      clientsModifyPermission: hasPermissionActions(clientPerms.value, allActions),
      crewApprovePermission: hasPermissionActions(crewPositionPerms.value, allActions),
      crewModifyPermission: hasPermissionActions(crewPerms.value, allActions),
      crewRatesViewPermission: hasPermissionActions(crewRatesPerms.value, viewActions),
      crewRatingsManagePermission: hasPermissionActions(crewRatingPerms.value, ['create', 'update']),
      crewRatingsViewPermission: hasPermissionActions(crewRatingPerms.value, viewActions),
      crewTravelBookingPermission: hasPermissionActions(travelPerms.value, allActions),
      crewTravelViewPermission: hasPermissionActions(travelPerms.value, viewActions),
      dataExportPermission: hasPermissionActions(accountExportPerms.value, ['create']),
      dataImportPermission: hasPermissionActions(accountImportPerms.value, ['create']),
      documentLibraryViewPermission: hasPermissionActions(accountDocumentPerms.value, viewActions),
      eventsCrewingPermission: hasPermissionActions(eventsCrewRosterPerms.value, allActions),
      eventsDeletePermission: hasPermissionActions(eventsPerms.value, ['delete']),
      eventsFinancialSummaryPermission: hasPermissionActions(eventFinancePerms.value, viewActions),
      eventsModifyPermission: hasPermissionActions(eventsPerms.value, allActions),
      eventsRosterManagePermission: hasPermissionActions(eventsCrewRosterPerms.value, allActions),
      eventsScheduleModifyPermission: hasPermissionActions(crewSchedulePerms.value, allActions),
      marketplaceFinanceViewPermission: hasPermissionActions(marketplaceFinancePerms.value, viewActions),
      messageCrewPermission: hasPermissionActions(crewMessagesPerms.value, ['create', 'update']),
      onboardingBasicPermission:
        hasPermissionActions(workforceOnboardPerms.value, viewActions) ||
        hasPermissionActions(workforceOnboardInfoPerms.value, ['update']),
      onboardingHrPermission:
        hasPermissionActions(workforceOnboardPerms.value, allActions) ||
        hasPermissionActions(workforceOnboardInfoPerms.value, allActions),
      positionsModifyPermission: hasPermissionActions(workforcePositionsPerms.value, [
        'create',
        'update',
        'get',
        'list',
        'describe'
      ]),
      programInvitationsPermission: hasPermissionActions(programInvitePerms.value, ['create']),
      venuesModifyPermission: hasPermissionActions(venuePerms.value, ['create', 'update']),
      viewOnlySchedulesPermission:
        !hasPermissionObject('*') &&
        (hasPermissionActions(eventSchedulePerms.value, viewActions) ||
          hasPermissionActions(workforceCrewSchedulePerms.value, viewActions)),

      // Other stuff
      accountHasOnboardingAccount: false,
      accountIsMarketplace: false,
      accountUsesMarketplace: false,
      additionalEventInfoFeatureFlag: false,
      marketplaceAdHocPayments: false,
      smartCrewCrewingUiFeatureFlag: false,
      userIsAdmin: false,
      userIsLasso: hasRole('r-is_lasso:admin'),
      integrationWorkflowDemoFeatureFlag: true
    };
  });

  const financeSummaryAllowed = computed(() => {
    return features.value.financeSummaryFeature || features.value.eventsFinancialSummaryPermission;
  });

  const editCriteriaAllowed = computed(
    () => features.value.crewCriteriaFeature && features.value.eventsModifyPermission
  );

  const marketplacePageAllowed = computed(
    () =>
      features.value.marketplaceFinanceViewPermission &&
      (features.value.accountUsesMarketplace || features.value.accountIsMarketplace)
  );

  return {
    isFeatureOn,
    domain,
    roles,
    permissions,
    canEditInventoryItems,
    warehouseInventoryPerms,
    loadPermissions,
    canReadWriteChat,
    canReadWriteGear,
    allowedRoutes,
    features,
    financeSummaryAllowed,
    editCriteriaAllowed,
    marketplacePageAllowed
  };
});

export default usePermissionsStore;

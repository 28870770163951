var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"spoke-nav",attrs:{"role":"navigation"}},[_c('a',{staticClass:"sr-only",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('Nav.SkipNav')))]),_c('nav',{class:{
    open: _vm.labelIsOpen,
    'mobile-open': _vm.spokeNavIsOpen
    },attrs:{"id":"spoke-navigation","role":"navigation"}},_vm._l((_vm.renderedItems),function(item){return _c('SpokeNavItem',_vm._b({key:item.titleKey},'SpokeNavItem',item,false))}),1),_c('nav',{class:{
    open: _vm.labelIsOpen,
    'mobile-open': _vm.spokeNavIsOpen
    },attrs:{"id":"spoke-nav-bottom"}},[_c('b-button',{staticClass:"shadow-none spokesLabelsToggle",attrs:{"size":"sm","variant":"outline"},on:{"click":function($event){return _vm.toggleSpokes()}}},[_c('b-icon',{attrs:{"icon":"list-stars","aria-label":"Expand","font-scale":"1.5"}})],1),_c('button',{staticClass:"spokes-mobile-toggle d-md-none d-lg-none",on:{"click":function($event){return _vm.toggleMobileSpokes()}}},[_c('b-icon-caret-right-fill')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
enum GearIconSizes {
  'xs' = '15px',
  'x-small' = '15px',
  'sm' = '30px',
  'small' = '30px',
  'md' = '48px',
  'medium' = '48px',
  'lg' = '75px',
  'large' = '75px',
  'xl' = '100px',
  'x-large' = '100px'
}

export default GearIconSizes;

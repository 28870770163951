
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import { Editor } from '@tiptap/core';
import TiptapEditorBase from './TiptapEditorBase.vue';
import extensions from './extensions';

export default defineComponent({
  name: 'LassoTiptapBasic',
  components: {
    TiptapEditorBase
  },
  props: {
    value: {
      type: String as PropType<string>,
      required: true
    },
    id: {
      type: String as PropType<string>,
      required: false
    },
    variant: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'flex-grow'>,
      default: () => 'sm'
    },
    objectId: {
      type: Number,
      required: false
    },
    dataTestIdString: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const tiptapEditor = ref<Editor | null>(null);

    onMounted(() => {
      tiptapEditor.value = new Editor({
        content: props.value ? JSON.parse(props.value) : '',
        extensions: extensions(),
        onUpdate: () => {
          emit('input', JSON.stringify(tiptapEditor.value?.getJSON()));
        }
      });
    });

    onBeforeUnmount(() => tiptapEditor.value?.destroy());

    watch(
      () => props.value,
      (newValue) => {
        const isSame = JSON.stringify(tiptapEditor.value?.getJSON()) === newValue;
        if (isSame) return;

        tiptapEditor.value?.commands.setContent(newValue ? JSON.parse(newValue) : newValue, false);
      }
    );

    return {
      tiptapEditor
    };
  }
});

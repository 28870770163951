// eslint-disable-next-line max-classes-per-file
import { BvTableField } from 'bootstrap-vue';
import { format } from 'date-fns';
import { LassoFile, User } from '@/types/core';

interface IJobTimeline {
  startDate: Date | null;
  endDate: Date | null;
}

export class JobTimeline implements IJobTimeline {
  startDate: Date | null = null;

  endDate: Date | null = null;

  get formattedStartDate() {
    return typeof this.startDate === 'object' ? format(this.startDate as Date, 'MM/dd/yyyy') : '';
  }

  get formattedEndDate() {
    return typeof this.endDate === 'object' ? format(this.endDate as Date, 'MM/dd/yyyy') : '';
  }

  constructor(select?: Record<string, any>) {
    if (!select) {
      return;
    }
    // TODO: change select.startDate to new Date(select.startDate)
    this.startDate = select.startDate ? new Date(select.startDate) : this.startDate;
    this.endDate = select.endDate ? new Date(select.endDate) : this.endDate;
  }
}

export type Status = 'Not Started' | 'In Progress' | 'Complete';
export type JobType = 'Event' | 'Tour' | 'Production';

export interface IJob {
  num: number;
  type: JobType;
  name: string;
  timeline: JobTimeline;
  status: Status; // TODO: make enum JobStatus
  dateCreated: Date | string;
  client: string; // TODO: This should reference the Customer Class
  primaryContact: User | null;
  lastActivity: Date | string;
  fillStatus: Status; // TODO: make enum FillStatus
  revenue: number;
  subRental: boolean;
  depositDate: Date | string;
  salesRep: string; // TODO: This should be replaced with a User Class
  rfpStatus: boolean;
  docs: LassoFile[] | File[];
}

export default class Job implements IJob {
  num = 0;

  type: JobType = 'Event';

  name = '';

  timeline = new JobTimeline();

  status = 'Not Started' as Status; // TODO: make enum JobStatus

  dateCreated = '';

  client = ''; // TODO: This should reference the Customer Class

  primaryContact: User | null = null;

  lastActivity = '';

  fillStatus = 'Not Started' as Status; // TODO: make enum FillStatus

  revenue = 0;

  subRental = false;

  depositDate = '';

  salesRep = ''; // TODO: This should be replaced with a SalesRep Class

  rfpStatus = false;

  docs = [];

  constructor(select?: Record<string, any>) {
    if (!select) {
      return;
    }

    this.num = select.num ?? this.num;
    this.type = select.type ?? this.type;
    this.name = select.name ?? this.name;
    this.timeline = select.timeline ? new JobTimeline(select.timeline) : this.timeline;
    this.status = select.status ?? this.status;
    this.dateCreated = select.dateCreated ?? this.dateCreated;
    this.client = select.client ?? this.client;
    this.primaryContact = select.primaryContact ? new User(select.primaryContact) : this.primaryContact;
    this.lastActivity = select.lastActivity ?? this.lastActivity;
    this.fillStatus = select.fillStatus ?? this.fillStatus;
    this.revenue = select.revenue ?? this.revenue;
    this.subRental = select.subRental ?? this.subRental;
    this.depositDate = select.depositDate ?? this.depositDate;
    this.salesRep = select.salesRep ?? this.salesRep;
    this.rfpStatus = select.rfpStatus ?? this.rfpStatus;
    this.docs = select.docs.length ? select.docs.map((doc: LassoFile) => new LassoFile(doc)) : this.docs;
  }
}

export type ColumnHeader = { key: string } & BvTableField & { position: number };

/* eslint-disable camelcase */
/* eslint-disable max-classes-per-file */

import { EquipmentListGroup, IEquipmentList, EquipmentList } from '@/types/events/equipmentList';

export enum GearOrganizationType {
  ListGroups = 'list-groups',
  Departments = 'departments'
}

export enum GearDetailType {
  ListTotalsOnly = 'list-totals-only',
  LineItems = 'line-items',
  DepartmentSummary = 'department-summery'
}

export enum LaborOrganizationType {
  LaborGroups = 'labor-groups',
  EventDays = 'event-days',
  PositionName = 'position-name',
  EventPosition = 'event-position'
}

export enum LaborDetailType {
  LaborTotalsOnly = 'labor-totals-only',
  LineItems = 'line-items'
}

// Group List Items:
export interface IGearQuoteGroup extends IEquipmentList {
  list: EquipmentList;
  detail: EquipmentList;
  groups: EquipmentListGroup[];
}

export interface ILaborQuoteGroup {
  id: number;
  name: string;
}

export interface ILaborCost {
  hours_regular: string;
  hours_overTime: string;
  hours_doubleTime: string;
  cost_regular: string;
  cost_overTime: string;
  cost_doubleTime: string;
  cost_total: string;
}

export interface ILaborDays {
  [key: string]: ILaborCost;
}

type BillingRateType = 'hourly' | 'daily' | 'weekly';

export interface ILaborEventPosition extends ILaborCost {
  id: number;
  position_name: string;
  quantity: number;
  billed_rate: string;
  billed_rate_type: BillingRateType;
  group: ILaborQuoteGroup;
  days: ILaborDays[];
}

export interface IQuoteItemType {
  key: string;
  order: number;
  groupIds: number[];
  organization: string;
  detail: string;
}
export interface IGearItemsType extends IQuoteItemType {
  showPublicNotes: boolean;
}

export type ILaborItemsType = IQuoteItemType;

export interface IDbProposalType {
  uuid: string;
  eventCode: string;
  quoteItems: Array<IGearItemsType | ILaborItemsType>;
}

export interface IComponentPropsType {
  selected: object; // reference object
  options: any[];
  enabled: boolean;
  disabled: boolean;
}

export interface IComponentType {
  compName: string;
  key: string;
  viewComponent: string | null;
  compProps: IComponentPropsType;
  compUpdate: (checked: boolean, val: any) => Promise<void>;
  order: number;
}

import Vue from 'vue';

type ToastType = 'success' | 'error' | 'warning' | 'info';

export interface LToastOptions {
  type: ToastType;
  title: string;
  message: string;
  delay?: number;
}

const titleProps = {
  success: {
    variant: 'success',
    icon: 'b-icon-check'
  },
  error: {
    variant: 'danger',
    icon: 'b-icon-exclamation-octagon-fill'
  },
  warning: {
    variant: 'warning',
    icon: 'b-icon-exclamation'
  },
  info: {
    variant: 'info',
    icon: 'b-icon-info'
  }
};

const LToastPlugin = {
  install(_Vue: typeof Vue) {
    // eslint-disable-next-line no-param-reassign
    _Vue.prototype.$showLToast = function show(this: Vue, options: LToastOptions) {
      const h = this.$createElement;

      const vNodesTitle = [
        h(
          'div',
          {
            class: 'd-flex align-items-center'
          },
          [
            h(
              'div',
              {
                class: 'mr-2'
              },
              [
                h(titleProps[options.type].icon, {
                  props: { variant: titleProps[options.type].variant, 'font-scale': 2 }
                })
              ]
            ),
            options.title
          ]
        )
      ];

      const variant = () => {
        if (options.type === 'error') {
          return 'danger';
        }
        return options.type;
      };

      this.$bvToast.toast(options.message, {
        title: vNodesTitle,
        solid: true,
        autoHideDelay: options.delay ?? 5000,
        variant: variant(),
        toaster: 'b-toaster-top-center'
      });
    };
  }
};

export default LToastPlugin;

/* eslint-disable max-classes-per-file */
import { isBefore, startOfDay, isToday } from 'date-fns';
import { IEvent } from '@/types/events';
import { IUser, User } from '@/types/core/index';

export interface IProjectAttachmentFolder {
  id: number;

  title: string;

  /* relations */

  eventId: number;
  event?: IEvent;

  parentFolderId: number | null;
  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: string;
  dbDateUpdated: string;

  children?: Array<
    | { type: 'folder'; data: IProjectAttachmentFolder }
    // eslint-disable-next-line no-use-before-define
    | { type: 'note'; data: IProjectNote }
    // eslint-disable-next-line no-use-before-define
    | { type: 'file'; data: IProjectFile }
    // eslint-disable-next-line no-use-before-define
    | { type: 'link'; data: IProjectLink }
  >;
}

export class ProjectAttachmentFolder {
  id: number;

  title: string;

  /* relations */

  eventId: number;

  event?: IEvent;

  parentFolderId: number | null;

  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: Date;

  dbDateUpdated: Date;

  children: Array<
    | { type: 'folder'; data: ProjectAttachmentFolder }
    // eslint-disable-next-line no-use-before-define
    | { type: 'note'; data: ProjectNote }
    // eslint-disable-next-line no-use-before-define
    | { type: 'file'; data: ProjectFile }
    // eslint-disable-next-line no-use-before-define
    | { type: 'link'; data: ProjectLink }
  >;

  constructor(options?: Partial<IProjectAttachmentFolder>) {
    this.id = options?.id ?? 0;
    this.title = options?.title ?? '';
    this.eventId = options?.eventId ?? 0;
    this.parentFolderId = options?.parentFolderId ?? null;
    this.dbDateCreated = options?.dbDateCreated ? new Date(options?.dbDateCreated) : new Date();
    this.dbDateUpdated = options?.dbDateUpdated ? new Date(options?.dbDateUpdated) : new Date();

    this.children =
      // eslint-disable-next-line array-callback-return, consistent-return
      options?.children?.map((child) => {
        switch (child.type) {
          case 'folder':
            return {
              type: 'folder',
              data: new ProjectAttachmentFolder(child.data)
            };
          case 'note':
            return {
              type: 'note',
              // eslint-disable-next-line no-use-before-define
              data: new ProjectNote(child.data)
            };
          case 'file':
            return {
              type: 'file',
              // eslint-disable-next-line no-use-before-define
              data: new ProjectFile(child.data)
            };
          case 'link':
            return {
              type: 'link',
              // eslint-disable-next-line no-use-before-define
              data: new ProjectLink(child.data)
            };
          // no default
        }
      }) ?? [];
  }
}

export interface IProjectNote {
  id: number;

  title: string;

  content: string;

  /* relations */

  eventId: number;
  event?: IEvent;

  parentFolderId: number | null;
  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: string;
  dbDateUpdated: string;
}

export class ProjectNote {
  id: number;

  title: string;

  content: string;

  /* relations */

  eventId: number;

  event?: IEvent;

  parentFolderId: number | null;

  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: Date;

  dbDateUpdated: Date;

  constructor(options?: Partial<IProjectNote>) {
    this.id = options?.id ?? 0;
    this.title = options?.title ?? '';
    this.content = options?.content ?? '';
    this.eventId = options?.eventId ?? 0;
    this.parentFolderId = options?.parentFolderId ?? null;
    this.dbDateCreated = options?.dbDateCreated ? new Date(options?.dbDateCreated) : new Date();
    this.dbDateUpdated = options?.dbDateUpdated ? new Date(options?.dbDateUpdated) : new Date();
  }
}

export interface IProjectLink {
  id: number;

  title: string;

  url: string;

  /* relations */

  eventId: number;
  event?: IEvent;

  parentFolderId: number | null;
  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: string;
  dbDateUpdated: string;
}

export class ProjectLink {
  id: number;

  title: string;

  url: string;

  /* relations */

  eventId: number;

  event?: IEvent;

  parentFolderId: number | null;

  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: Date;

  dbDateUpdated: Date;

  constructor(options?: Partial<IProjectLink>) {
    this.id = options?.id ?? 0;
    this.title = options?.title ?? '';
    this.url = options?.url ?? '';
    this.eventId = options?.eventId ?? 0;
    this.parentFolderId = options?.parentFolderId ?? null;
    this.dbDateCreated = options?.dbDateCreated ? new Date(options?.dbDateCreated) : new Date();
    this.dbDateUpdated = options?.dbDateUpdated ? new Date(options?.dbDateUpdated) : new Date();
  }
}

export interface IProjectFile {
  id: number;

  title: string;

  fileName: string;

  /* relations */

  eventId: number;
  event?: IEvent;

  parentFolderId: number | null;
  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: string;
  dbDateUpdated: string;
}

export class ProjectFile {
  id: number;

  title: string;

  fileName: string;

  /* relations */

  eventId: number;

  event?: IEvent;

  parentFolderId: number | null;

  parentFolder?: IProjectAttachmentFolder;

  dbDateCreated: Date;

  dbDateUpdated: Date;

  /* Used on Front-end Only */

  downloadingState: boolean;

  constructor(options?: Partial<IProjectFile>) {
    this.id = options?.id ?? 0;
    this.title = options?.title ?? '';
    this.fileName = options?.fileName ?? '';
    this.eventId = options?.eventId ?? 0;
    this.parentFolderId = options?.parentFolderId ?? null;
    this.dbDateCreated = options?.dbDateCreated ? new Date(options?.dbDateCreated) : new Date();
    this.dbDateUpdated = options?.dbDateUpdated ? new Date(options?.dbDateUpdated) : new Date();
    this.downloadingState = false;
  }
}

export interface IProjectTaskList {
  id: number;

  title: string;

  /* relations */

  eventId: number;
  event?: IEvent;
}

export class ProjectTaskList {
  id: number;

  title: string;

  eventId: number;

  active: boolean;

  lastSaved: Date | null;

  constructor(options?: Partial<IProjectTaskList>) {
    this.id = options?.id ?? 0;
    this.title = options?.title ?? '';
    this.eventId = options?.eventId ?? 0;
    this.active = false;
    this.lastSaved = null;
  }
}

export interface IProjectTaskStatus {
  id: number;

  status?: string;

  code?: string;
}

export class ProjectTaskStatus {
  id: number;

  status: string;

  code: string;

  constructor(options?: Partial<IProjectTaskStatus>) {
    this.id = options?.id ?? 0;
    this.status = options?.status ?? '';
    this.code = options?.code ?? '';
  }
}

export const projectTaskPriorities = ['low', 'medium', 'high'] as const;
export type ProjectTaskPriority = typeof projectTaskPriorities[number];

export interface IProjectTask {
  id?: number;

  title: string;

  dateStart: string | null;

  dateEnd: string | null;

  billRate: number | null;

  duration: number | null;

  isMilestone: boolean;

  description: string;

  priority: ProjectTaskPriority;

  uuid: string;

  /* relations */

  eventId: number;

  taskStatus: IProjectTaskStatus;

  createdByUser?: IUser;

  // only have id field required
  assignedUsers: Array<Partial<Omit<IUser, 'id'>> & Pick<IUser, 'id'>>;

  // only have id field required
  watchingUsers: Array<Partial<Omit<IUser, 'id'>> & Pick<IUser, 'id'>>;

  taskListId: number;

  parentTaskId: number | null;

  // eslint-disable-next-line no-use-before-define
  dependencies: IProjectTaskDependency[];

  hasChildren: boolean;

  // eslint-disable-next-line no-use-before-define
  tags: IProjectTaskTag[];

  // eslint-disable-next-line no-use-before-define
  parentTask?: ProjectTask;

  billableHours: number | null;

  timeEstimate: number | null;

  // create task properties
  taskIdToPlaceAbove?: number | undefined | null;

  taskIdToPlaceUnder?: number | undefined | null;
}

export const taskDependencyTypes = ['blocks', 'blocked-by'] as const;
export type TaskDependencyType = typeof taskDependencyTypes[number];

export interface IProjectTaskDependency {
  taskId: number | null;
  task?: Omit<IProjectTask, 'dependencies' | 'children' | 'assignedUsers' | 'createdByUser'>;

  type: TaskDependencyType | null;
}

export interface IProjectTaskTag {
  /** tag id */
  id: number;
  /** tag contents */
  name?: string;
}

export class ProjectTask {
  id: number;

  title: string;

  dateStart: Date | null;

  dateEnd: Date | null;

  billRate: number | null;

  duration: number | null;

  isMilestone: boolean;

  description: string;

  priority: ProjectTaskPriority;

  eventId: number;

  taskStatus: ProjectTaskStatus;

  createdByUser?: User;

  uuid: string;

  // only have id field required
  assignedUsers: Array<Partial<Omit<User, 'id'>> & Pick<User, 'id'>>;

  // only have id field required
  watchingUsers: Array<Partial<Omit<User, 'id'>> & Pick<User, 'id'>>;

  taskListId: number;

  parentTaskId: number | null;

  hasChildren: boolean;

  dependencies: IProjectTaskDependency[];

  tags: IProjectTaskTag[];

  parentTask?: ProjectTask;

  billableHours: number | null;

  timeEstimate: number | null;

  // create task properties
  taskIdToPlaceAbove?: number | undefined | null;

  taskIdToPlaceUnder?: number | undefined | null;

  // syncfusion gantt chart dependencies
  dependency: string | undefined;

  children?: ProjectTask[];

  constructor(options?: Partial<IProjectTask>) {
    this.id = options?.id ?? 0;
    this.title = options?.title ?? '';
    this.dateStart = options?.dateStart ? new Date(options.dateStart) : null;
    this.dateEnd = options?.dateEnd ? new Date(options.dateEnd) : null;
    this.billRate = options?.billRate ?? null;
    this.duration = options?.duration ?? null;
    this.isMilestone = options?.isMilestone ?? false;
    this.description = options?.description ?? '';
    this.priority = options?.priority ?? 'medium';
    this.eventId = options?.eventId ?? 0;
    this.uuid = options?.uuid ?? '';
    this.taskStatus = options?.taskStatus
      ? new ProjectTaskStatus(options.taskStatus)
      : new ProjectTaskStatus({ id: 1, status: 'Backlog', code: 'BL' });
    this.createdByUser = new User(options?.createdByUser ?? {});
    this.assignedUsers = options?.assignedUsers ? options.assignedUsers.map((user) => new User(user)) : [];
    this.watchingUsers = options?.watchingUsers ? options.watchingUsers.map((user) => new User(user)) : [];
    this.taskListId = options?.taskListId ?? 0;
    this.parentTaskId = options?.parentTaskId ?? null;
    this.parentTask = options?.parentTask;
    this.hasChildren = options?.hasChildren ?? false;
    this.dependencies = options?.dependencies ?? [];
    this.tags = options?.tags ?? [];
    this.billableHours = options?.billableHours ?? null;
    this.timeEstimate = options?.timeEstimate ?? null;
    this.taskIdToPlaceAbove = options?.taskIdToPlaceAbove ?? null;
    this.taskIdToPlaceUnder = options?.taskIdToPlaceUnder ?? null;
  }

  getStatusColor() {
    if (this.taskStatus?.id === 3) {
      // if the task is done
      return 'darkgreen';
    }
    const today = startOfDay(new Date());
    const isBeforeToday = (date: Date | null) => {
      return date && isBefore(date, today);
    };
    if (this.taskStatus?.id === 2) {
      // if the task is in progress
      if (isBeforeToday(this.dateEnd)) {
        // if task is not finished in time
        return 'darkred';
      }
      if (this.dateEnd && isToday(this.dateEnd)) {
        // if task finishes today
        return 'goldenrod';
      }
    } else if (this.taskStatus?.id === 1) {
      // if the task is in the backlog
      if (isBeforeToday(this.dateStart) || isBeforeToday(this.dateEnd)) {
        // if not started or finished in time
        return 'darkred';
      }
      if ((this.dateStart && isToday(this.dateStart)) || (this.dateEnd && isToday(this.dateEnd))) {
        // if not started and start or end is today
        return 'goldenrod';
      }
    }
    return '#008786';
  }

  toJSON() {
    return {
      ...this,
      parentTask: undefined
    };
  }
}

export interface IProjectAttachmentCreateUploadUrlResponse {
  dbDateCreated: string;
  dbDateUpdated: string;
  eventId: number;
  fileName: string;
  id: number;
  parentFolderId: number | string;
  secureUploadUrl: string;
  title: string;
}

export interface IProjectTaskComment {
  id: number;

  createdAt: string;

  createdByUser: IUser;

  content: string;
}

export class ProjectTaskComment {
  id: number;

  createdAt: Date;

  createdByUser: User;

  content: string;

  constructor(options?: Partial<IProjectTaskComment>) {
    this.id = options?.id ?? 0;
    this.createdAt = options?.createdAt ? new Date(options.createdAt) : new Date();
    this.createdByUser = new User(options?.createdByUser);
    this.content = options?.content ?? '';
  }
}

export interface IProjectTime {
  id?: number;

  timeSpentInSeconds: number | null;

  startTime: number | null;

  endTime: number | null;

  dbDateCreated: string;

  taskId: number | null;

  userId: number;

  eventId: number;

  task: ProjectTask | null;

  event: Event | null;
}

export class ProjectTime {
  id: number;

  timeSpentInSeconds: number | null;

  startTime: number | null;

  endTime: number | null;

  dbDateCreated: Date | null;

  task: ProjectTask | null;

  event: Event | null;

  constructor(options?: Partial<IProjectTime>) {
    this.id = options?.id ?? 0;
    this.timeSpentInSeconds = options?.timeSpentInSeconds ?? null;
    this.startTime = options?.startTime ?? null;
    this.endTime = options?.endTime ?? null;
    this.dbDateCreated = options?.dbDateCreated ? new Date(options?.dbDateCreated) : null;
    this.event = options?.event ? options?.event : null;
    this.task = options?.task ? options?.task : null;
  }
}

// Below are all types for task history

export type NormalizedDiffTask = Omit<
  IProjectTask,
  | 'id'
  | 'uuid'
  | 'eventId'
  | 'parentTask'
  | 'taskListId'
  | 'children'
  | 'createdByUser'
  | 'taskStatus'
  | 'assignedUsers'
  | 'watchingUsers'
  | 'tags'
  | 'dependencies'
  | 'taskIdToPlaceAbove'
  | 'taskIdToPlaceUnder'
> & {
  taskStatus: { id: number };
  assignedUsers: { id: number }[];
  watchingUsers: { id: number }[];
  tags: { id: number }[];
  dependencies: { type: TaskDependencyType; taskId: number }[];
};
export type NormalizedDiffTaskKeys = keyof NormalizedDiffTask;
type ArrayDiff = { [key in NormalizedDiffTaskKeys]?: NormalizedDiffTask[key] };

export interface ITaskCreated {
  type: 'created';
  date: string;
}

/** Task change diff history entry */
export interface ITaskChange {
  type: 'change';
  userId: number;
  date: string;
  updated: {
    [key in NormalizedDiffTaskKeys]?: [NormalizedDiffTask[key], NormalizedDiffTask[key]];
  };
  inserted: ArrayDiff;
  removed: ArrayDiff;
}

export type IProjectTaskHistoryEntry = ITaskCreated | ITaskChange;

export interface IProjectTaskHistoryResponse {
  history: IProjectTaskHistoryEntry[];
  related: {
    statuses: IProjectTaskStatus[];
    users: IUser[];
    tags: IProjectTaskTag[];
    tasks: IProjectTask[];
  };
}

export interface IProjectTaskHistoryDisplayUpdate {
  type: 'update';
  key: string;
  fieldName: string;
  before: string;
  after: string;
}
export interface IProjectTaskHistoryDisplayChange {
  type: 'array-change';
  fieldName: string;
  display: string;
}

export type IProjectTaskHistoryDisplayEntry =
  | {
      type: 'created';
      user: User;
      date: Date;
    }
  | {
      type: 'change';
      user: User;
      date: Date;
      changes: Array<IProjectTaskHistoryDisplayUpdate | IProjectTaskHistoryDisplayChange>;
    };

export interface ITaskPaginatedCache {
  pages: ProjectTask[][];
  total: number | null;
  totalPages: number | null;
}

import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import {
  IQueryNotificationsPayload,
  IQueryNotificationsPaginatedResponse,
  IUserChangeFeed
} from '@/types/notifications/index';
import { axiosNotifications } from '@/services/httpService';

const useNotificationsStore = defineStore('NotificationsStore', () => {
  const currentNotificationsQueryPage = ref<number>(0);
  const userNotifications = ref<IUserChangeFeed[]>([]);
  const showNotificationsSidePanel = ref<boolean>(false);
  const fetchNotificationsInProgress = ref<boolean>(false);

  const intervalFetchNotificationsPid = ref<number>();

  const unreadUserNotificationCount = computed(
    () => userNotifications.value.filter((n) => n.status === 'unread').length
  );

  const queryNotifications = async (options?: IQueryNotificationsPayload, resetPage = true): Promise<null> => {
    fetchNotificationsInProgress.value = true;
    if (resetPage) {
      currentNotificationsQueryPage.value = 0;
      userNotifications.value = [];
    }
    try {
      const { data }: { data: IQueryNotificationsPaginatedResponse } = await axiosNotifications.post(
        `/notifications/query_feed?page=${currentNotificationsQueryPage.value}`,
        { ...options }
      );
      if (currentNotificationsQueryPage.value !== 0) {
        userNotifications.value = userNotifications.value.concat(data.rows);
      } else {
        userNotifications.value = data.rows;
      }

      if (data.currentPage + 1 < data.totalPages) {
        currentNotificationsQueryPage.value += 1;
        return queryNotifications(options, false);
      }
    } catch (error) {
      // TODO: Tie in error notification / toast?
      console.error(error);
    }

    fetchNotificationsInProgress.value = false;
    return null;
  };

  const setNotificationsPanelVisibility = (visible = true) => {
    showNotificationsSidePanel.value = visible;
  };

  const markNotificationsAsRead = async (userChangeFeed: IUserChangeFeed[]) => {
    await axiosNotifications.post(`/notifications/mark_as_read`, {
      userChangeFeed
    });
    const userNotificationIdsMarkedAsRead = userChangeFeed.map((change) => change.id);
    userNotifications.value = userNotifications.value.map((change) => {
      if (userNotificationIdsMarkedAsRead.includes(change.id)) {
        return {
          ...change,
          status: 'read'
        };
      }
      return change;
    });
  };

  const deleteNotifications = async (userChangeFeed: IUserChangeFeed[]) => {
    await axiosNotifications.post(`/notifications/bulk_delete`, {
      userChangeFeed
    });
  };

  const autoFetchNotifications = () => {
    intervalFetchNotificationsPid.value = setInterval(async () => {
      await queryNotifications({}, false);
    }, 30000); // 30 sec interval
  };

  // automatically check for notifications in the background
  // TODO: Tie in sockets to notifications-api?
  watch(
    () => showNotificationsSidePanel.value,
    (newShowValue) => {
      if (intervalFetchNotificationsPid.value) {
        clearInterval(intervalFetchNotificationsPid.value);
      }

      if (!newShowValue) {
        autoFetchNotifications();
      }
    }
  );

  return {
    queryNotifications,
    autoFetchNotifications,
    userNotifications,
    unreadUserNotificationCount,
    showNotificationsSidePanel,
    setNotificationsPanelVisibility,
    fetchNotificationsInProgress,
    markNotificationsAsRead,
    deleteNotifications
  };
});

export default useNotificationsStore;


import { defineComponent, onBeforeUnmount, PropType, ref } from 'vue';
import { Editor } from '@tiptap/core';
import * as Y from 'yjs';
import Collaboration from '@tiptap/extension-collaboration';
import { WebrtcProvider } from 'y-webrtc';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { fromUint8Array, toUint8Array } from 'js-base64';
import { sample } from 'lodash';
import TiptapEditorBase from './TiptapEditorBase.vue';
import extensions from './extensions';

// FIXME: this is a temporary webrtc signaling password for demo purposes, to be replaced with websockets collab implem
const signalingPassword = 'ahjee0uah3Lei8phohdauLie5Suchah5Roog8guawazei4eiLaih3pitee2thu6U';

const colors = ['#CBE896', '#FCDFA6', '#F4B886', '#C6D8AF', '#DBD8B3', '#FCC8B2', '#F0F7EE', '#D1CFE2'];

export default defineComponent({
  name: 'LassoTiptapCollab',
  components: {
    TiptapEditorBase
  },
  props: {
    id: {
      type: String as PropType<string>,
      required: false
    },
    variant: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'flex-grow'>,
      default: () => 'sm'
    },
    userDisplayName: {
      type: String,
      required: true
    },
    dataTestIdString: {
      type: String,
      required: false
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const tiptapEditor = ref<Editor | null>(null);
    const currentYDoc = ref<Y.Doc | null>(null);
    const currentProvider = ref<WebrtcProvider | null>(null);

    const destroy = () => {
      tiptapEditor.value?.destroy();
      currentProvider.value?.destroy();
      currentYDoc.value?.destroy();
    };

    const setDocument = (roomId: string, ydocEncoded: string) => {
      destroy();

      const yDoc = new Y.Doc();
      if (ydocEncoded !== '' && !ydocEncoded.startsWith('{')) {
        const update = toUint8Array(ydocEncoded);
        Y.applyUpdate(yDoc, update);
      }

      yDoc.on('update', (_a, _b, doc: Y.Doc) => emit('update', fromUint8Array(Y.encodeStateAsUpdate(doc))));

      const provider = new WebrtcProvider(roomId, yDoc, {
        // web crypto API requires https origin, disable signaling encryption during (http) development
        password: process.env.VUE_APP_ENVIRONMENT === 'dev' ? undefined : signalingPassword
      });

      currentYDoc.value = yDoc;
      currentProvider.value = provider;
      tiptapEditor.value = new Editor({
        extensions: [
          ...extensions({ history: false }),
          Collaboration.configure({
            document: yDoc
          }),
          CollaborationCursor.configure({
            provider,
            user: {
              name: props.userDisplayName,
              color: sample(colors)
            }
          })
        ]
      });

      // if note was created before collab implementation, set content using JSON
      if (ydocEncoded.startsWith('{')) {
        try {
          const parsed = JSON.parse(ydocEncoded);
          tiptapEditor.value?.commands.setContent(parsed, false);
        } catch (_) {
          // do nothing
        }
      }
    };

    onBeforeUnmount(destroy);

    return {
      tiptapEditor,
      setDocument
    };
  }
});

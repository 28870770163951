import { defineStore } from 'pinia';
import { ref } from 'vue';
import { axiosInventory } from '@/services/httpService';
import { IDepreciationMethod, IGeneralLedger, IGeneralLedgerCodes } from '@/types/accounting';

const useAccounting = defineStore('AccountingStore', () => {
  const depreciationCodes = ref<IDepreciationMethod[]>([]);
  const generalLedgerCodes = ref<IGeneralLedger[]>([]);
  const defaultDepreciation = ref<IDepreciationMethod>();

  const getDepreciationCodes = async () => {
    if (!depreciationCodes.value || !depreciationCodes.value.length) {
      const { data }: { data: IDepreciationMethod[] } = await axiosInventory.get('/accounting/depreciation_types');
      const codes = data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      const straightline = data.find((d: IDepreciationMethod) => d.name.toLowerCase() === 'straight line');
      depreciationCodes.value = codes;
      defaultDepreciation.value = straightline;
    }
    return depreciationCodes.value;
  };

  const getGeneralLedgerCodes = async () => {
    if (!generalLedgerCodes.value || !generalLedgerCodes.value.length) {
      const { data }: { data: IGeneralLedgerCodes } = await axiosInventory.get('/accounting/general_ledger_codes');
      const codes = [...data.revenueCodes, ...data.expenseCodes].sort((a, b) =>
        a.code.toLowerCase().localeCompare(b.code.toLowerCase())
      );
      generalLedgerCodes.value = codes;
    }
    return generalLedgerCodes.value;
  };

  return {
    depreciationCodes,
    generalLedgerCodes,
    defaultDepreciation,
    getDepreciationCodes,
    getGeneralLedgerCodes
  };
});

export default useAccounting;

import { EquipmentList, IEquipmentList } from '@/types/events/equipmentList';

export interface IEquipmentListPrepDay {
  goingOut: Partial<IEquipmentList>[];
  comingIn: Partial<IEquipmentList>[];
  subRentals: Partial<IEquipmentList>[];
}

export class EquipmentListPrepDay {
  goingOut: EquipmentList[] = [];

  comingIn: EquipmentList[] = [];

  subRentals: EquipmentList[] = [];

  constructor(options: IEquipmentListPrepDay) {
    if (!options) {
      return;
    }
    const { goingOut, comingIn, subRentals } = options;

    this.goingOut = goingOut.length ? goingOut.map((equipmentList) => new EquipmentList(equipmentList)) : [];
    this.comingIn = comingIn.length ? comingIn.map((equipmentList) => new EquipmentList(equipmentList)) : [];
    this.subRentals = subRentals.length ? subRentals.map((equipmentList) => new EquipmentList(equipmentList)) : [];
  }
}

import { defineStore } from 'pinia';
import { axiosInventory } from '@/services/httpService';

import {
  IListDepartmentObject,
  IDescribeDepartmentObject,
  IWriteDepartmentObject,
  IWriteDepreciationMethodObject,
  IWriteLedgerCodeObject
} from '@/types/api/gear/responses/inventory';
import { IDepreciationMethod, IGeneralLedger, IGeneralLedgerCodes } from '@/types/accounting';
import { IPriceStrategy } from '@/types/inventory';

const useSettingsStore = defineStore('SettingsStore', () => {
  // DEPARTMENTS START
  const createDepartment = async (data: IWriteDepartmentObject) => {
    return axiosInventory.post(`/departments/`, data);
  };

  const updateDepartment = async (departmentId: number, data: IWriteDepartmentObject) => {
    if (departmentId > 0) {
      await axiosInventory.put(`/departments/${departmentId}`, data);
    }
  };

  const getDepartment = async (departmentId: number) => {
    if (departmentId > 0) {
      const { data }: { data: IDescribeDepartmentObject } = await axiosInventory.get(`/departments/${departmentId}`);
      return data;
    }
    return undefined;
  };

  const getDepartmentList = async (includeArchived: boolean) => {
    // always fetch
    const { data }: { data: IListDepartmentObject[] } = await axiosInventory.get(
      `/departments?archived=${includeArchived}`
    );
    const depts = data
      ?.sort((a, b) => a.departmentName.toLowerCase().localeCompare(b.departmentName.toLowerCase()))
      ?.sort((a, b) => {
        if (a.archived === b.archived) {
          return 0;
        }
        return a.archived ? 1 : -1;
      });
    return depts || [];
  };

  const archiveDepartment = async (departmentId: number) => {
    if (departmentId > 0) {
      await axiosInventory.put(`/departments/${departmentId}/archive`);
    }
  };

  const restoreDepartment = async (departmentId: number) => {
    if (departmentId > 0) {
      await axiosInventory.put(`/departments/${departmentId}/restore`);
    }
  };
  // DEPARTMENTS END

  // DEPRECIATION METHOD START
  const createDepreciationMethod = async (data: IWriteDepreciationMethodObject) => {
    return axiosInventory.post(`/accounting/depreciation_types/`, data);
  };

  const getDepreciationMethods = async (includeArchived: boolean) => {
    const { data }: { data: IDepreciationMethod[] } = await axiosInventory.get(
      `/accounting/depreciation_types?archived=${includeArchived}`
    );
    const codes = data
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .sort((a, b) => {
        if (a.archived === b.archived) {
          return 0;
        }
        return a.archived ? 1 : -1;
      });
    return codes;
  };

  const updateDepreciationMethod = async (depreciationMethodId: number, data: IWriteDepreciationMethodObject) => {
    if (depreciationMethodId > 0) {
      await axiosInventory.put(`/accounting/depreciation_types/${depreciationMethodId}`, data);
    }
  };

  const archiveDepreciationMethod = async (depreciationMethodId: number) => {
    if (depreciationMethodId > 0) {
      await axiosInventory.put(`/accounting/depreciation_types/${depreciationMethodId}/archive`);
    }
  };

  const restoreDepreciationMethod = async (depreciationMethodId: number) => {
    if (depreciationMethodId > 0) {
      await axiosInventory.put(`/accounting/depreciation_types/${depreciationMethodId}/restore`);
    }
  };
  // DEPRECIATION METHOD END

  // LEDGER CODE START
  const createLedgerCode = async (data: IWriteLedgerCodeObject) => {
    return axiosInventory.post(`/accounting/general_ledger_codes/`, { ...data });
  };

  const sortLedgerCodes = (codes: IGeneralLedger[]) => {
    return codes
      .sort((a, b) => a.code.toLowerCase().localeCompare(b.code.toLowerCase()))
      .sort((a, b) => {
        if (a.archived === b.archived) {
          return 0;
        }
        return a.archived ? 1 : -1;
      });
  };

  const getLedgerCodes = async (includeArchived: boolean) => {
    const { data }: { data: IGeneralLedgerCodes } = await axiosInventory.get(
      `/accounting/general_ledger_codes?archived=${includeArchived}`
    );
    const codes = [...data.revenueCodes, ...data.expenseCodes];

    return sortLedgerCodes(codes);
  };

  const updateLedgerCode = async (ledgerCodeId: number, data: IWriteLedgerCodeObject) => {
    if (ledgerCodeId > 0) {
      await axiosInventory.put(`/accounting/general_ledger_codes/${ledgerCodeId}`, {
        ...data
      });
    }
  };

  const archiveLedgerCode = async (ledgerCodeId: number) => {
    if (ledgerCodeId > 0) {
      await axiosInventory.put(`/accounting/general_ledger_codes/${ledgerCodeId}/archive`);
    }
  };

  const restoreLedgerCode = async (ledgerCodeId: number) => {
    if (ledgerCodeId > 0) {
      await axiosInventory.put(`/accounting/general_ledger_codes/${ledgerCodeId}/restore`);
    }
  };
  // LEDGER CODE END

  // PRICE STRATEGIES START
  const sortPriceStrategies = (data: IPriceStrategy[]) => {
    return data.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
  };

  const getPriceStrategies = async () => {
    const { data }: { data: IPriceStrategy[] } = await axiosInventory.get(`/price_strategies`);

    return sortPriceStrategies(data);
  };

  return {
    // departments
    createDepartment,
    getDepartment,
    getDepartmentList,
    updateDepartment,
    archiveDepartment,
    restoreDepartment,
    // depreciation method
    createDepreciationMethod,
    getDepreciationMethods,
    updateDepreciationMethod,
    archiveDepreciationMethod,
    restoreDepreciationMethod,
    // ledger code
    createLedgerCode,
    sortLedgerCodes,
    getLedgerCodes,
    updateLedgerCode,
    archiveLedgerCode,
    restoreLedgerCode,
    // price strategies
    getPriceStrategies
  };
});

export default useSettingsStore;

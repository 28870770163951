import { ref } from 'vue';
import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import Job from '@/types/sales/job';

const useJobStore = defineStore('JobStore', () => {
  // TODO: Remove raw data thats hard coded with with async actions that make api requests
  const rawJobsData = [
    {
      num: 122,
      name: 'Oracle Meeting 2022',
      timeline: { startDate: '02/22/2022', endDate: '02/28/2022' },
      status: 'In Progress',
      dateCreated: '02/17/2022',
      client: 'Cinematix',
      lastActivity: '03/07/2022',
      fillStatus: 'In Progress',
      revenue: 326678,
      subRental: true,
      depositDate: '02/20/2022',
      salesRep: 'Janet Cooper',
      rfpStatus: true,
      docs: [
        {
          fileName: 'LASSO Logo',
          fileType: 'png',
          url: 'https://cdn.test.lasso.io/internal/icons/lasso-round.png'
        },
        {
          fileName: 'LASSO Logo(1)',
          fileType: 'png',
          url: 'https://cdn.test.lasso.io/internal/icons/lasso-round.png'
        },
        {
          fileName: 'LASSO Logo(2)',
          fileType: 'png',
          url: 'https://cdn.test.lasso.io/internal/icons/lasso-round.png'
        }
      ]
    },
    {
      num: 123,
      name: 'Project 1',
      timeline: { startDate: '02/22/2022', endDate: '02/28/2022' },
      status: 'Complete',
      dateCreated: '01/06/2022',
      client: 'Dramatage',
      lastActivity: '03/07/2022',
      fillStatus: 'Complete',
      revenue: 226678,
      subRental: false,
      depositDate: '02/20/2022',
      salesRep: 'Floyd Mclaughlin',
      rfpStatus: false,
      docs: []
    },
    {
      num: 124,
      name: 'Project 2',
      timeline: { startDate: '02/22/2022', endDate: '02/28/2022' },
      status: 'In Progress',
      dateCreated: '02/17/2022',
      client: 'Cinematix',
      lastActivity: '03/07/2022',
      fillStatus: 'In Progress',
      revenue: 326678,
      subRental: false,
      depositDate: '02/20/2022',
      salesRep: 'Janet Cooper',
      rfpStatus: false,
      docs: []
    },
    {
      num: 125,
      name: 'Project 3',
      timeline: { startDate: '02/22/2022', endDate: '02/28/2022' },
      status: 'Complete',
      dateCreated: '01/06/2022',
      client: 'Dramatage',
      lastActivity: '03/07/2022',
      fillStatus: 'Complete',
      revenue: 226678,
      subRental: false,
      depositDate: '02/20/2022',
      salesRep: 'Floyd Mclaughlin',
      rfpStatus: true,
      docs: [
        {
          fileName: 'LASSO Logo(3)',
          fileType: 'png',
          url: 'https://cdn.test.lasso.io/internal/icons/lasso-round.png'
        },
        {
          fileName: 'LASSO Logo(4)',
          fileType: 'png',
          url: 'https://cdn.test.lasso.io/internal/icons/lasso-round.png'
        },
        {
          fileName: 'LASSO Logo(5)',
          fileType: 'png',
          url: 'https://cdn.test.lasso.io/internal/icons/lasso-round.png'
        }
      ]
    },
    {
      num: 126,
      name: 'Project 4',
      timeline: { startDate: '02/22/2022', endDate: '02/28/2022' },
      status: 'In Progress',
      dateCreated: '02/17/2022',
      client: 'Cinematix',
      lastActivity: '03/07/2022',
      fillStatus: 'In Progress',
      revenue: 326678,
      subRental: false,
      depositDate: '02/20/2022',
      salesRep: 'Janet Cooper',
      rfpStatus: false,
      docs: []
    }
  ];

  // state
  const originalJobs: Ref<Job[]> = ref([]);
  const jobs: Ref<Job[]> = ref([]);
  const newJobs: Ref<Job[]> = ref([]);
  const clientList = ref(['Cinematix', 'Dramatage', 'Screen Heros', 'ShowBies']);

  // computed

  // actions
  const setJobsOnLoad = () => {
    originalJobs.value = rawJobsData.map((job) => new Job(job));
    jobs.value = [...originalJobs.value];
  };
  // note action above can be refactored for retieving data from api like
  //   const loadJobs = () => {
  //       const rawJobsData = axios.get('/jobs')
  //       originalJobs.value = rawJobsData.data.map((job) => new Job(job));
  //       jobs.value = [...originalJobs.value];
  //   }

  const submitNewJob = (newJob: Job) => {
    newJobs.value = jobs.value.concat([newJob]);
  };

  const loadJobsStore = () => {
    setJobsOnLoad();
  };

  return {
    loadJobsStore,
    submitNewJob,
    originalJobs,
    jobs,
    clientList
  };
});

export default useJobStore;

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { axiosInventory } from '@/services/httpService';
import { PriceStrategy, IPriceStrategy } from '@/types/inventory';

const usePriceStrategyStore = defineStore('PriceStrategyStore', () => {
  const priceStrategies = ref<PriceStrategy[]>([]);

  const getAvailablePriceStrategies = async () => {
    try {
      const { data }: { data: IPriceStrategy[] } = await axiosInventory.get('/price_strategies');
      const newPriceStrategies: PriceStrategy[] = data.map((s) => new PriceStrategy(s));
      priceStrategies.value = newPriceStrategies;
    } catch (error) {
      // FIXME: Send this error to some sort of global error handling store, present error toast
      console.error(error);
    }
  };

  return {
    priceStrategies,
    getAvailablePriceStrategies
  };
});

export default usePriceStrategyStore;


import { computed, defineComponent, PropType, ref } from 'vue';
import { IParsedChangeDetail } from '@/types/notifications';

export default defineComponent({
  name: 'NotificationCardChangeDetail',
  props: {
    change: {
      type: Object as PropType<IParsedChangeDetail>,
      required: true
    },
    alignLeft: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  setup(props) {
    const variantChangeDetails = ref<{
      [field: string]: {
        redValue: string[];
        greenValue: string[];
        yellowValue: string[];
      };
    }>({
      Priority: {
        redValue: ['high'],
        greenValue: ['low'],
        yellowValue: []
      }
    });
    const variantClass = computed(() => {
      const defaultClass = 'text-gray-500';
      const { after, field } = props.change;
      const variantChangeDetailMap = variantChangeDetails.value[field];
      if (!variantChangeDetailMap) {
        return defaultClass;
      }
      const hasGreenValue = variantChangeDetailMap.greenValue.includes(after);
      if (hasGreenValue) return 'text-green';
      const hasYellowValue = variantChangeDetailMap.yellowValue.includes(after);
      if (hasYellowValue) return 'text-warning';
      const hasRedValue = variantChangeDetailMap.redValue.includes(after);
      if (hasRedValue) return 'text-danger';

      return defaultClass;
    });
    const fieldLocalizationKey = computed(() => `Notifications.Changes.ProjectManagement.${props.change.field}`);

    return {
      variantClass,
      fieldLocalizationKey,
      variantChangeDetails
    };
  }
});

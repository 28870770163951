
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-composition-wrapper';
import { useI18n } from 'vue-i18n-composable';

import { storeToRefs } from 'pinia';
import GlobalNav from '@/components/Nav/GlobalNav.vue';
import SpokeNav from '@/components/Nav/SpokeNav.vue';
import EventHeader from '@/components/Nav/EventHeader.vue';
import MobileToolbar from '@/components/Nav/MobileToolbar.vue';

import PageLoading from '@/components/Common/PageLoading.vue';

import { useNavigationStore, usePermissionsStore } from '@/store';

export default defineComponent({
  name: 'App',
  components: {
    GlobalNav,
    SpokeNav,
    EventHeader,
    MobileToolbar,
    PageLoading
  },
  setup() {
    const route = useRoute();
    const navigationStore = useNavigationStore();
    const permissionsStore = usePermissionsStore();

    const {domain} = storeToRefs(permissionsStore);
    const { navigationInProgress } = storeToRefs(navigationStore);
    const eventPage = computed(() => Boolean(route.value.meta?.eventPage));
    const showTimeTrack = computed(() => {
      return Boolean(route.value.meta?.eventPage);
    });

    const logoUrl = computed(() => {
      const slug = domain.value;
      if (slug) {
        return `https://lasso-public.s3.amazonaws.com/_account_avatars/${slug}/logo-200.png`;
      }
      return null;
    })

    return {
      eventPage,
      navigationInProgress,
      ...useI18n(),
      logoUrl,
      showTimeTrack
    };
  }
});

// eslint-disable-next-line max-classes-per-file
export class LassoFile {
  fileName = '';

  fileType: 'pdf' | 'doc' | 'docx' | 'csv' | 'jpg' | 'jpeg' | 'png' | null = null;

  url = '';

  constructor(select?: Record<string, any>) {
    if (!select) {
      return;
    }

    this.fileName = select.fileName ?? this.fileName;
    this.fileType = select.fileType ?? this.fileType;
    this.url = select.url ?? this.url;
  }
}

interface IEmail {
  default: boolean;
  userId: number;
  email: string;
}

export class Email implements IEmail {
  default = false;

  userId: number = Math.floor(Math.random() * 100);

  email = '';

  constructor(select?: Record<string, any>) {
    if (!select) {
      return;
    }
    this.default = select.default ?? this.default;
    this.userId = select.userId ?? Math.floor(Math.random() * 100);
    this.email = select.email ?? this.email;
  }
}

export interface IUser {
  id: number;

  uuid: string;

  email: string | null;

  firstName: string | null;

  lastName: string | null;

  middleName: string | null;

  phone: string | null;
}

export class User implements IUser {
  id: number;

  uuid: string;

  email: string | null;

  firstName: string | null;

  lastName: string | null;

  middleName: string | null;

  phone: string | null;

  get fullName() {
    return `${this.firstName}${this.middleName ? ` ${this.middleName}` : ''} ${this.lastName}`;
  }

  set fullName(fullName: string) {
    if (!fullName.length) {
      return;
    }

    const [firstName, lastName] = fullName.split(' ');
    if (lastName) {
      this.firstName = firstName;
      this.lastName = lastName;
    } else {
      this.firstName = firstName;
    }
  }

  constructor(options?: Partial<IUser>) {
    this.id = options?.id || 0;
    this.uuid = options?.uuid ?? '';
    this.email = options?.email ?? '';
    this.firstName = options?.firstName || '';
    this.middleName = options?.middleName || '';
    this.lastName = options?.lastName || '';
    this.phone = options?.phone ?? '';
  }
}

import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { storeToRefs } from 'pinia';
import pinia, {
  useEventStore,
  useItemsStore,
  usePermissionsStore,
  useProductStore,
  useUserConfigStore,
  useUserStore,
  useNavigationStore
} from '@/store';
import Home from '@/views/Home.vue';
import { translate, SUPPORTED_LANGUAGE_CODES } from '@/localization/i18n';

Vue.use(VueRouter);

const gearRoutesAvailable = async () => {
  const permissionStore = usePermissionsStore();
  const { canReadWriteGear } = storeToRefs(permissionStore);
  const showGear = await permissionStore.isFeatureOn('show_gear');
  return showGear && canReadWriteGear.value;
};

const beforeWorkforceEnter = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const navigationStore = useNavigationStore();
  const { workforceServer } = storeToRefs(navigationStore);

  window.location.href = `${navigationStore.updateSubdomain(workforceServer.value)}${to.fullPath.replace(
    '/workforce',
    ''
  )}`;
  navigationStore.setNavigationInProgress(false);
};

const beforeEventEnter = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const eventStore = useEventStore();
  const permissionsStore = usePermissionsStore();
  const { features } = storeToRefs(permissionsStore);

  await eventStore.setActiveEvent(to.params.eventCode);
  const { activeEvent } = storeToRefs(eventStore);

  if (to.name?.match(/^event-projects-*/) && !features.value.projectManagementFeature) {
    next('/');
  }

  if (to.name === 'event-proposal' && !features.value.proposalsFeature) {
    next('/');
  }

  if (to.name === 'event-gear' && !features.value.inventoryFeature) {
    next('/');
  }

  if (to.name === 'event-transport' && !features.value.logisticsFeature) {
    next('/');
  }

  if (!activeEvent.value) {
    next({
      name: 'not-found',
      params: {
        msg: `Event ${to.params.eventCode} Not Found`
        // pathMatch: to.path.split('/').slice(1).join('/')
      },
      query: to.query,
      hash: to.hash
    });
  }
  next();
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/sales',
    name: 'sales',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sales" */ '@/views/Sales.vue')
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/Inventory.vue'),
    beforeEnter: async (to, from, next) => {
      if (!(await gearRoutesAvailable())) {
        next('/');
      }

      const productStore = useProductStore();
      const userConfigStore = useUserConfigStore();
      const itemsStore = useItemsStore();
      userConfigStore.initInventoryProductTableColumns();
      userConfigStore.initInventorySerializedItemColumns();
      await productStore.getProducts();
      await productStore.getProductTypes();
      await itemsStore.getItemStatusTypes();
      if (typeof to.query.editProduct === 'string') {
        const productUuid = to.query.editProduct;
        await productStore.setActiveProduct(productUuid);
      }
      next();
    }
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('@/views/Warehouse.vue'),
    beforeEnter: async (to, from, next) => {
      if (!(await gearRoutesAvailable())) {
        next('/');
      }
      next();
    }
  },
  {
    path: '/warehouse/packing/:equipmentListUuid',
    name: 'packing',
    component: () => import('@/views/WarehousePacking.vue'),
    beforeEnter: async (to, from, next) => {
      if (!(await gearRoutesAvailable())) {
        next('/');
      }
      next();
    }
  },
  {
    path: '/admin-settings',
    name: 'admin-settings',
    component: () => import(/* webpackChunkName: "admin-settings" */ '@/views/AdminSettings.vue')
  },
  {
    path: '/company/gear',
    name: 'company-settings',
    component: () => import('@/views/CompanySettings.vue')
  },
  {
    path: '/events/:eventCode/gear',
    name: 'event-gear',
    component: () => import('@/views/event/EventGear.vue'),
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/quotes',
    name: 'event-quotes',
    component: () => import('@/views/event/EventQuotes.vue'),
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/projects/tasks',
    name: 'event-projects-tasks',
    component: () => import('@/views/event/EventProject.vue'),
    props: {
      activeTab: 'tasks'
    },
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/projects/gantt-chart',
    name: 'event-projects-gantt-chart',
    component: () => import('@/views/event/EventProject.vue'),
    props: {
      activeTab: 'ganttChart'
    },
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/projects/calendar',
    name: 'event-projects-calendar',
    component: () => import('@/views/event/EventProject.vue'),
    props: {
      activeTab: 'calendar'
    },
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/transport',
    name: 'event-transport',
    component: () => import('@/views/event/EventTransport.vue'),
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/purchase-orders',
    name: 'event-purchase-orders',
    component: () => import('@/views/event/EventPurchaseOrders.vue'),
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/proposal',
    name: 'event-proposal',
    component: () => import('@/views/event/EventProposal.vue'),
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/events/:eventCode/projects/attachments',
    name: 'event-projects-attachments',
    component: () => import('@/views/event/EventProject.vue'),
    props: {
      activeTab: 'attachments'
    },
    beforeEnter: beforeEventEnter,
    meta: {
      eventPage: true
    }
  },
  {
    path: '/pm/user/dashboard/',
    name: 'guest-user-dashboard',
    component: () => import('@/views/GuestView.vue')
  },
  // Workforce Server paths....
  {
    path: '/workforce/*',
    beforeEnter: beforeWorkforceEnter
  },
  // Not-Found
  {
    path: '/:pathMatch(.*)*',
    props: true,
    name: 'not-found',
    component: () => import('@/views/PageNotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  const navigationStore = useNavigationStore(pinia);
  navigationStore.setNavigationInProgress(true);
  const supportedPreferredLanguages = navigator.languages.filter((lang) => SUPPORTED_LANGUAGE_CODES.includes(lang));
  if (supportedPreferredLanguages.length) {
    const preferredLanguage = supportedPreferredLanguages[0];
    await translate(preferredLanguage);
  }

  // load permissions if not loaded yet...
  const permissionsStore = usePermissionsStore(pinia);
  if (!permissionsStore.permissions.length) {
    await permissionsStore.loadPermissions();
  }

  const userStore = useUserStore(pinia);
  if (userStore.self.id === 0) {
    await userStore.loadSelf();
  }

  next();
});

router.afterEach(() => {
  const navigationStore = useNavigationStore(pinia);
  navigationStore.setNavigationInProgress(false);
});

export default router;

import { parseISO } from 'date-fns';
import { ICountry } from '@/types/core/geographical';
import { IAccount } from '@/types/core/account';
import { IVenue, IVenueContact, Venue, VenueContact } from '@/types/events/venue';

export interface IClient {
  id: number;

  uuid: string;

  dbDateCreated: string;

  dbDateUpdated: string;

  dbDateRemoved?: string;

  code: string;

  name: string;

  status: 'active' | 'inactive' | 'deleted';

  phone: string;

  accountId: number; // accountId should be the same

  country?: Partial<ICountry>; // don't reference the countryId since they may be different in our DB
}

export interface IClientContact {
  id: number;

  uuid: string;

  accountId: number;

  account?: Partial<IAccount>;

  clientId: number;

  client?: Partial<IClient>;

  companyName?: string;

  email?: string;

  extension?: string;

  fax?: string;

  firstName?: string;

  jobTitle?: string;

  lastName?: string;

  mobile?: string;

  phone?: string;

  status?: string;

  type?: string;

  dbDateCreated?: Date;

  dbDateUpdated?: Date;

  dbDateRemoved?: Date;
}

export interface IEvent {
  id: number;

  uuid: string;

  dbDateRemoved?: Date;

  dateBegin: string;

  dateEnd: string;

  dateLoadIn?: string;

  dateLoadOut?: string;

  dateDeadline?: string;

  dateArchived?: string;

  dateChanged?: string;

  code: string;

  externalCode?: string;

  name: string;

  status: string;

  description?: string;

  localOnly?: boolean;

  positionsNeeded?: number;

  positionsOpen?: number;

  positionsFilled?: number;

  positionsOverbooked?: number;

  totalHours?: number;

  accountId: number; // accountId should be the same in our DB

  account?: Partial<IAccount>;

  po: string;

  clientId: number; // clientId from upstream/workforce

  client?: Partial<IClient>;

  currency?: string;

  venueId?: number;

  venue?: IVenue;

  subVenueId?: number;

  subVenue?: Partial<IVenue>;

  clientPrimaryContactId?: number | null;

  clientPrimaryContact?: Partial<IClientContact>;

  venuePrimarycontactId?: number;

  venuePrimaryContact?: Partial<IVenueContact>;
}

export class Event {
  id: number;

  uuid: string;

  dbDateRemoved?: Date;

  dateBegin: Date;

  dateEnd: Date;

  dateLoadIn?: Date;

  dateLoadOut?: Date;

  dateDeadline?: Date;

  dateArchived?: Date;

  dateChanged?: Date;

  code: string;

  externalCode: string;

  name: string;

  status: string;

  description: string;

  localOnly: boolean;

  positionsNeeded: number;

  positionsOpen: number;

  positionsFilled: number;

  positionsOverbooked: number;

  totalHours: number;

  accountId: number; // accountId should be the same in our DB

  account?: Partial<IAccount>;

  po: string;

  clientId: number; // clientId from upstream/workforce

  client?: Partial<IClient>;

  currency?: string;

  venueId?: number;

  venue?: Venue;

  subVenueId?: number;

  subVenue?: Venue;

  clientPrimaryContactId?: number | null;

  clientPrimaryContact?: Partial<IClientContact>;

  venuePrimarycontactId?: number;

  venuePrimaryContact?: IVenueContact;

  constructor(options?: Partial<IEvent>) {
    this.id = options?.id ?? 0;
    this.uuid = options?.uuid ?? '';
    this.dbDateRemoved = options?.dbDateRemoved;
    this.dateBegin = options?.dateBegin ? parseISO(options.dateBegin) : new Date();
    this.dateEnd = options?.dateEnd ? parseISO(options.dateEnd) : new Date();
    this.dateLoadIn = options?.dateLoadIn ? new Date(options.dateLoadIn) : undefined;
    this.dateLoadOut = options?.dateLoadOut ? new Date(options.dateLoadOut) : undefined;
    this.dateDeadline = options?.dateDeadline ? new Date(options.dateDeadline) : undefined;
    this.dateArchived = options?.dateArchived ? new Date(options.dateArchived) : undefined;
    this.dateChanged = options?.dateChanged ? new Date(options.dateChanged) : undefined;
    this.code = options?.code ?? '';
    this.externalCode = options?.externalCode ?? '';
    this.name = options?.name ?? '';
    this.status = options?.status ?? 'active';
    this.description = options?.description ?? '';
    this.localOnly = options?.localOnly ?? false;
    this.positionsFilled = options?.positionsFilled ?? 0;
    this.positionsNeeded = options?.positionsNeeded ?? 0;
    this.positionsOpen = options?.positionsOpen ?? 0;
    this.positionsOverbooked = options?.positionsOverbooked ?? 0;
    this.totalHours = options?.totalHours ?? 0;
    this.accountId = options?.accountId ?? options?.account?.id ?? 0;
    this.account = options?.account;
    this.po = options?.po ?? '';
    this.clientId = options?.clientId ?? options?.client?.id ?? 0;
    this.client = options?.client;
    this.currency = options?.currency;
    this.venueId = options?.venueId;
    this.venue = options?.venue || options?.venueId ? new Venue(options.venue ?? { id: options.venueId }) : undefined;
    this.subVenueId = options?.subVenueId;
    this.subVenue =
      options?.subVenue || options?.subVenueId ? new Venue(options.subVenue ?? { id: options.subVenueId }) : undefined;
    this.clientPrimaryContactId = options?.clientPrimaryContactId;
    this.clientPrimaryContact = options?.clientPrimaryContact;
    this.venuePrimarycontactId = options?.venuePrimarycontactId;
    this.venuePrimaryContact = new VenueContact(options?.venuePrimaryContact);
  }
}

import Actions, { IActions } from './actions';

export interface IPermission {
  object: string;
  actions: IActions;
}

export default class Permission implements IPermission {
  object = '';

  actions = new Actions();

  constructor(select?: Record<string, any>) {
    if (!select) {
      return;
    }

    this.object = select.object ?? this.object;
    this.actions = select.actions ? new Actions(select.actions) : this.actions;
  }
}


import { defineComponent, ref, watch, onBeforeMount, onMounted, onBeforeUnmount, provide } from 'vue';
import { useRoute } from 'vue-composition-wrapper';
import { storeToRefs } from 'pinia';
import { v4 as uuid } from 'uuid';
import { useI18n } from 'vue-i18n-composable';
import { LProjectTimerNav } from '@lassoworkforce/lasso-vue-styles';
import axios from 'axios';
import {
  useNavigationStore,
  useChatStore,
  usePermissionsStore,
  useUserStore,
  useNotificationsStore
} from '@/store';
import SearchNav from '@/components/Nav/SearchNav.vue';
import CrewNavMenu from '@/components/Nav/CrewNavMenu.vue';
import EventsNavMenu from '@/components/Nav/EventsNavMenu.vue';
import SchedulesNavMenu from '@/components/Nav/SchedulesNavMenu.vue';
import TasksNavMenu from '@/components/Nav/TasksNavMenu.vue';
import AdminNavMenu from '@/components/Nav/AdminNavMenu.vue';
import UserNavMenu from '@/components/Nav/UserNavMenu.vue';
import Inbox from '@/components/Chat/Inbox.vue';
import HelpNavMenu from '@/components/Nav/HelpNavMenu.vue';
import permissions from '@/store/permissions';
import SearchSelect from '@/components/Common/SearchSelect.vue';
import { LIconMarketplace } from '@/components/Icons/NavIcons';
import NotificationsSidePanel from '@/components/Common/Notifications/NotificationsSidePanel.vue';
import { IUser, User } from '@/types/core';
import bus from './navBus';
import { formatDate } from '@/localization/i18n';

export default defineComponent({
  name: 'GlobalNav',
  methods: { permissions },
  components: {
    AdminNavMenu,
    TasksNavMenu,
    SchedulesNavMenu,
    EventsNavMenu,
    SearchNav,
    CrewNavMenu,
    UserNavMenu,
    Inbox,
    SearchSelect,
    HelpNavMenu,
    LIconMarketplace,
    NotificationsSidePanel,
    LProjectTimerNav,
    // LNotificationsNav
  },
  props: {
    logoUrl: {
      type: String,
      default: null
    },
    showTimeTrack: Boolean
  },
  setup() {
    // provides for subcomponents
    provide("axios", axios);
    provide("bus", bus);

    const route = useRoute();
    const navigationStore = useNavigationStore();
    const chatStore = useChatStore();
    const permissionsStore = usePermissionsStore();
    const userStore = useUserStore();
    const notificationsStore = useNotificationsStore();

    const { navSettings } = storeToRefs(navigationStore);
    const { showInboxDropdown, showNewMsgPopover } = storeToRefs(chatStore);
    const { canReadWriteChat, canReadWriteGear, features } = storeToRefs(permissionsStore);
    const { marketplacePageAllowed } = permissionsStore;
    const { unreadUserNotificationCount } = storeToRefs(notificationsStore);
    const { t } = useI18n();

    const onGearTab = ref<boolean>(false);
    const imageLoadError = ref<boolean>(false);
    const defaultSearchType = ref<string>('events');
    const recentEvents = ref<any[]>([]);
    const unreadMessageCount = ref<number>(0);

    // flag to reload time history
    const reloadHistory = ref<boolean>(false);

    // timer info
    const timerRunning = ref<boolean>(false);

    const newEvent = () => {
      window.location.replace(navigationStore.getSubdomainUrl(navSettings.value.events.new_event));
    };

    const dmConversationSubject = ref<string | undefined>(undefined);
    const dmChatErrorMessage = ref<string | undefined>(undefined);
    const newMessageLoader = ref(false);
    const inboxRef = ref<InstanceType<typeof Inbox> | undefined>(undefined);
    const searchUsers = async (text?: string) => {
      const { rows: users } = await userStore.searchUsers(text ?? '');
      return users;
    };
    const selectedUsersForDMChat = ref<IUser[]>([]);
    const handleCancelDirectMessage = () => {
      showNewMsgPopover.value = false;
      dmChatErrorMessage.value = '';
      dmConversationSubject.value = '';
      selectedUsersForDMChat.value = [];
    };

    const startDirectMessage = async () => {
      newMessageLoader.value = true;
      dmChatErrorMessage.value = '';
      const conversationId = uuid();
      try {
        if (!dmConversationSubject.value) {
          throw new Error('Message must have subject');
        }

        await chatStore.startConversation({
          subject: dmConversationSubject.value,
          conversationId,
          participants: selectedUsersForDMChat.value.map((u) => u.uuid)
        });

        inboxRef.value?.chatInboxRef?.inboxRef?.select(conversationId);

        handleCancelDirectMessage();
      } catch (error: unknown) {
        console.error(error);
        dmChatErrorMessage.value = t('Chat.NewMsg.ErrorStartingConversation').toString();
      }
      newMessageLoader.value = false;
    };

    const handleSearchUserModelChange = (value: User[]) => {
      selectedUsersForDMChat.value = value;
    };

    const hasFeature = async (key: string) => {
      const flg = await permissionsStore.isFeatureOn(key);
      return flg;
    };

    const handleUnreadCountChange = (unreadCount: number) => {
      unreadMessageCount.value = unreadCount;
    };

    watch(route, (newRoute) => {
      const { path } = newRoute;
      switch (path) {
        case '/inventory':
        case '/sales':
        case '/warehouse':
        case '/chat':
        case '/admin-settings':
          onGearTab.value = true;
          break;
        default:
          onGearTab.value = false;
          break;
      }
    });

    // Allow Project Timer component(s) to communicate to global nav:
    const projectTimeEventHandler = async (msgData: any) => {
      const { msg } = msgData;

      if (msg === 'startTimer') {
        timerRunning.value = true;
      }

      if (msg === 'stopTimer') {
        timerRunning.value = false;
        reloadHistory.value = true;
      }

      if (msg === 'logtime') {
        reloadHistory.value = true;
      }

      if (msg === "refreshHistoryDone") {
        reloadHistory.value = false;
      }
    };

    const showNotifications = (e: Event) => {
      e.preventDefault();
      notificationsStore.setNotificationsPanelVisibility(true);
    };

    // attach handler to vue event bus
    onBeforeMount(() => {
      bus.$on('project-timer-msg', projectTimeEventHandler);
    });

    onBeforeUnmount(() => {
      bus.$off('project-timer-msg', projectTimeEventHandler);
    });

    onMounted(() => {
      notificationsStore.autoFetchNotifications();
    });

    return {
      formatDate,
      hasFeature,
      onGearTab,
      navSettings,
      newEvent,
      imageLoadError,
      defaultSearchType,
      features,
      showInboxDropdown,
      showNewMsgPopover,
      canReadWriteChat,
      canReadWriteGear,
      marketplacePageAllowed,
      recentEvents,
      searchUsers,
      selectedUsersForDMChat,
      startDirectMessage,
      handleSearchUserModelChange,
      inboxRef,
      dmChatErrorMessage,
      dmConversationSubject,
      handleCancelDirectMessage,
      newMessageLoader,
      reloadHistory,
      timerRunning,
      handleUnreadCountChange,
      unreadMessageCount,
      unreadUserNotificationCount,
      showNotifications,
      labels: {
        buttonLabel: t('Event.Project.TimeTracking.History'),
        eventColHeader: t('Event.Project.Labels.EventTask'),
        timeColHeader: t('Event.Project.Labels.Time'),
        dateColHeader: t('Event.Project.Labels.Date'),
        noHistory: t('Event.Project.NoTimeHistory'),
        timeInput: t('Event.Project.TimeTracking.Time'),
        logTime: t('Event.Project.TimeTracking.LogTime'),
        eventTaskLabel: t('Event.Project.TimeTracking.EventTask'),
        startLabel: t('CommonButtons.Actions.Start'),
        stopLabel: t('CommonButtons.Actions.Stop'),
        tab1Title: t('Event.Project.TimeTracking.LogTime'),
        tab2Title: t('Event.Project.TimeTracking.UseTimer')
      },
    };
  }
});

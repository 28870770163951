
import Talk from 'talkjs';
import { defineComponent, onMounted, PropType, watch, ref } from 'vue';

export default defineComponent({
  name: 'ChatInbox',
  props: {
    elementId: {
      type: String as PropType<string>,
      default: () => 'inbox'
    },
    inboxClass: {
      type: String as PropType<string>,
      default: () => 'w-100'
    },
    activeConversationId: {
      type: String as PropType<string>
    },
    userId: {
      type: String as PropType<string>,
      required: true
    },
    userSignature: {
      type: String as PropType<string>,
      required: true
    },
    loadingText: {
      type: String as PropType<string>,
      default: () => 'Loading...'
    },
    theme: {
      type: String as PropType<string>,
      default: 'default'
    },
    accountSlug: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup: (props, { emit }) => {
    const inboxRef = ref<Talk.Inbox | null>(null);

    const initInbox = () => {
      const user = new Talk.User(props.userId);
      const session = new Talk.Session({
        appId: process.env.VUE_APP_TALKJS_APP_ID,
        me: user,
        signature: props.userSignature
      });

      session.unreads.onChange((messages) => {
        const countForThisAccount = messages.filter(
          (m) => m.lastMessage?.conversation?.custom?.account === props.accountSlug
        ).length;
        emit('unread-count-change', countForThisAccount);
      });

      inboxRef.value = session.createInbox({
        theme: props.theme,
        feedFilter: {
          custom: {
            account: ['==', props.accountSlug]
          }
        },
        ...(props.activeConversationId && { selected: props.activeConversationId })
      });
      inboxRef.value.mount(document.getElementById(props.elementId));
    };

    onMounted(() => {
      initInbox();
    });

    watch(
      () => props.activeConversationId,
      (newConversationId) => {
        inboxRef.value?.select(newConversationId);
      }
    );

    return {
      inboxRef
    };
  },
  async mounted() {
    await Talk.ready;
  }
});

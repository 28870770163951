
import { defineComponent, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-composition-wrapper';
import { ROUTE_NAME_EVENTS_LIST } from '@/constants';
import { useEventStore } from '@/store';

interface SearchOptionType {
  value: string;
  text: string;
  placeholder: string;
}

export default defineComponent({
  name: 'SearchNav',
  props: {
    userIsLasso: Boolean,
    vendorsFeature: Boolean,
    defaultSearchType: String
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const eventOption = {
      value: 'event',
      text: 'Nav.Search.Event',
      placeholder: 'Nav.Search.EventPlaceholder'
    };
    const searchOptions = computed(() => {
      const options = [
        eventOption,
        { value: 'crew', text: 'Nav.Search.Crew', placeholder: 'Nav.Search.CrewPlaceholder' },
        { value: 'client', text: 'Nav.Search.Client', placeholder: 'Nav.Search.ClientPlaceholder' },
        { value: 'venue', text: 'Nav.Search.Venue', placeholder: 'Nav.Search.VenuePlaceholder' }
      ];
      if (props.vendorsFeature || props.defaultSearchType === 'vendor') {
        options.push({
          value: 'vendor',
          text: 'Nav.Search.Vendor',
          placeholder: 'Nav.Search.VendorPlaceholder'
        });
      }
      if (props.userIsLasso) {
        options.push({
          value: 'staff',
          text: 'Nav.Search.Account',
          placeholder: 'Nav.Search.AccountPlaceholder'
        });
      }
      return options;
    });
    const searchTypeInitialChoice = props.defaultSearchType
      ? searchOptions.value.find((opt) => opt.value === props.defaultSearchType) || eventOption
      : eventOption;
    const searchType = ref(searchTypeInitialChoice.value);
    const searchTerm = ref<string>('');
    const placeholder = ref(searchTypeInitialChoice.placeholder);

    function setSearchType(option: SearchOptionType) {
      placeholder.value = option.placeholder;
      searchType.value = option.value;
      searchTerm.value = '';
    }

    function searchPath() {
      switch (searchType.value) {
        case 'staff':
          return 'workforce/staff/search';
        case 'crew':
          return `workforce/${searchType.value}`;
        case 'event':
          return 'workforce/next/events';
        default:
          return `workforce/${searchType.value}s`;
      }
    }

    const submitSearch = () => {
      const currSearchTerm = searchTerm.value;
      searchTerm.value = '';
      if (searchType.value === 'event') {
        if (route.value.name === ROUTE_NAME_EVENTS_LIST) {
          const eventStore = useEventStore();
          eventStore.navSearchTerm = currSearchTerm;
        } else {
          router.push({
            path: '/workforce/next/events',
            query: { q: currSearchTerm },
            replace: true
          });
        }
      } else {
        window.location.href = `${window.location.origin}/${searchPath()}?query_type=${
          searchType.value
        }&q=${currSearchTerm}`;
      }
    };

    return {
      searchOptions,
      searchType,
      searchTerm,
      searchPath,
      placeholder,
      setSearchType,
      submitSearch
    };
  }
});

import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { LToast } from '@lassoworkforce/lasso-vue-styles';
import { useI18n } from 'vue-i18n-composable';
import { axiosInventory } from '../services/httpService';
// eslint-disable-next-line import/no-cycle
import { useEventStore } from '@/store';
import {
  EquipmentList,
  EquipmentListLineItem,
  IEquipmentList,
  IEquipmentListGroup,
  IEquipmentListLineItem,
  IListEquipmentListGroupsResponse,
  IListEquipmentListLineItemsResponse,
  IListEquipmentListsResponse,
  IUpdateEquipmentListLineItemResponse,
  EquipmentListGroup,
  IEquipmentListAggregateData,
  EquipmentListAggregateData,
  IAccountEventStaus
} from '@/types/events/equipmentList';
import { IErrorAPIResponse, IPaginatedResponse } from '@/types/api/common';
import type { LassoGrid } from '@/components/Common/LassoGrid';

interface IListAccountEventStatuses extends IPaginatedResponse {
  rows: IAccountEventStaus[];
}

interface IQueryLineItemsResponse extends IListEquipmentListLineItemsResponse {
  rows: EquipmentListLineItem[];
}
const useEquipmentListStore = defineStore('EquipmentListStore', () => {
  // nested store
  const eventStore = useEventStore();
  const { activeEvent } = storeToRefs(eventStore);

  // state
  const { t, tc } = useI18n();
  const activeEquipmentList = ref<EquipmentList>(new EquipmentList());
  const equipmentListLineItemsCache = ref<EquipmentListLineItem[]>([]);
  const activeEquipmentListGroups = ref<IEquipmentListGroup[]>([]);
  const equipmentListsInEvent = ref<EquipmentList[]>([]);
  const groupsOpen = ref<{ [groupId: number]: boolean }>({});
  const equipmentListStatuses = ref<IAccountEventStaus[]>([]);
  const showAggregateSidePanel = ref<boolean>(false);
  const activeEventAggregateEquipmentmentData = ref<IEquipmentListAggregateData | null>(null);
  const expandedCompositeLineItemsInGearLists = ref<{ [lineItemId: number]: boolean }>({});
  const equipmentListGridRef = ref<InstanceType<typeof LassoGrid>>();

  const showActiveEquipmentListDetails = ref<boolean>(false);
  const equipmentListToastProps = ref({
    type: '',
    title: '',
    message: '',
    delay: ''
  });
  const equipmentListToastRef = ref<InstanceType<typeof LToast> | null>(null);
  const showSearchProductsPanel = ref<boolean>(false);

  // actions
  const getEquipmentListsForEvent = async (
    aggregateLists: EquipmentList[] = [],
    page = 0
  ): Promise<EquipmentList[]> => {
    if (!activeEvent.value || !activeEvent.value.code) {
      equipmentListsInEvent.value = [];
      return equipmentListsInEvent.value;
    }

    /* Get all equipment lists for drop down, instead of waiting to paginate */
    const { data }: { data: IListEquipmentListsResponse } = await axiosInventory.get(
      `/events/${activeEvent.value.code}/equipment_lists?page=${page}`
    );
    const allLists = aggregateLists.concat(data.rows.map((r) => new EquipmentList(r)));
    if (data.total === aggregateLists.length || data.rows.length === 0) {
      equipmentListsInEvent.value = allLists;
      return equipmentListsInEvent.value;
    }

    const nextPage = page + 1;
    return getEquipmentListsForEvent(allLists, nextPage);
  };

  const handleEquipmentListErrorToast = (error: any, defaultMessage?: string) => {
    console.error(error);
    const errResponse: IErrorAPIResponse = error;
    equipmentListToastProps.value.type = 'error';
    equipmentListToastProps.value.title = t('CommonResponses.Error').toString();
    equipmentListToastProps.value.message =
      errResponse.message ?? defaultMessage ?? t('CommonResponses.UnknownIssue').toString();
    equipmentListToastRef.value?.show();
  };

  /**
   *
   * @param size page size
   * @param page current page
   * @param equipmentListIds optional filter of equipment list ids
   * @param equipmentListGroupIds optional filter for group ids
   * @param includeNullGroupAssignments set to true to include ungrouped items in the response, even with an equipmentListGroupIds array filter
   * @param equipmentListLineItemIds optional list of line item ids to filter on
   * @returns Paginated response of equipment list items
   */
  const queryLineItems = async (
    size: number,
    page: number,
    equipmentListIds: number[] = [],
    equipmentListGroupIds: number[] = [],
    includeNullGroupAssignments = false,
    equipmentListLineItemIds: number[] = []
  ): Promise<IQueryLineItemsResponse> => {
    try {
      const { data }: { data: IListEquipmentListLineItemsResponse } = await axiosInventory.post(
        `/events/${activeEvent.value?.code}/equipment_lists/query_line_items?page=${page}&size=${size}`,
        {
          equipmentListIds,
          equipmentListGroupIds,
          includeNullGroupAssignments,
          equipmentListLineItemIds
        }
      );
      const shapedLineItems = data.rows.map((i) => new EquipmentListLineItem(i));
      // eslint-disable-next-line no-use-before-define
      await Promise.all(shapedLineItems.map(cacheEquipmentListLineItem));

      return {
        ...data,
        rows: shapedLineItems
      };
    } catch (error: any) {
      handleEquipmentListErrorToast(error);
    }
    return {
      total: 0,
      totalPages: 0,
      currentPage: 1,
      rows: []
    };
  };

  const getEquipmentListGroups = async (
    equipmentListId: number,
    page = 0,
    size = 100
  ): Promise<IListEquipmentListGroupsResponse> => {
    if (page === 0) {
      activeEquipmentListGroups.value = [];
    }
    if (!activeEvent.value || equipmentListId < 1) {
      return {
        total: 0,
        totalPages: 1,
        currentPage: 0,
        rows: []
      };
    }

    const { data }: { data: IListEquipmentListGroupsResponse } = await axiosInventory.get(
      `/events/${activeEvent.value.code}/equipment_lists/${equipmentListId}/groups?page=${page}&size=${size}`
    );

    activeEquipmentListGroups.value = activeEquipmentListGroups.value.concat(
      data.rows.map((r) => new EquipmentListGroup(r))
    );
    return data;
  };

  const getEquipmentListGroupLineItems = async (
    equipmentListId: number,
    equipmentListGroupId: number,
    page = 0,
    size = 25
  ): Promise<IListEquipmentListLineItemsResponse> => {
    if (!activeEvent.value || equipmentListId < 1 || equipmentListGroupId < 1) {
      return {
        total: 0,
        totalPages: 0,
        rows: [],
        currentPage: 0
      };
    }

    const { data }: { data: IListEquipmentListLineItemsResponse } = await axiosInventory.get(
      `/events/${activeEvent.value.code}/equipment_lists/${equipmentListId}/groups/${equipmentListGroupId}/line_items?page=${page}&size=${size}`
    );

    const { total, totalPages, rows, currentPage } = data;

    return {
      total,
      totalPages,
      currentPage,
      rows: rows.map((item) => new EquipmentListLineItem(item))
    };
  };

  const getEquipmentListDetails = async (equipmentListId: number) => {
    if (!activeEvent.value) {
      return null;
    }

    const { data }: { data: IEquipmentList } = await axiosInventory.get(
      `/events/${activeEvent.value.code}/equipment_lists/${equipmentListId}`
    );

    return data;
  };

  const setActiveEquipmentList = async (equipmentListId: number) => {
    if (!activeEvent.value) {
      return null;
    }
    try {
      const equipmentList = await getEquipmentListDetails(equipmentListId);

      activeEquipmentList.value = new EquipmentList({ ...equipmentList });
      return null;
    } catch (error: any) {
      handleEquipmentListErrorToast(error);
      return null;
    }
  };

  const addNewEquipmentList = async (list: EquipmentList) => {
    const { data }: { data: IEquipmentList } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/equipment_lists`,
      list
    );
    const newList = new EquipmentList(data);
    equipmentListsInEvent.value.push(newList);
    await setActiveEquipmentList(data.id);
    return newList;
  };

  const updateEquipmentList = async (list: EquipmentList) => {
    try {
      await axiosInventory.put(`/events/${activeEvent.value?.code}/equipment_lists/${list.id}`, list);
      await equipmentListGridRef.value?.refresh(true);
    } catch (error: any) {
      handleEquipmentListErrorToast(error);
    }
  };

  const updateEquipmentListGroup = async (group: IEquipmentListGroup) => {
    try {
      await axiosInventory.put(
        `/events/${activeEvent.value?.code}/equipment_lists/${activeEquipmentList.value.id}/groups/${group.id}`,
        group
      );
    } catch (error: any) {
      handleEquipmentListErrorToast(error);
    }
  };

  const deleteEquipmentListGroup = async (group: IEquipmentListGroup) => {
    try {
      await axiosInventory.delete(
        `/events/${activeEvent.value?.code}/equipment_lists/${group.equipmentListId}/groups/${group.id}`
      );
      // eslint-disable-next-line no-use-before-define
      await getAggregateEquipmentListData();
      await equipmentListGridRef.value?.refresh(true);
    } catch (error) {
      handleEquipmentListErrorToast(error);
    }
  };

  const addEquipmentListGroup = async () => {
    const defaultName = t('Event.Equipment.Table.GroupN', {
      number: activeEquipmentListGroups.value.length + 1
    }).toString();
    const { data }: { data: IEquipmentListGroup } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/equipment_lists/${activeEquipmentList.value.id}/groups`,
      {
        name: defaultName
      }
    );
    activeEquipmentListGroups.value.unshift(data);
    equipmentListGridRef.value?.refresh();
  };

  const getAggregateEquipmentListData = async () => {
    try {
      const { data }: { data: IEquipmentListAggregateData } = await axiosInventory.get(
        `/events/${activeEvent.value?.code}/equipment_lists/aggregate`
      );
      activeEventAggregateEquipmentmentData.value = new EquipmentListAggregateData(data);
    } catch (error) {
      handleEquipmentListErrorToast(error);
    }
  };

  const cacheEquipmentListLineItem = (item: EquipmentListLineItem) =>
    new Promise((res) => {
      const foundCachedItem = equipmentListLineItemsCache.value.find((i) => i.id === item.id);
      if (!foundCachedItem) {
        equipmentListLineItemsCache.value.push(item);
      } else {
        const index = equipmentListLineItemsCache.value.findIndex((i) => i.id === item.id);
        equipmentListLineItemsCache.value.splice(index, 1, item);
      }
      res(true);
    });

  const addEquipmentListLineItem = async (lineItem: IEquipmentListLineItem) => {
    const { data }: { data: EquipmentListLineItem[] } = await axiosInventory.post(
      `/events/${activeEvent.value?.code}/equipment_lists/line_items`,
      lineItem
    );

    // re-calculate fields on the backend
    await eventStore.setActiveEvent(activeEvent.value?.code);
    await getAggregateEquipmentListData();
    // await setActiveEquipmentList(activeEquipmentList.value.id);
    await Promise.all(data.map(cacheEquipmentListLineItem));
    return data;
  };

  const updateEquipmentListLineItem = async (lineItem: IEquipmentListLineItem) => {
    if (lineItem.product && lineItem.product.id === 0) {
      console.warn('Not updating line item due to product ID 0 being set');
      const emptyResponse: IUpdateEquipmentListLineItemResponse = {
        lineItemsAdded: [],
        lineItemsUpdated: []
      };
      return emptyResponse;
    }

    try {
      const { data: response }: { data: IUpdateEquipmentListLineItemResponse } = await axiosInventory.put(
        `/events/${activeEvent.value?.code}/equipment_lists/line_items/${lineItem.id}`,
        lineItem
      );

      // re-calculate fields on the backend
      await eventStore.setActiveEvent(activeEvent.value?.code);
      await getAggregateEquipmentListData();
      // await setActiveEquipmentList(activeEquipmentList.value.id);
      const shapedResponse = {
        lineItemsAdded: response.lineItemsAdded.map((i) => new EquipmentListLineItem(i)),
        lineItemsUpdated: response.lineItemsUpdated.map((i) => new EquipmentListLineItem(i))
      };
      await Promise.all(
        shapedResponse.lineItemsAdded.concat(shapedResponse.lineItemsUpdated).map(cacheEquipmentListLineItem)
      );
      return shapedResponse;
    } catch (error: any) {
      handleEquipmentListErrorToast(
        error,
        t('Event.Equipment.ErrorSavingList', { name: tc('Event.Equipment.LineItem', 1) }).toString()
      );
      const emptyResponse: IUpdateEquipmentListLineItemResponse = {
        lineItemsAdded: [],
        lineItemsUpdated: []
      };

      return emptyResponse;
    }
  };

  const toggleShowActiveEquipmentListDetails = () => {
    showActiveEquipmentListDetails.value = !showActiveEquipmentListDetails.value;
    // any other logic that might need to happen
  };

  const deleteLineItem = async (lineItem?: IEquipmentListLineItem) => {
    try {
      await axiosInventory.delete(`/events/${activeEvent.value?.code}/equipment_lists/line_items/${lineItem?.id}`);

      const index = equipmentListLineItemsCache.value.findIndex((i) => i.id === lineItem?.id);
      if (index >= 0) {
        equipmentListLineItemsCache.value.splice(index, 1);
      }
      await getAggregateEquipmentListData();
      await equipmentListGridRef.value?.refresh();
    } catch (error: any) {
      handleEquipmentListErrorToast(error);
    }
  };

  const markGroupAsOpen = (groupId: number, open: boolean) => {
    groupsOpen.value = {
      ...groupsOpen.value,
      [groupId]: open
    };
  };

  // eslint-disable-next-line default-param-last
  const listEquipmentListStatuses = async (page = 0, size?: number) => {
    if (!equipmentListStatuses.value.length) {
      try {
        const { data }: { data: IListAccountEventStatuses } = await axiosInventory.get(
          `/events/${activeEvent.value?.code}/equipment_lists/statuses?page=${page}${size ? `&size=${size}` : ''}`
        );
        equipmentListStatuses.value = page > 0 ? equipmentListStatuses.value.concat(data.rows) : data.rows;
      } catch (error) {
        handleEquipmentListErrorToast(error);
      }
    }
    return equipmentListStatuses.value;
  };

  const toggleAggregateSidePanel = () => {
    showAggregateSidePanel.value = !showAggregateSidePanel.value;
  };

  const updateAggregateChargeTotals = async (update: Partial<IEquipmentListAggregateData>) => {
    if (!activeEventAggregateEquipmentmentData.value) return;
    try {
      await axiosInventory.put(`/events/${activeEvent.value?.code}/equipment_lists/aggregate`, update);
      await getAggregateEquipmentListData();
    } catch (error) {
      handleEquipmentListErrorToast(error);
    }
  };

  const getLineItemById = async (id: number) => {
    if (id === 0) return null;
    const itemInCache = equipmentListLineItemsCache.value.find((i) => i.id === id);
    if (itemInCache) {
      return itemInCache;
    }

    const items = await queryLineItems(1, 0, [], [], false, [id]);
    if (items.rows.length) {
      const item = items.rows.pop() ?? null;
      if (item) {
        await cacheEquipmentListLineItem(item);
        return item;
      }
    }
    return null;
  };

  return {
    getEquipmentListDetails,
    setActiveEquipmentList,
    activeEquipmentList,
    activeEquipmentListGroups,
    getEquipmentListsForEvent,
    getEquipmentListGroups,
    getEquipmentListGroupLineItems,
    equipmentListsInEvent,
    addNewEquipmentList,
    updateEquipmentList,
    updateEquipmentListGroup,
    addEquipmentListGroup,
    addEquipmentListLineItem,
    updateEquipmentListLineItem,
    showActiveEquipmentListDetails,
    toggleShowActiveEquipmentListDetails,
    equipmentListToastProps,
    equipmentListToastRef,
    queryLineItems,
    deleteLineItem,
    groupsOpen,
    markGroupAsOpen,
    listEquipmentListStatuses,
    equipmentListStatuses,
    showSearchProductsPanel,
    deleteEquipmentListGroup,
    showAggregateSidePanel,
    toggleAggregateSidePanel,
    activeEventAggregateEquipmentmentData,
    getAggregateEquipmentListData,
    updateAggregateChargeTotals,
    expandedCompositeLineItemsInGearLists,
    equipmentListGridRef,
    equipmentListLineItemsCache,
    getLineItemById
  };
});

export default useEquipmentListStore;

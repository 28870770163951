import { helpers } from '@vuelidate/validators';
import { PRODUCT_TYPE_CODE } from '@/constants';

// eslint-disable-next-line import/prefer-default-export
export const alphaNumPlusValidator = helpers.regex(/^[a-z\d-/]*$/i);

export const doesProductHaveChildSerializedItems = (productType: string) => {
  const acceptedProductTypes = [
    PRODUCT_TYPE_CODE.VIRTUAL,
    PRODUCT_TYPE_CODE.LOCKED_CONTAINER,
    PRODUCT_TYPE_CODE.UNLOCKED_CONTAINER
  ];
  return acceptedProductTypes.includes(productType);
};

export const doesProductHaveComponents = (productType: string) => {
  const acceptedProductTypes = [PRODUCT_TYPE_CODE.VIRTUAL, PRODUCT_TYPE_CODE.BUNDLE, PRODUCT_TYPE_CODE.PRICED_BUNDLE];
  return acceptedProductTypes.includes(productType);
};

export const isPlatformAMac = () => {
  return navigator.platform.indexOf('Mac') === 0 || navigator.platform === 'iPhone';
};

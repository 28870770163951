
import { defineComponent, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { BNavItemDropdown } from 'bootstrap-vue';
import { useNavigationStore } from '@/store';
import { LIconEvent } from '../Icons/NavIcons';

export default defineComponent({
  name: 'EventsNavMenu',
  components: {
    LIconEvent
  },
  props: {
    eventsModifyPermission: Boolean,
    programsFeature: Boolean
  },
  setup(props) {
    const eventNavDropdown = ref<BNavItemDropdown | null>(null);

    const navigationStore = useNavigationStore();
    const { navSettings } = storeToRefs(navigationStore);

    const toursLink = computed(() =>
      props.programsFeature ? navSettings.value.events.tours : navSettings.value.events.tours_help
    );

    const onNewEventClick = () => {
      window.location.replace(navigationStore.getSubdomainUrl(navSettings.value.events.new_event));
    };

    return {
      eventNavDropdown,
      navSettings,
      toursLink,
      onNewEventClick
    };
  }
});


import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from '@/store';
import NotificationCard from '@/components/Common/Notifications/NotificationCard.vue';
import { IUserChangeFeed } from '@/types/notifications';

interface INotificationFilter {
  status: 'read' | 'unread' | undefined;
  assigned: boolean | undefined;
  watchChanged: boolean | undefined;
}

export default defineComponent({
  name: 'NotificationsSidePanel',
  components: {
    NotificationCard
  },
  setup() {
    const notificationsStore = useNotificationsStore();
    const { showNotificationsSidePanel, userNotifications, fetchNotificationsInProgress } =
      storeToRefs(notificationsStore);

    const searchString = ref<string>();
    const searchTimeoutPid = ref<number>();
    const showFilters = ref<boolean>(false);
    const filters = ref<INotificationFilter>({
      status: undefined,
      assigned: undefined,
      watchChanged: undefined
    });

    const hideNotificationsPanel = (e: Event) => {
      e.preventDefault();
      notificationsStore.setNotificationsPanelVisibility(false);
    };

    const search = () => {
      if (searchTimeoutPid.value) {
        clearTimeout(searchTimeoutPid.value);
      }

      searchTimeoutPid.value = setTimeout(async () => {
        try {
          await notificationsStore.queryNotifications({
            ...(searchString.value && { text: searchString.value }),
            ...(filters.value.status && { status: filters.value.status }),
            ...(filters.value.watchChanged && {
              metadataFields: ['watchingUsers']
            })
          });
        } catch (error) {
          console.error(error);
        }
      }, 500);
    };

    const setFilter = (filterSettings: Partial<INotificationFilter>) => {
      if (!Object.keys(filterSettings).length) {
        filters.value = {
          status: undefined,
          assigned: undefined,
          watchChanged: undefined
        };
      }

      filters.value = { ...filters.value, ...filterSettings };
      return search();
    };

    onBeforeMount(() => {
      search();
    });

    watch(
      () => searchString.value,
      () => search()
    );

    const handleMarkAsRead = async (userChangeFeed: IUserChangeFeed) => {
      await notificationsStore.markNotificationsAsRead([userChangeFeed]);
    };

    const handleDelete = async (userChangeFeed: IUserChangeFeed) => {
      await notificationsStore.deleteNotifications([userChangeFeed]);
      userNotifications.value = userNotifications.value.filter((n) => n.id !== userChangeFeed.id);
    };

    return {
      showNotificationsSidePanel,
      hideNotificationsPanel,
      userNotifications,
      fetchNotificationsInProgress,
      handleMarkAsRead,
      handleDelete,
      searchString,
      showFilters,
      setFilter,
      filters
    };
  }
});

import Vue from 'vue';
import PortalVue from 'portal-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import LassoVueStyles from '@lassoworkforce/lasso-vue-styles';
import { PiniaVuePlugin } from 'pinia';
import { GanttPlugin } from '@syncfusion/ej2-vue-gantt';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import { vMaska } from 'maska';
import LToastPlugin from './components/Common/LToastPlugin';

import pinia from '@/store';
import router from '@/router';
import App from './App.vue';
import './registerServiceWorker';

import { i18n } from './localization/i18n';

// import Lasso global style sheet
import '@lassoworkforce/lasso-vue-styles/scss/_lasso.scss';

// register custom directive
Vue.directive('maska', vMaska);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(PortalVue);
Vue.use(LassoVueStyles);
Vue.use(PiniaVuePlugin);
Vue.use(DropDownListPlugin);
Vue.use(GanttPlugin);
Vue.use(SchedulePlugin);
Vue.use(LToastPlugin);

registerLicense(process.env.VUE_APP_SYNC_FUSION_KEY);

Vue.config.productionTip = process.env.NODE_ENV === 'development';

new Vue({
  render: (h: any) => h(App),
  i18n,
  pinia,
  router
}).$mount('#app');

import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { axiosInventory } from '../services/httpService';
// eslint-disable-next-line import/no-cycle
import { useEventStore } from '@/store';
import {
  IProjectAttachmentCreateUploadUrlResponse,
  IProjectAttachmentFolder,
  ProjectAttachmentFolder,
  ProjectFile,
  ProjectLink,
  ProjectNote,
  IProjectNote
} from '@/types/events/project';

const useProjectAttachmentsStore = defineStore('ProjectAttachmentsStore', () => {
  // nested store
  const eventStore = useEventStore();
  const { activeEvent } = storeToRefs(eventStore);

  const loadedAttachmentFolders = ref<{ [folderId: number | string]: ProjectAttachmentFolder }>({});
  const activeFolder = ref<ProjectAttachmentFolder | null>(null);
  const activeNote = ref<ProjectNote | null>(null);

  const describeAttachmentFolder = async (attachmentFolderId: number | null) => {
    const { data }: { data: IProjectAttachmentFolder } = await axiosInventory.get(
      `/events/${activeEvent.value?.code}/project/folders/${attachmentFolderId ?? 'root'}`
    );

    const key = attachmentFolderId ? data.id : 'root';

    loadedAttachmentFolders.value = {
      ...loadedAttachmentFolders.value,
      [key]: new ProjectAttachmentFolder(data)
    };
  };

  const addAttachmentFolder = async (folder: ProjectAttachmentFolder) => {
    await axiosInventory.post(`/events/${activeEvent.value?.code}/project/folders`, folder);

    await describeAttachmentFolder(folder.parentFolderId);
  };

  const deleteAttachmentFolder = async (folder: ProjectAttachmentFolder) => {
    await axiosInventory.delete(`/events/${activeEvent.value?.code}/project/folders/${folder.id}`);

    await describeAttachmentFolder(folder.parentFolderId);
  };

  const deleteFile = async (file: ProjectFile) => {
    await axiosInventory.delete(`/events/${activeEvent.value?.code}/project/files/${file.id}`);

    await describeAttachmentFolder(file.parentFolderId);
  };

  const getFileDownloadUrl = async (file: ProjectFile) => {
    const { data } = await axiosInventory.post(`/events/${activeEvent.value?.code}/project/files/get_document_url`, {
      fileName: file.fileName
    });
    return data;
  };

  const addNote = async (note: ProjectNote) => {
    await axiosInventory.post(`/events/${activeEvent.value?.code}/project/notes`, note);
    await describeAttachmentFolder(note.parentFolderId);
  };

  const updateNote = async (note: ProjectNote) => {
    const { data } = await axiosInventory.put(`/events/${activeEvent.value?.code}/project/notes/${note.id}`, note);
    const updatedNote: IProjectNote = data;
    // Update just the dbDateUpdated to give accurate saving timestamp. Full replacement causes content mismatch bug
    if (activeNote.value) {
      const { dbDateUpdated, ...restOfProjectNote } = activeNote.value;
      activeNote.value = { dbDateUpdated: new Date(updatedNote.dbDateUpdated), ...restOfProjectNote };
    }

    await describeAttachmentFolder(note.parentFolderId);
  };

  const deleteNote = async (note: ProjectNote) => {
    await axiosInventory.delete(`/events/${activeEvent.value?.code}/project/notes/${note.id}`);

    await describeAttachmentFolder(note.parentFolderId);
  };

  const addLink = async (link: ProjectLink) => {
    await axiosInventory.post(`/events/${activeEvent.value?.code}/project/links`, link);
    await describeAttachmentFolder(link.parentFolderId);
  };

  const deleteLink = async (link: ProjectLink) => {
    await axiosInventory.delete(`/events/${activeEvent.value?.code}/project/links/${link.id}`);

    await describeAttachmentFolder(link.parentFolderId);
  };

  const getDocumentUploadUrl = async (fileName: string, title?: string) => {
    if (!activeEvent.value) {
      throw new Error('No event is selected');
    }
    const { code } = activeEvent.value;
    const url = `/events/${code}/project/files/create_upload_url`;
    let parentFolderId: number | null = activeFolder.value?.id ?? null;
    if (parentFolderId === 0) parentFolderId = null;
    const { data }: { data: IProjectAttachmentCreateUploadUrlResponse } = await axiosInventory.post(url, {
      eventId: activeEvent.value.id,
      parentFolderId,
      fileName,
      title: title || fileName
    });
    return data;
  };

  return {
    activeFolder,
    loadedAttachmentFolders,
    describeAttachmentFolder,
    addAttachmentFolder,
    deleteAttachmentFolder,
    deleteFile,
    deleteNote,
    getDocumentUploadUrl,
    addNote,
    activeNote,
    updateNote,
    addLink,
    deleteLink,
    getFileDownloadUrl
  };
});

export default useProjectAttachmentsStore;

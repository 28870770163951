
import { defineComponent, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { BNavItemDropdown } from 'bootstrap-vue';
import { useNavigationStore } from '@/store';

export default defineComponent({
  name: 'HelpNavMenu',
  props: {
    viewOnlySchedulesPermission: Boolean
  },
  setup() {
    const navigationStore = useNavigationStore();
    const { navSettings } = storeToRefs(navigationStore);

    return {
      navSettings
    };
  }
});

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { axiosChat } from '@/services/httpService';
import { IStartConversationPayload, IListConversationsResponse, ITalkJSResponse } from '@/types/chat';

const useChatStore = defineStore('ChatStore', () => {
  // state
  const loggedInUserChatUserId = ref<string>();
  const loggedInChatUserSignature = ref<string>();
  const showInboxDropdown = ref<boolean>(false);
  const showNewMsgPopover = ref<boolean>(false);

  // actions
  const startConversation = async (payload: IStartConversationPayload) => {
    const { data }: { data: { conversationId: string; me: string; signature: string } } = await axiosChat.post(
      '/talk/conversations',
      payload
    );
    loggedInUserChatUserId.value = data.me;
    loggedInChatUserSignature.value = data.signature;
    return data;
  };

  const listConversations = async () => {
    const { data }: { data: IListConversationsResponse } = await axiosChat.get('/talk/conversations');
    loggedInChatUserSignature.value = data.me;
    loggedInChatUserSignature.value = data.signature;
    return data;
  };

  const getSignature = async (refresh = false) => {
    if (refresh) {
      loggedInChatUserSignature.value = undefined;
    }
    if (!loggedInChatUserSignature.value || !loggedInUserChatUserId) {
      const { data }: { data: ITalkJSResponse } = await axiosChat.post('/talk/get_signature');
      loggedInChatUserSignature.value = data.signature;
      loggedInUserChatUserId.value = data.me;
    }
  };

  return {
    loggedInUserChatUserId,
    loggedInChatUserSignature,
    startConversation,
    listConversations,
    getSignature,
    showInboxDropdown,
    showNewMsgPopover
  };
});

export default useChatStore;

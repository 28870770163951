
import { defineComponent } from 'vue';
import {
  LIconRatesAndBilling,
  LIconProjectManagement,
  LIconGearList,
  LIconTransport,
  LIconProposals,
  LIconCommunicationsSpoke
} from '@/components/Icons/NavIcons';

export default defineComponent({
  name: 'SpokeNavItem',
  components: {
    LIconRatesAndBilling,
    LIconProjectManagement,
    LIconGearList,
    LIconTransport,
    LIconProposals,
    LIconCommunicationsSpoke
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
    titleKey: {
      type: String,
      required: true
    },
    pathName: {
      type: String,
      required: true
    }
  }
});

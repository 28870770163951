
import { defineComponent, onMounted, PropType, watch } from 'vue';
import { Editor } from '@tiptap/core';
import { EditorContent } from '@tiptap/vue-2';
import type { Level } from '@tiptap/extension-heading';
import { isPlatformAMac } from '@/validators';

export default defineComponent({
  name: 'TiptapEditorBase',
  components: {
    EditorContent
  },
  props: {
    tiptapEditor: {
      type: Object as PropType<Editor>,
      required: true
    },
    id: {
      type: String as PropType<string>,
      required: false
    },
    variant: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'flex-grow'>,
      default: () => 'sm'
    },
    objectId: {
      type: Number,
      required: false
    },
    dataTestIdString: {
      type: String,
      required: false
    }
  },
  emits: ['input'],
  setup(props) {
    const defaultEditorClass = 'border rounded p-2';

    const headingOptions = [
      {
        value: 0,
        text: 'TipTapEditor.HeadingOptions.NormalText'
      },
      {
        value: 1,
        text: 'TipTapEditor.HeadingOptions.HeadingOne'
      },
      {
        value: 2,
        text: 'TipTapEditor.HeadingOptions.HeadingTwo'
      },
      {
        value: 3,
        text: 'TipTapEditor.HeadingOptions.HeadingThree'
      },
      {
        value: 4,
        text: 'TipTapEditor.HeadingOptions.HeadingFour'
      },
      {
        value: 5,
        text: 'TipTapEditor.HeadingOptions.HeadingFive'
      },
      {
        value: 6,
        text: 'TipTapEditor.HeadingOptions.HeadingSix'
      }
    ];

    const toolbarButtons = [
      {
        id: 0,
        name: 'bold',
        iconName: 'type-bold',
        toggleFunction: 'toggleBold',
        altText: 'TipTapEditor.ToolbarButtons.Bold',
        keyStroke: isPlatformAMac() ? '⌘B' : 'Ctrl+B'
      },
      {
        id: 1,
        name: 'italic',
        iconName: 'type-italic',
        toggleFunction: 'toggleItalic',
        altText: 'TipTapEditor.ToolbarButtons.Italic',
        keyStroke: isPlatformAMac() ? '⌘I' : 'Ctrl+I'
      },
      {
        id: 2,
        name: 'underline',
        iconName: 'type-underline',
        toggleFunction: 'toggleUnderline',
        altText: 'TipTapEditor.ToolbarButtons.Underline',
        keyStroke: isPlatformAMac() ? '⌘U' : 'Ctrl+U'
      },
      {
        id: 3,
        name: 'strike',
        iconName: 'type-strikethrough',
        toggleFunction: 'toggleStrike',
        altText: 'TipTapEditor.ToolbarButtons.Strikethrough',
        keyStroke: isPlatformAMac() ? '⌘⇧X' : 'Ctrl+⇧+X'
      },
      {
        id: 4,
        name: 'orderedList',
        iconName: 'list-ol',
        toggleFunction: 'toggleOrderedList',
        altText: 'TipTapEditor.ToolbarButtons.NumberedList',
        keyStroke: isPlatformAMac() ? '⌘⇧7' : 'Ctrl+⇧+7'
      },
      {
        id: 5,
        name: 'bulletList',
        iconName: 'list-ul',
        toggleFunction: 'toggleBulletList',
        altText: 'TipTapEditor.ToolbarButtons.BulletList',
        keyStroke: isPlatformAMac() ? '⌘⇧8' : 'Ctrl+⇧+8'
      }
    ];

    const textAlignToolbarButtons = [
      {
        id: 0,
        name: 'left',
        iconName: 'text-left',
        altText: 'TipTapEditor.ToolbarButtons.TextAlignLeft',
        keyStroke: isPlatformAMac() ? '⌘⇧L' : 'Ctrl+⇧+L'
      },
      {
        id: 1,
        name: 'center',
        iconName: 'text-center',
        altText: 'TipTapEditor.ToolbarButtons.TextAlignCenter',
        keyStroke: isPlatformAMac() ? '⌘⇧E' : 'Ctrl+⇧+E'
      },
      {
        id: 2,
        name: 'right',
        iconName: 'text-right',
        altText: 'TipTapEditor.ToolbarButtons.TextAlignRight',
        keyStroke: isPlatformAMac() ? '⌘⇧R' : 'Ctrl+⇧+R'
      },
      {
        id: 3,
        name: 'justify',
        iconName: 'justify',
        altText: 'TipTapEditor.ToolbarButtons.TextAlignJustify',
        keyStroke: isPlatformAMac() ? '⌘⇧J' : 'Ctrl+⇧+J'
      }
    ];

    const setSelectedHeading = (selected: Level | 0) => {
      if (selected === 0) {
        const selectedLevel = props.tiptapEditor.getAttributes('heading').level ?? 0;
        return props.tiptapEditor.chain().focus().toggleHeading({ level: selectedLevel }).run();
      }
      return props.tiptapEditor.chain().focus().setHeading({ level: selected }).run();
    };

    const setEditorOptions = (editor: Editor) => {
      editor.setOptions({
        editorProps: {
          attributes: {
            class: props.variant === 'sm' ? defaultEditorClass : `${defaultEditorClass} editor-${props.variant}`
          }
        }
      });
    };

    const objectIdString = () => {
      let string = '';
      if (props.objectId) {
        string += `-${props.objectId}`
      }
      return string
    }

    onMounted(() => {
      setEditorOptions(props.tiptapEditor);
    });

    watch(() => props.tiptapEditor, setEditorOptions);

    return {
      onMounted,
      toolbarButtons,
      headingOptions,
      setSelectedHeading,
      textAlignToolbarButtons,
      objectIdString
    };
  }
});


import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '@/store';
import { LIconEvent } from '../Icons/NavIcons';

export default defineComponent({
  name: 'SchedulesNavMenu',
  components: {
    LIconEvent
  },
  props: {
    viewOnlySchedulesPermission: Boolean
  },
  setup(props) {
    const navigationStore = useNavigationStore();
    const { navSettings } = storeToRefs(navigationStore);

    const crewScheduleLink = navSettings.value.schedule.crew; // `/calendars/crew/${cookieToParams('LASSO_param_filter_calendars_crew')}`;
    const eventsScheduleLink = navSettings.value.schedule.events; // `/calendars/events/${cookieToParams('LASSO_param_filter_calendars_events')}`;

    return {
      crewScheduleLink,
      eventsScheduleLink
    };
  }
});

import { defineStore, StoreActions, Store } from 'pinia';
import { ref } from 'vue';
// eslint-disable-next-line import/no-cycle
import { useItemsStore, useProductStore } from '@/store';

const actionsToRecord = [
  'addSerializedItemsBulk',
  'updateSerializedItem',
  'deleteSerializedItem',
  'updateUnserializedQuantity'
];

/*
To keep inventory event history in the same order as intended by the user,
this store records during product drafting and then plays back history upon product save.

The actual relevant stores (ItemsStore, ProductStore) have support internally to
fake the behavior from API responses when a product is a draft so that the UI works
correctly whilst not actually persisting things to the API.

For the UI to function normally, created serialized items need to have an ID for update/dispose
store calls to have a valid reference. ItemsStore manages these "draft ids" and, using the order of creation
first creates incremented IDs for use on the frontend, then during draft playback, re-maps those IDs
to the actual created API IDs.
*/

/**
 * This store enables product inventory to function as expected when the product has not yet been created on the API side.
 */
const useItemsDraftStore = defineStore('ItemsDraftStore', () => {
  const itemsStore = useItemsStore();
  const productStore = useProductStore();

  // state
  const unsubscribe = ref<(() => void) | null>(null);
  const draftActionHistory = ref<Array<{ store: Store<any>; name: string; args: any }>>([]);

  /**
   * Start recording relevant product inventory actions from ItemsStore and ProductStore.
   */
  const startDraft = () => {
    const listener = ({
      name, // name of the action
      args, // array of parameters passed to the action
      store
    }: {
      name: string;
      args: any;
      store: Store<any>;
    }) => {
      if (!actionsToRecord.includes(name)) return;

      draftActionHistory.value.push({
        store,
        name,
        // need to deep copy, because if referenced objects are edited later
        // then the history won't execute properly
        args: JSON.parse(JSON.stringify(args))
      });
    };

    const unsubscribeItems = itemsStore.$onAction(listener);
    const unsubscribeProduct = productStore.$onAction(listener);

    unsubscribe.value = () => {
      unsubscribeItems();
      unsubscribeProduct();
    };
  };

  /**
   * Play back recorded inventory actions for a draft product.
   * Must be called after creating the product and setting it as active.
   */
  const persistDraft = async () => {
    if (unsubscribe.value) unsubscribe.value();

    itemsStore.draftPlayback = true;
    itemsStore.currentDraftId = 1;

    // cannot use forEach, needs await
    // eslint-disable-next-line no-restricted-syntax
    for (const action of draftActionHistory.value) {
      // must be run in the same order as recorded, can't use Promise.all
      /* eslint-disable no-await-in-loop */
      // @ts-expect-error: typing this is impossible?
      await action.store[action.name].apply(null, action.args);
      /* eslint-enable no-await-in-loop */
    }

    itemsStore.draftPlayback = false;
  };

  /**
   * Unsubscribe draft listeners and and clear history.
   */
  const cancelDraft = () => {
    if (unsubscribe.value) unsubscribe.value();
    draftActionHistory.value = [];
  };

  return {
    startDraft,
    persistDraft,
    cancelDraft
  };
});

export default useItemsDraftStore;
